<template>
    <div class="q-ma-md text-h5">
      {{ role_title }}
    </div>
    <div class="row">
      <div class="col q-pa-md">
        <div class="q-ma-md text-h5">
          Сотрудник
        </div>
        <div>
          <q-list padding>
            <q-item v-if="current_user.id > 0" v-ripple clickable>
              <q-item-section center avatar>
                <q-avatar color="primary" size="60px" text-color="white">
                  {{ current_user.email.slice(0,2) }}
                </q-avatar>
              </q-item-section>

              <q-item-section>
                <q-item-label>{{ current_user.display_name }}</q-item-label>
                <q-item-label caption>{{ current_user.email }}</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-btn flat dense color="negative" label="Уволить" @click="removeUserFromOrg(current_user.id)" />
                <!-- <q-btn dense flat icon="settings">
                  <q-menu>  
                    <q-list style="min-width: 100px">
                      <q-item clickable v-close-popup @click="removeUserFromOrg(current_user.id)">
                        <q-item-section class="text-red">Уволить</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn> -->
              </q-item-section>
            </q-item>
            <q-item v-else>

              <q-item-section>
                <q-item-label>Место вакантно</q-item-label>
              </q-item-section>

            </q-item>
            <q-separator />
            <q-item v-if="is_has_invite">
              <q-item-section>
                Приглашение отправлено на <span class="text-primary">{{ old_invite_email }}</span>
              </q-item-section>
              <q-item-section side>
                <q-btn flat label="Отменить приглашение" @click="cancelInvitation" />
              </q-item-section>
            </q-item>
            <q-item v-else>
              <q-item-section>
                <q-input dense v-model="email_invite" label="E-mail" :loading="show_invite_loading" />
              </q-item-section>
              <q-item-section side>
                <q-btn flat :disable="!(invite_allowed && (email_invite || '').length !== 0)" label="Пригласить"
                  @click="inviteUserToOrganisation" />
              </q-item-section>
            </q-item>
          </q-list>

        </div>

      </div>
      <div class="col q-pa-md">
        <div class="q-ma-md text-h5">
          Настройки
        </div>
        <div class="q-pa-md">
          <q-input v-model="role_title" label="Название рабочего места" />
        </div>
        <div class="q-gutter-sm">
          <q-list>
            <q-item tag="label" v-ripple>
              <q-item-section avatar top>
                <q-checkbox :disable="is_root_role" v-model="edit_roles" color="primary" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Редактировать рабочие места</q-item-label>
                <q-item-label caption>
                  Право редактировать рабочие места организации
                </q-item-label>
                <q-slide-transition>
                  <q-list v-show="edit_roles">
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="is_root_role" v-model="edit_roles_invite" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Добавлять сотрудников</q-item-label>
                        <q-item-label caption>
                          Отсылать приглашения и подтверждать пользователей в системе
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="is_root_role" v-model="edit_roles_change" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Управлять рабочими местами</q-item-label>
                        <q-item-label caption>
                          Создавать, редактировать и удалять рабочие места
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="is_root_role" v-model="edit_roles_edit_users" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Редактировать сотрудников</q-item-label>
                        <q-item-label caption>
                          Изменять личные данные сотрудников, назначать рабочие места
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="is_root_role" v-model="edit_roles_fire" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Увольнять сотрудников</q-item-label>
                        <q-item-label caption>
                          Удалять сотрудников из организации
                        </q-item-label>
                      </q-item-section>
                    </q-item>

                  </q-list>
                </q-slide-transition>
              </q-item-section>
            </q-item>
            <q-item tag="label" v-ripple>
              <q-item-section avatar top>
                <q-checkbox :disable="is_root_role" v-model="edit_organisation" color="primary" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Редактировать организацию</q-item-label>
                <q-item-label caption>
                  Право редактировать данные организации
                </q-item-label>
                <q-slide-transition>
                  <q-list v-show="edit_organisation">
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="is_root_role" v-model="edit_organisation_balance" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Баланс организации</q-item-label>
                        <q-item-label caption>
                          Просмотр баланса организации и запрос счетов на оплату
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="is_root_role" v-model="edit_organisation_bills" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Акты и счета</q-item-label>
                        <q-item-label caption>
                          Получение актов и счетов-фактур
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="is_root_role" v-model="edit_organisation_plans" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Тарифный план</q-item-label>
                        <q-item-label caption>
                          Просмотр и изменение тарифного плана организации
                        </q-item-label>
                      </q-item-section>
                    </q-item>

                  </q-list>
                </q-slide-transition>
              </q-item-section>
            </q-item>
            <q-separator />
            <q-item-label header>Документы</q-item-label>
            <q-item tag="label" v-ripple>
              <q-item-section avatar top>
                <q-checkbox :disable="is_root_role" v-model="open_documents" color="primary" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Просматривать документы</q-item-label>
                <q-item-label caption>
                  Право просматривать документы организации
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item tag="label" v-ripple>
              <q-item-section avatar top>
                <q-checkbox :disable="is_root_role" v-model="edit_documents" color="primary" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Редактировать документы</q-item-label>
                <q-item-label caption>
                  Право создавать, редактировать, удалять документы организации
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-separator />
            <q-item-label header>Бухгалтерия</q-item-label>
            <q-item tag="label" v-ripple>
              <q-item-section avatar top>
                <q-checkbox :disable="is_root_role" v-model="open_finance" color="primary" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Бухгалтерия</q-item-label>
                <q-item-label caption>
                  Доступ к просмотру бухучета организации
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
        <div class="q-pa-md">

          <q-list>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label>
                  <q-btn label="Сохранить" @click="saveRole" color="primary" />
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label>
                  <q-btn :disable="!delete_allowed" label="Удалить" color="red" @click="tryToDeleteRole" />
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </div>
</template>

<script>
import {ref} from "vue";
import {useQuasar} from "quasar";

export default {
  name: "role_settings",
  mounted() {

  },
  setup() {

  },
  data() {
    const $q = useQuasar();
    this.emitter.on('didInviteUserToOrg', (data) => {
      console.log(data);
      this.connection.getAllUsersList('1', this.$store.state.current_org_id);
      this.connection.getRoleInfoById('1', this.$store.state.current_org_id, this.$route.params.id);
      this.email_invite = null
      this.show_invite_loading = false;
      $q.notify('Приглашение было успешно отправлено');
    })
    this.emitter.on('didUpdateRoleInfoById', (data) => {
      console.log(data);
      $q.notify('Рабочее место было успешно изменено');
      this.connection.getAllUsersList('1', this.$store.state.current_org_id);
      this.connection.getRoleInfoById('1', this.$store.state.current_org_id, this.$route.params.id);
    })
    this.emitter.on('didDeleteRoleFromOrganisation', (data) => {
      console.log(data);
      $q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Вы не можете удалить это рабочее место',
          cancel: false,
          persistent: true
        }).onOk(() => {
          this.confirmation_code = null;
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        $q.notify('Рабочее место было успешно удалено');
        this.$router.back();
      }
    });
    this.emitter.on('didReceiveRoleInfoById', (role_data) => {
      const data = role_data.role.permissions;
      this.is_root_role = role_data.role.root;
      this.role_title = role_data.role.role;
      this.edit_users = data.edit_users;
      this.edit_roles = data.edit_roles;
      this.edit_roles_invite = data.edit_roles_invite;
      this.edit_roles_change = data.edit_roles_change;
      this.edit_roles_fire = data.edit_roles_fire;
      this.edit_roles_edit_users = data.edit_roles_edit_users;
      this.edit_organisation = data.edit_organisation;
      this.edit_organisation_balance = data.edit_organisation_balance;
      this.edit_organisation_bills = data.edit_organisation_bills;
      this.edit_organisation_plans = data.edit_organisation_plans;
      this.open_documents = data.open_documents;
      this.edit_documents = data.edit_documents;
      this.open_finance = data.open_finance;
      this.edit_finance = data.edit_finance;
      this.invite_allowed = !(role_data.role.has_user);
      this.delete_allowed = true;
      this.is_has_invite = !role_data.role.invite.empty;
      this.old_invite_email = role_data.role.invite.email;
      this.is_has_worker = role_data.role.has_user;
      if (role_data.role.has_user) {
        this.current_user = role_data.role.user;
      } else {
        this.current_user = { email: '', display_name: '', role: { role: '' }, id: -1 };
      }
    })
    this.emitter.on('onCancelInvitation', (data) => {
      $q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Вы не можете отменить это приглашение',
          cancel: false,
          persistent: true
        }).onOk(() => {
          this.confirmation_code = null;
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        $q.notify('Приглашение было успешно отменено');
      }
    });

    this.emitter.on('onAddUserToRole', (data) => {
      $q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Вы не можете назначить работника на рабочее место',
          cancel: false,
          persistent: true
        }).onOk(() => {
          this.confirmation_code = null;
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        $q.notify('Работник успешно назначен');
        this.connection.getAllUsersList('1', this.$store.state.current_org_id);
      }
    });

    this.emitter.on('onRemoveUserFromRole', (data) => {
      $q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Вы не можете убрать работника с этого рабочего места',
          cancel: false,
          persistent: true
        }).onOk(() => {
          this.confirmation_code = null;
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        $q.notify('Рабочее место успешно освобождено');
        this.connection.getAllUsersList('1', this.$store.state.current_org_id);
        this.current_user = { email: '', display_name: '', role: { role: '' }, id: -1 };
      }
    });
    this.emitter.on('onRemoveUserFromOrg', (data) => {
      $q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Вы не можете уволить работника',
          cancel: false,
          persistent: true
        }).onOk(() => {
          console.log('cancel')
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        $q.notify('Работник успешно уволен');
        this.current_user = { email: '', display_name: '', role: { role: '' }, id: -1 };
        this.connection.getAllUsersList('1', this.$store.state.current_org_id);
        this.connection.getRoleInfoById('1', this.$store.state.current_org_id, this.$route.params.id);
      }
    });
    this.emitter.on('onGetAllUsersList', (data) => {
      this.all_users = data.data;
    });
    if (this.connection.isAuthenticated) {
      this.connection.getRoleInfoById('1', this.$store.state.current_org_id, this.$route.params.id);
      this.connection.getAllUsersList('1', this.$store.state.current_org_id);
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getRoleInfoById('1', this.$store.state.current_org_id, this.$route.params.id);
        this.connection.getAllUsersList('1', this.$store.state.current_org_id);
      })
    }
    return {
      is_root_role: ref(false),
      can_save_role: ref(true),
      can_invite_user: ref(false),
      show_invite_loading: ref(false),
      organisation_flat: ref({roles: []}),
      parents: [],
      options: ref([]),
      parent: ref(''),
      role_title: ref(''),
      edit_users: ref(false),
      edit_roles: ref(false),
      edit_roles_invite: ref(true),
      edit_roles_change: ref(true),
      edit_roles_fire: ref(true),
      edit_roles_edit_users: ref(true),
      edit_organisation: ref(false),
      edit_organisation_balance: ref(true),
      edit_organisation_bills: ref(true),
      edit_organisation_plans: ref(true),
      open_documents: ref(false),
      edit_documents: ref(false),
      open_finance: ref(false),
      edit_finance: ref(false),
      role_id: ref(this.$route.params.id),
      invite_allowed: ref(true),
      delete_allowed: ref(false),
      users_list: ref([]),
      is_has_worker: ref(false),
      current_user: ref({ email: '', display_name: '', role: { role: '' }, id: -1 }),
      all_users: ref([]),
      selected_user: ref(null),
      email_invite: ref(null),
      is_has_invite: ref(false),
      old_invite_email: ref(null)
    }
  },
  methods: {
    saveRole() {
      const data = {
        role_title: this.role_title,
        edit_users: this.edit_users,
        edit_roles: this.edit_roles,
        edit_roles_invite: this.edit_roles_invite,
        edit_roles_change: this.edit_roles_change,
        edit_roles_fire: this.edit_roles_fire,
        edit_roles_edit_users: this.edit_roles_edit_users,
        edit_organisation: this.edit_organisation,
        edit_organisation_balance: this.edit_organisation_balance,
        edit_organisation_bills: this.edit_organisation_bills,
        edit_organisation_plans: this.edit_organisation_plans,
        open_documents: this.open_documents,
        edit_documents: this.edit_documents,
        open_finance: this.open_finance,
        edit_finance: this.edit_finance,
      }
      this.connection.updateRoleInfoById('1', this.role_id, data);
    },
    inviteUserToOrganisation() {
      const org_id = this.$store.state.current_org_id;
      const data = {
        org_id: org_id,
        email: this.email_invite,
        role_id: this.role_id
      }
      this.show_invite_loading = true;
      this.connection.inviteUserToOrg('1', data)
    },
    tryToDeleteRole() {
      this.$q.dialog({
        title: 'Предупреждение',
        message: 'Рабочее место будет удалено, привязанные пользователи будут исключены из организации',
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.deleteRole();
      }).onCancel(() => {
        console.log('cancel')
      }).onDismiss(() => {
        console.log('dismiss')
      })
    },
    deleteRole() {
      const org_id = this.$store.state.current_org_id;
      this.connection.deleteRoleFromOrganisation('1', this.role_id, org_id);
      this.$q.loading.show();
    },
    cancelInvitation() {
      this.$q.dialog({
        title: 'Предупреждение',
        message: 'Приглашение будет отозвано',
        cancel: true,
        persistent: true
      }).onOk(() => {
        const org_id = this.$store.state.current_org_id;
        this.connection.cancelInvitation('1', this.role_id, org_id);
        this.$q.loading.show();
      }).onCancel(() => {
        console.log('cancel')
      }).onDismiss(() => {
        console.log('dismiss')
      });
    },
    removeUserFromOrg(user_id) {
      this.$q.dialog({
        title: 'Предупреждение',
        message: 'Пользователь будет исключен из организации',
        cancel: true,
        persistent: true
      }).onOk(() => {
        const org_id = this.$store.state.current_org_id;
        this.connection.removeUserFromOrg('1', user_id, this.role_id, org_id);
        this.$q.loading.show();
      }).onCancel(() => {
        console.log('cancel')
      }).onDismiss(() => {
        console.log('dismiss')
      });

    },
    addUserToRole(user_id) {
      this.$q.dialog({
        title: 'Предупреждение',
        message: 'Пользователь будет назначен на рабочее место',
        cancel: true,
        persistent: true
      }).onOk(() => {
        const org_id = this.$store.state.current_org_id;
        this.connection.addUserToRole('1', user_id, this.role_id, org_id);
        this.$q.loading.show();
      }).onCancel(() => {
        console.log('cancel')
      }).onDismiss(() => {
        console.log('dismiss')
      });

    },
    removeUserFromRole() {
      this.$q.dialog({
        title: 'Предупреждение',
        message: 'Пользователь будет убран с рабочего места',
        cancel: true,
        persistent: true
      }).onOk(() => {
        const org_id = this.$store.state.current_org_id;
        this.connection.removeUserFromRole('1', this.role_id, org_id);
        this.$q.loading.show();
      }).onCancel(() => {
        console.log('cancel')
      }).onDismiss(() => {
        console.log('dismiss')
      });

    }
  }
}
</script>

<style scoped>

</style>