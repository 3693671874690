<template>
  <div class="row">
    <div class="col-10">
      <q-table title="Шаблоны проводок" :rows="table_rows" :columns="table_columns" row-key="name" flat separator="cell"
        :pagination="tablePagination" @row-click="onRowClick" v-model="table_rows">

      </q-table>
    </div>
    <div class="col-2 q-pa-md">
      
    </div>
  </div>
  <q-page-sticky position="bottom-right" :offset="[18, 18]">
    <q-fab icon="add" direction="up" color="primary">
      <q-fab-action anchor="start" @click="$router.push({ name: 'transfer_templates_payment_new', params: {} })"
        color="primary" label="Шаблон для платежного поручения" />
      <q-fab-action anchor="start" @click="$router.push({ name: 'transfer_templates_new', params: {} })" color="primary"
        label="Шаблон для УПД" />
      <q-fab-action anchor="start" @click="$router.push({ name: 'transfer_templates_avance_new', params: {} })"
        color="primary" label="Шаблон для счет-фактуры" />
    </q-fab>
  </q-page-sticky>

  <q-dialog v-model="accept_delete" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <span class="q-ml-sm">Вы собираетесь удалить шаблон для проводок, продолжить?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Отмена" color="primary" v-close-popup />
        <q-btn flat label="Удалить" color="red" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>


</template> 

<script>
import {ref} from "vue";

const fields = [
  { value: 'count', label: 'Количество' },
  { value: 'price', label: 'Цена' },
  { value: 'cost_without_nds', label: 'Стоимость до налогов' },
  { value: 'aciz', label: 'Акциз' },
  { value: 'nalog', label: 'Сумма налога, всего' },
  { value: 'summtotal', label: 'Сумма с налогом, всего' },
  { value: 'summ', label: 'Сумма' },
];

const table_columns = [
  { name: 'name', align: 'left', label: 'Название', field: 'name' },
  { name: 'document_type', align: 'left', label: 'Тип документа', field: 'document_type_display' },
  { name: 'bill_from', align: 'left', label: 'Счет списания', field: 'bill_from', sortable: true }, 
  { name: 'bill_to', align: 'left', label: 'Счет зачисления', field: 'bill_to', sortable: true },
  { name: 'direction', align: 'left', label: 'Направление', field: 'direction_display', sortable: true },
  { name: 'field_id', align: 'left', label: 'Поле', field: 'field_id_display', sortable: true },
  { name: 'comment', align: 'left', label: 'Комментарий', field: 'comment', sortable: true },
]

export default {
  name: "transfer_templates_list",
  data() {
    this.emitter.on('onReceiveTrasnferTemplateList', (response) => {
      console.log(response);
      this.table_rows = response.data;
    });
    this.emitter.on('onDeleteUpdTransferTemplate', (response) => {
      console.log(response);
      this.connection.getTransfersTemplatesList(
          '1',
          this.$store.state.current_org_id
      );
    });
    if (this.connection.isAuthenticated) {
      this.connection.getTransfersTemplatesList(
          '1',
          this.$store.state.current_org_id
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getTransfersTemplatesList(
            '1',
            this.$store.state.current_org_id
        );
      })
    }

    return {
      table_columns: ref(table_columns),
      table_rows: ref([]),
      accept_delete: ref(false)
    }
  },
  methods: {
    deleteTransfer(transfer_id) {
      this.$q.loading.show();
      this.connection.deleteUpdTransferTemplate(
          '1',
          this.$store.state.current_org_id,
          transfer_id
      )
    },
    displayValue(key) {
      return fields.find(field => field.value === key).label;
    }
  }
}
</script>

<style scoped>

</style>