<template>
  <div class="q-pa-md">
    <div class="q-ma-md text-h5">
      Организация
    </div>
    <div class="row">
      <div class="col-6">
        <div class="q-ma-md text-h6">
          Создать новую организацию
        </div>

        <q-inner-loading :showing="is_new_org_loading">
          <q-spinner-gears size="50px" color="primary" />
        </q-inner-loading>

        <q-stepper
            flat
            v-model="step"
            vertical
            color="primary"
            animated
        >
          <q-step
              :name="1"
              title="Основные данные"
              icon="settings"
              :done="step > 1"
          >
            <div class="row">
              <div class="col-12">
                <div class="q-pa-md">
                  Введите ИНН
                </div>
                <div class="q-pa-md">
                  <q-form>
                    <q-input v-model="org_inn" label="ИНН" :error="is_inn_error">
                      <template v-slot:error>
                        Такая организация уже зарегистрирована.
                      </template>
                    </q-input>
                    <q-toggle v-model="is_director" label="Я директор"/>
                  </q-form>
                </div>
              </div>
            </div>

            <q-stepper-navigation>
              <q-btn @click="firstStepSubmit()" color="primary" label="Далее" />
            </q-stepper-navigation>

          </q-step>

          <q-step
              :name="2"
              title="Регистрационные данные"
              icon="create_new_folder"
              :done="step > 2"
          >

            <div class="row">
              <div class="col-12">
                <div class="q-pa-md">
                  Проверьте данные
                </div>
                <div class="q-pa-md">
                  <q-input v-model="name" label="Полное наименование"/>
                  <q-input v-model="org_adress" label="Адрес"/>
                  <q-input v-model="org_ogrn" label="ОГРН"/>
                  <q-input v-model="org_okpo" label="ОКПО"/>
                  <q-input v-model="org_okved" label="ОКВЭД"/>
                  <q-input v-model="org_created_date" label="Дата создания"/>
                </div>
              </div>
            </div>

            <q-stepper-navigation>
              <q-btn @click="secondStepSubmit" color="primary" label="Далее" />
              <q-btn flat @click="step = 1" color="primary" label="Назад" class="q-ml-sm" />
            </q-stepper-navigation>
          </q-step>
        </q-stepper>
      </div>
    </div>

  </div>
</template>

<script>
import {ref} from 'vue'

const bills_table_columns = [
  { name: 'name', align: 'left', label: 'Счет', field: 'name', sortable: true},
  { name: 'debet_start', align: 'left', label: 'Дебет', field: 'debet_start'},
  { name: 'credit_start', align: 'left', label: 'Кредит', field: 'credit_start'},
  { name: 'debet_period', align: 'left', label: 'Дебет', field: 'debet_period'},
  { name: 'credit_period', align: 'left', label: 'Кредит', field: 'credit_period'},
  { name: 'debet_end', align: 'left', label: 'Дебет', field: 'debet_end'},
  { name: 'credit_end', align: 'left', label: 'Кредит', field: 'credit_end'},
];

const org_reg_choices_dict = [
  {
    value: 'ip',
    label: 'Индивидуальный предприниматель',
  },
  {
    value: 'ooo',
    label: 'Общество с ограниченной ответственностью',
  },
];

const nalog_systems_choices = [
  {
    id: 1,
    value: 'osn',
    name: 'ОСН',
    description: 'Общий налоговый режим является основным и применяется по умолчанию, если индивидуальный предприниматель не подал в налоговый орган заявление о переходе на один из специальных налоговых режимов.'
  },
  {
    id: 2,
    value: 'usn_dohod',
    name: 'УСН доходы',
    description: 'УСН доходы – это объекты, учитываемые в упрощенной системе налогообложения – особом порядке уплаты налогов, ориентированном на представителей малого и среднего бизнеса. В качестве объекта выбираются доходы. Налоговая ставка состовляет 6%.'
  },
  {
    id: 3,
    value: 'usn_dohod_rashod',
    name: 'УСН доходы-расходы',
    description: 'УСН доходы – это объекты, учитываемые в упрощенной системе налогообложения – особом порядке уплаты налогов, ориентированном на представителей малого и среднего бизнеса. В качестве объекта выбираются доходы, уменьшенные на величину произведенных расходов. Налоговая ставка состовляет 15%.'
  }
];

export default {
  name: "create_new_orgainsation",
  setup() {
    return {
      step: ref(1),
      step_2: ref(1),
      org_reg_choices: org_reg_choices_dict.map(function (x) { return x.label } ),
      nalog_systems_choices,
      bills_table_columns,
    }
  },
  mounted() {
    this.emitter.on("didGetAllOrganisations", (data) => {
      this.organisation_list = data.organisations;
      this.organisation_options = data.organisations.map(function (element) { return element.name });
    });
    this.emitter.on("organisationCreated", (organisation_data) => {
      console.log(organisation_data)
      if (organisation_data.error === 'org_exist') {
        this.is_inn_error = true;
        this.step = 1;
        this.is_new_org_loading = false
      } else {
        this.$store.commit('updateUserOrganisation', organisation_data);
        this.$store.commit('updateCurrentOrgId', organisation_data.organisation_id);
        this.name = organisation_data.organisation_metadata.name;
        this.org_adress = organisation_data.organisation_metadata.adress;
        this.org_ogrn = organisation_data.organisation_metadata.ogrn;
        this.org_okpo = organisation_data.organisation_metadata.okpo;
        this.org_okved = organisation_data.organisation_metadata.okved;
        const ts = parseInt(organisation_data.organisation_metadata.ogrn_date);
        const date = new Date(ts);
        this.org_created_date = date.toLocaleDateString();
        this.is_new_org_loading = false;
        this.step = 2;
        this.connection.getOrganisationInfo('1', this.$store.state.current_org_id);
        this.connection.getUserInfoAcrossOrg('1', this.$store.state.current_org_id);
      }

    })
  },
  data() {
    return {
      is_inn_error: ref(false),
      is_new_org_loading: ref(false),
      is_existing_org_loading: ref(false),

      organisation_list: ref([]),
      organisation_options: ref([]),
      selected_organisation: ref(''),
      bills_table_rows: [],

      is_director: ref(true),

      org_okpo: ref(''),
      org_okved: ref(''),
      org_created_date: ref(''),

      org_title: ref(''),
      org_adress: ref(''),
      org_creation_date: ref(),
      org_form_raw: ref(org_reg_choices_dict[0].label),

      is_ip: ref(false),

      org_inn: ref(''),
      org_kpp: ref(''),
      org_ogrnip: ref(''),
      org_ogrn: ref(''),

      nalog: ref('osn'),

      new_invite: ref(''),
      invites_list: ref([]),

      new_initial_bill_name: ref('01'),
      new_initial_bill_start_debet: ref(0),
      new_initial_bill_start_credit: ref(0),
      new_initial_bill_period_debet: ref(0),
      new_initial_bill_period_credit: ref(0),
      new_initial_bill_end_debet: ref(0),
      new_initial_bill_end_credit: ref(0),
    }
  },
  methods: {
    add_initial_bill() {
      const new_item = {
        name: this.new_initial_bill_name,
        debet_start: this.new_initial_bill_start_debet,
        credit_start: this.new_initial_bill_start_credit,
        debet_period: this.new_initial_bill_period_debet,
        credit_period: this.new_initial_bill_period_credit,
        debet_end: this.new_initial_bill_end_debet,
        credit_end: this.new_initial_bill_end_credit,
      };
      this.bills_table_rows.push(new_item);
      this.new_initial_bill_name = '01';
      this.new_initial_bill_start_debet = 0;
      this.new_initial_bill_start_credit = 0;
      this.new_initial_bill_period_debet = 0;
      this.new_initial_bill_period_credit = 0;
      this.new_initial_bill_end_debet = 0;
      this.new_initial_bill_end_credit = 0;
    },
    firstStepSubmit() {
      this.is_new_org_loading = true
      this.connection.createOrganisation(this.org_inn, this.is_director, '1');
      // if (this.org_title < 1 ||
      //     this.org_adress < 1 ||
      //     this.org_creation_date.length < 1) {
      //   return
      // }
      // this.step = 2;
      // this.is_ip = org_reg_choices_dict.find( (x) => x.label === this.org_form_raw).value === 'ip';
    },
    secondStepSubmit() {
      // this.step = 3
      setTimeout(() => {
        this.$router.push({ name: 'org_main', params: {} })
      }, 2000);

    },
    finalStepSubmit() {
      setTimeout(() => {
        this.$router.push({ name: 'org_main', params: {} })
      }, 2000);
    },
    addNewInvite() {
      if (this.new_invite.length < 3) { return }
      const item = {
        email: this.new_invite,
      };
      this.invites_list.push(item);
      this.new_invite = ''
      console.log(this.invites_list)
    },
    applyToOrg() {
      this.$router.push({ name: 'org_main', params: {} })
    }

  }

}
</script>

<style scoped>

</style>