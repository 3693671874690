<template>
  <q-breadcrumbs class="q-ma-md" separator=">">
    <q-breadcrumbs-el label="Склад"  @click="$router.push({ name: 'store_abstract_positions_list', params: {} })" />
    <q-breadcrumbs-el :label="label" />
  </q-breadcrumbs>
</template>

<script>
export default {
  name: "store_breadcrumb",
  props: {
    label: { required: true, type: String }
  }
}
</script>

<style scoped>

</style>