<template>
  <div class="row">
    <div class="q-pa-md">
      <finance-breadcrumb label="Справочник контрагентов"/>
      <div class="q-pa-md">
        <q-table
            title="Справочник контрагенто"
            :rows="original_contragents_list"
            :columns="table_columns"
            row-key="name"
            flat
            separator="cell"
            :pagination="tablePagination"
            @row-click="onRowClick"
            v-model="table_rows"
            style="min-width: 1024px"
        >
          <template v-slot:top="props">
            <div class="row" style="width: 100%">
              <div class="col-8">
              </div>
              <div class="col-4 text-right">
                <div class="q-pa-sm inline-block">
                  <q-btn-dropdown color="primary" label="Добавить" icon="add" text-color="white">
                    <q-list>
                      <q-item clickable v-close-popup >
                        <q-item-section>
                          <q-item-label>Контрагент</q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>
                </div>
                <div class="q-pa-sm inline-block">
                  <q-btn
                      flat round dense
                      icon="download"
                      @click="exportTable"
                      class="q-ml-md"
                      text-color="primary"
                  />
                  <q-btn
                      flat round dense
                      :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="props.toggleFullscreen"
                      class="q-ml-md"
                      text-color="primary"
                  />
                </div>
              </div>
            </div>
          </template>
        </q-table>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceBreadcrumb from '../../../components/bills/finance_breadcrumb'
import {ref} from "vue";

const table_columns = [
  { name: 'code', align: 'left', label: 'Инн', field: 'inn'},
  { name: 'common_name', align: 'left', label: 'Название', field: 'name'},
  { name: 'common_quantity', align: 'left', label: 'Адрес', field: 'address'},
]

export default {
  name: "glossary",
  components: {
    FinanceBreadcrumb
  },
  data() {
    this.emitter.on('didReceiveContragentsList', (data) => {
      this.original_contragents_list = data.contragents;
    });
    if (this.connection.isAuthenticated) {
      this.connection.getContragentsList(
          '1',
          this.$store.state.current_org_id
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getContragentsList(
            '1',
            this.$store.state.current_org_id
        );
      })
    }
    return {
      table_columns: ref(table_columns),
      original_contragents_list: ref([])
    }
  }
}
</script>

<style scoped>

</style>