<template>
  <q-header elevated class="text-white" style="background: #24292e" height-hint="61.59">
    <q-toolbar class="q-py-sm q-px-md">
      <q-btn round dense flat :ripple="false" size="18px" color="white" class="q-mr-sm" no-caps>
        <q-img src="./../../assets/logo_transp.png" />
      </q-btn>

      <!-- TODO create search bar -->
      <!-- <q-select ref="search" dark dense standout use-input hide-selected class="GL__toolbar-select" color="black"
        :stack-label="false" label="Поиск по организации" v-model="text" :options="filteredOptions" @filter="filter"
        style="width: 300px">
        <template v-slot:no-option>
          <q-item>
            <q-item-section>
              <div class="text-center">
                <q-spinner-pie color="grey-5" size="24px" />
              </div>
            </q-item-section>
          </q-item>
        </template>

        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps" class="GL__select-GL__menu-link">
            <q-item-section side>
              <q-icon name="collections_bookmark" />
            </q-item-section>
            <q-item-section>
              <q-item-label v-html="scope.opt.label" />
            </q-item-section>
            <q-item-section side :class="{ 'default-type': !scope.opt.type }">
              <q-btn outline dense no-caps text-color="blue-grey-5" size="12px" class="bg-grey-1 q-px-sm">
                {{ scope.opt.type || 'Jump to' }}
                <q-icon name="subdirectory_arrow_left" size="14px" />
              </q-btn>
            </q-item-section>
          </q-item>
        </template>
      </q-select> -->

      <div v-if="$q.screen.gt.sm"
        class="GL__toolbar-link q-ml-xs q-gutter-md text-body2 text-weight-bold row items-center no-wrap">
        <!-- <a @click="routeToHome" class="text-white">
          Главная
        </a> -->
        <a @click="$router.push({ name: 'org_main', params: {} })" class="text-white" v-show="can_open_org_structure">
          Структура организации
        </a>
        <a @click="$router.push({ name: 'bill_saldo', params: {} })" class="text-white" v-show="can_open_finances">
          ОСВ
        </a>
        <a @click="$router.push({ name: 'bills_plan', params: {} })" class="text-white" v-show="can_open_finances">
          План счетов
        </a>
        <a @click="$router.push({ name: 'documents_list', params: {} })" class="text-white" v-show="can_open_finances">
          Документы
        </a>
        <a @click="$router.push({ name: 'payments_list', params: {} })" class="text-white" v-show="can_open_finances">
          Платежные поручения
        </a>
        <a @click="$router.push({ name: 'reports_list', params: {} })" class="text-white" v-show="can_open_finances">
          Отчеты
        </a>
        <a @click="$router.push({ name: 'org_profile_billing', params: {} })" class="text-white"
          v-show="can_open_org_structure">
          Тарифный план
        </a>
        <a @click="$router.push({ name: 'org_profile_buh_automatic', params: {} })" class="text-white"
          v-show="can_open_finances">
          Настройки бухгалтерии
        </a>
      </div>

      <q-space />

      <div class="q-pl-sm q-gutter-sm row items-center no-wrap">
        <q-btn v-if="$q.screen.gt.xs" dense flat icon="notifications">
          <q-badge v-show="!user_info.is_confirmed" color="orange" floating>!</q-badge>
          <q-menu auto-close>
            <q-list bordered class="rounded-borders" style="max-width: 450px">
              <q-item-label header>Уведомления</q-item-label>

              <q-item v-show="!user_info.is_confirmed" clickable
                @click="$router.push({ name: 'profile_edit', params: {} })" v-ripple>
                <q-item-section avatar>
                  <q-avatar>
                    <img src="./../../assets/logo_transp.png">
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label lines="1">Подтверждение аккаунта</q-item-label>
                  <q-item-label caption>

                    Ваш адрес электронной почты <span class="text-weight-bold">{{ user_info.email }}</span> еще не
                    подтвержден. Мы отправили вам письмо с
                    кодом безопасности, пожалуйста, перейдите в настройки профиля и введите код из письма.
                  </q-item-label>
                </q-item-section>

                <q-item-section side top>
                  <q-icon color="orange" name="warning" />
                </q-item-section>
              </q-item>

            </q-list>
          </q-menu>
        </q-btn>
        <!-- <q-btn v-if="$q.screen.gt.xs" dense flat>
          <div class="row items-center no-wrap">
            <q-icon name="add" size="20px" />
            <q-icon name="arrow_drop_down" size="16px" style="margin-left: -2px" />
          </div>
          <q-menu auto-close>
            <q-list dense style="min-width: 100px">
              <q-item clickable class="GL__menu-link"
                @click="$router.push({ name: 'document_create_upd', params: {} })">
                <q-item-section>Новый УПД</q-item-section>
              </q-item>
              <q-item clickable class="GL__menu-link"
                @click="$router.push({ name: 'document_create_factura_avance', params: {} })">
                <q-item-section>Новая счет-фактура</q-item-section>
              </q-item>
              <q-item clickable class="GL__menu-link"
                @click="$router.push({ name: 'document_create_payment', params: {} })">
                <q-item-section>Новое платежное поручение</q-item-section>
              </q-item>
              <q-item clickable class="GL__menu-link text-grey">
                <q-item-section>Мастер отчетов</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn> -->

        <q-btn dense flat no-wrap>
          <q-avatar rounded size="20px" color="teal">
            <!-- <img src="https://cdn.quasar.dev/img/avatar3.jpg"> -->
            {{ (display_name || "?").substring(0, 1) }}
          </q-avatar>
          <q-icon name="arrow_drop_down" size="16px" />

          <q-menu auto-close>
            <q-list dense>
              <q-item class="GL__menu-link-signed-in">
                <q-item-section>
                  <div>Текущий аккаунт <strong>{{display_name}}</strong></div>
                </q-item-section>
              </q-item>
              <q-item class="GL__menu-link-signed-in">
                <q-item-section>
                  <div style="max-width: 400px;">Текущая организация <strong>{{ getCurrentOrgIdName() }}</strong></div>
                </q-item-section>
              </q-item>
              <q-separator />
              <q-item clickable class="GL__menu-link text-grey">
                <q-item-section>Мои задачи</q-item-section>
              </q-item>
              <q-item clickable @click="$router.push({ name: 'my_org_list', params: {} })" class="GL__menu-link">
                <q-item-section>Мои организации</q-item-section>
              </q-item>
              <q-separator />
              <q-item clickable class="GL__menu-link text-grey">
                <q-item-section>Справка</q-item-section>
              </q-item>
              <q-item clickable class="GL__menu-link" @click="$router.push({ name: 'profile_edit', params: {} })">
                <q-item-section>Настройки</q-item-section>
              </q-item>
              <q-item clickable class="GL__menu-link text-red" @click="logout">
                <q-item-section>Выйти</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </q-toolbar>
  </q-header>
  <q-page-container>
    <q-page padding>
      <router-view />
    </q-page>
  </q-page-container>
</template>

<script>
// import ScheduleWidget from '@/components/widgets/schedule_widget.vue'
// import UserProfileWidget from '@/components/widgets/user_profile_widget.vue'
// import OrganisationProfile from '@/components/widgets/organisation_profile.vue'
import {ref} from "vue";

export default {
  name: "dashboard",
  created() {

  },
  data() {
    if (this.connection.isAuthenticated) {
      this.connection.getOrganisationInfo('1', this.$store.state.current_org_id);
      this.connection.getUserInfoAcrossOrg('1', this.$store.state.current_org_id);
    } else {
      this.emitter.on('connectionReady', () => {
          this.connection.getOrganisationInfo('1', this.$store.state.current_org_id);
          this.connection.getUserInfoAcrossOrg('1', this.$store.state.current_org_id);
      });
    }
    this.emitter.on('didReceiveUserInfo', (data) => {
      this.user_info = data;
      this.email = data.email;
      this.display_name = data.display_name;
      if (this.$store.state.current_org_id === -1) {
        if (data.organisations.length > 0) {
          console.log('currr upd', data.organisations[0].id)
          this.$store.commit('updateCurrentOrgId', data.organisations[0].id);
        }
      }
    });
    this.emitter.on('didReceoveUserInfoAcrossOrg', (data) => {
      this.$store.commit('userInfoAcrossOrg', data);
      if (data.user !== undefined) {
        if (data.user.organisations.length > 0) {
          this.can_open_finances = data.user.role.permissions.open_finance;
          this.can_open_org_structure = data.user.role.permissions.edit_roles;
        }
      } else {
        this.can_open_finances = false;
        this.can_open_org_structure = false;
      }
    });

    return {
      user_info: ref(this.$store.state.user_info),
      can_open_finances: ref(false),
      can_open_org_structure: ref(false),
      leftDrawerOpen: ref(true),
      rightDrawerOpen: ref(false),
      leftDrawer: ref(false),
      miniState: ref(false),
      email: ref(''),
      display_name: ref('')
    }
  },
  methods: {
    routeToHome() {
      console.log(this.user_info)
      if (this.user_info.organisations.length > 0) {
        if (this.can_open_org_structure) {
          this.$router.push({name: 'org_main', params: {} });
        } else if (this.can_open_finances) {
          this.$router.push({name: 'bills_plan', params: {} });
        } else {
          this.$router.push({name: 'profile_edit', params: {} });
        }
        
      } else {
        this.$router.push({name: 'profile_edit', params: {} });
      }
    },
    didAuthenticate() {
      console.log("emitted")
    },
    updateSocket(token) {
      console.log(this.$socket, this.socket, token);
    },
    logout() {
      localStorage.clear();
      this.$store.commit('clearStore');
      document.location.reload();
    },
    getCurrentOrgIdName(){
      if (!this.user_info) {
        return "отсутствует"
      }
      if(!this.user_info.organisations) {
        return "отсутствует"
      }
      if (this.user_info.organisations.length == 0) {
        return "отсутствует";
      }
      var index = this.user_info.organisations.findIndex((x) => x.id == this.$store.state.current_org_id);
      if (index == -1) {
        index = 0;
        const new_org_id = this.user_info.organisations[0].id;
        this.$store.commit('updateCurrentOrgId', new_org_id);
        this.connection.getOrganisationInfo('1', new_org_id);
        this.connection.getUserInfoAcrossOrg('1', new_org_id);
      }
      return this.user_info.organisations[index].name;
    }
  }
}
</script>

<style lang="sass">
.GL
  &__select-GL__menu-link
    .default-type
      visibility: hidden

    &:hover
      background: #0366d6
      color: white
      .q-item__section--side
        color: white
      .default-type
        visibility: visible

  &__toolbar-link
    a
      color: white
      text-decoration: none
      &:hover
        opacity: 0.7

  &__menu-link:hover
    background: #0366d6
    color: white

  &__menu-link-signed-in,
  &__menu-link-status
    &:hover
      & > div
        background: white !important

  &__menu-link-status
    color: #0277bd
    &:hover
      color: #0277bd

  &__toolbar-select.q-field--focused
    width: 450px !important
    .q-field__append
      display: none
</style>