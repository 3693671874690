<template>
  <q-card style="min-width: 350px">
    <q-card-section>
      <div class="text-h6">Новая проводка</div>
      <q-input label="Описание проводки" v-model="transfer_name" />
      <q-select :disable="without_document" v-model="transfer_document" :options="documents" label="Документ" />
      <q-toggle v-model="without_document" label="Не привязывать к документу" />
    </q-card-section>

    <q-card-section>
      <q-select v-model="bill_from" :options="initial" label="Счет списания" />
      <q-select v-model="bill_to" :options="initial" label="Счет зачисления" />
    </q-card-section>

    <q-card-section>
      <q-input label="Сумма" type="number" dense v-model="summ" />
      <q-input label="Количество" type="number" dense v-model="pos_count" />
    </q-card-section>
    <q-card-section>
      <div style="max-width: 300px">
        <q-input dense label="Проводка на дату" v-model="transfer_date_proxy" mask="date" :rules="['transfer_date_proxy']">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy @before-show="updateDateProxy" ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
                <q-date v-model="transfer_date_proxy">
                  <div class="row items-center justify-end">
                    <div class="row items-center justify-end q-gutter-sm">
                      <q-btn label="Отмена" color="primary" flat v-close-popup />
                      <q-btn label="Применить" color="primary" flat @click="saveDateProxy" v-close-popup />
                    </div>
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
    </q-card-section>

    <q-card-actions align="right" class="text-primary">
      <q-btn flat label="Отмена" v-close-popup />
      <q-btn flat label="Создать" v-close-popup @click="addNewTransfer()" />
    </q-card-actions>
  </q-card>
</template>

<script>

import {ref} from "vue";
import moment from "moment";


export default {
  name: "create_new_transfer",
  setup() {
  },
  props: {
    period_start: { required: true, type: Number},
    period_end: { required: true, type: Number},
  },
  data() {
    if (this.connection.isAuthenticated) {
      this.connection.gitBillsListFlatCompact('1', this.$store.state.current_org_id);
      this.connection.getDocumentsListFlatCompact('1', this.$store.state.current_org_id);
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.gitBillsListFlatCompact('1', this.$store.state.current_org_id);
        this.connection.getDocumentsListFlatCompact('1', this.$store.state.current_org_id);
      });
    }
    this.emitter.on('didReceiveBillListFlatCompact', (data) => {
      this.initial = data.bills.map((x) => x.display_name );
      this.bills_list = data.bills;
    })
    this.emitter.on('didReceiveDocumentListFlatCompact', (data) => {
      this.documents = data.documents.map((x) => x.display_name );
      this.documents_list = data.documents;
    })

    this.emitter.on('didCreateNewTransfer', (data) => {
      console.log(data);
      this.$q.loading.hide();
    });
    return  {
      bill_from: ref(null),
      bill_to: ref(null),
      transfer_document: ref(null),
      summ: ref(0),
      initial: ref([]),
      bills_list: ref([]),
      documents: ref([]),
      documents_list: ref([]),
      without_document: ref(true),
      transfer_name: ref(null),
      pos_count: ref(1),
      transfer_date_proxy: ref(ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"))),
      transfer_date: ref(ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"))),
      transfer_date_unix: ref(moment(ref(ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD")))).startOf('day').unix())
    }
  },
  methods: {
    updateDateProxy () {
      this.transfer_date_proxy = this.transfer_date
    },
    saveDateProxy() {
      this.transfer_date = this.transfer_date_proxy;
      this.transfer_date_unix = moment(this.transfer_date_proxy).startOf('day').unix() + 18000;
    },
    addNewTransfer() {
        this.$q.loading.show();
        const bill_from_index = this.bills_list.findIndex( (element) => {
          return element.display_name === this.bill_from
        });

        const bill_to_index = this.bills_list.findIndex( (element) => {
          return element.display_name === this.bill_to
        });

        var document_id = null;
        if (this.without_document === false) {
          const document_index = this.bills_list.findIndex( (element) => {
            return element.display_name === this.bill_to
          });
          document_id = this.documents_list[document_index].id;
        }
        const bill_from_id = this.bills_list[bill_from_index].id;
        const bill_to_id = this.bills_list[bill_to_index].id;
        const data = {
          summ: this.summ,
          count: this.pos_count,
          bill_from: bill_from_id,
          bill_to: bill_to_id,
          name: this.transfer_name,
          period_start: this.period_start,
          period_end: this.period_end,
          transfer_date: this.transfer_date_unix
        }
        console.log(this.period_start, this.period_end, 'periods')
        if (document_id != null) {
          data.update({ document_id: document_id });
        }
        this.connection.createNewTransfer('1', this.$store.state.current_org_id, data)
    }
  }
}
</script>

<style scoped>

</style>