<template>
  <div class="row">
    <div class="q-pa-md">
      <finance-breadcrumb label="Режим налогообложения"/>
      <div class="q-pa-md">
        <q-list>
          <q-item v-for="item in nalog_systems" v-bind:key="item.id" tag="label" v-ripple>
            <q-item-section avatar top>
              <q-radio v-model="nalog" :val="item.value" color="cyan" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ item.name }}</q-item-label>
              <q-item-label caption>{{ item.description }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>

  </div>
</template>

<script>
import { ref } from 'vue'
import FinanceBreadcrumb from '../../components/bills/finance_breadcrumb'

const nalog_systems = [
  {
    id: 1,
    value: 'osn',
    name: 'ОСН',
    description: 'Общий налоговый режим является основным и применяется по умолчанию, если индивидуальный предприниматель не подал в налоговый орган заявление о переходе на один из специальных налоговых режимов.'
  },
  {
    id: 2,
    value: 'usn_dohod',
    name: 'УСН доходы',
    description: 'УСН доходы – это объекты, учитываемые в упрощенной системе налогообложения – особом порядке уплаты налогов, ориентированном на представителей малого и среднего бизнеса. В качестве объекта выбираются доходы. Налоговая ставка состовляет 6%.'
  },
  {
    id: 3,
    value: 'usn_dohod_rashod',
    name: 'УСН доходы-расходы',
    description: 'УСН доходы – это объекты, учитываемые в упрощенной системе налогообложения – особом порядке уплаты налогов, ориентированном на представителей малого и среднего бизнеса. В качестве объекта выбираются доходы, уменьшенные на величину произведенных расходов. Налоговая ставка состовляет 15%.'
  }
]

export default {
  name: "tax_reglament",
  components: {
    FinanceBreadcrumb,
  },
  data() {
    return {
      nalog_systems
    }
  },
  setup() {
    return {
      nalog: ref('osn')
    }
  }
}
</script>

<style scoped>

</style>