<template>

  <div class="row">
    <div class="col q-pa-md">
      <div>
        <div class="text-h5">
          Структура
        </div>
        <div v-if="is_all_data_loaded" class="widget-department">
          <blocks-tree @node-click="onNodeClick" :key="department_structure_tree" :data="department_structure"
            :horizontal="widget_settings.horizontal || false" :collapsable="true"
            :props="{ label: 'label', expand: 'expand', children: 'children', key: 'key' }">
            <template #node="{ data }">
              <div>{{ (data.unit_info || { role: '' }).role || " " }}</div>
              <!--          <div>{{ data.unit_info.user || data.unit_info.email }}</div>-->
              <div v-if="data.unit_info.invite.empty">
                <div v-if="data.unit_info.email == undefined">
                  <span class="text-green-9">Место вакантно</span>
                </div>
                <div v-else>
                  <span v-if="data.unit_info.is_me" class="text-green-9">
                    {{ data.unit_info.user || data.unit_info.email }}
                  </span>
                  <span v-else>
                    {{ data.unit_info.user || data.unit_info.email }}
                  </span>
                </div>
              </div>
              <div v-else>
                <div v-if="data.unit_info.invite.is_accepted">
                  <span v-if="data.unit_info.is_me" class="text-green">
                    {{ data.unit_info.user || data.unit_info.email }}
                  </span>
                  <span v-else>
                    {{ data.unit_info.user || data.unit_info.email }}
                  </span>
                </div>
                <div v-else class="text-yellow-9">
                  {{ data.unit_info.invite.email }}
                </div>
              </div>
            </template>
          </blocks-tree>
        </div>
      </div>
    </div>
    
  </div>
  <q-page-sticky position="bottom-left" :offset="[18, 18]">
    <q-fab icon="add" direction="right" color="primary">
      <q-fab-action @click="onCreateNewRole" color="primary" icon="add_to_queue" label="Рабочее место" />
    </q-fab>
  </q-page-sticky>

</template>

<script>
import { ref, reactive, computed } from 'vue'
import moment from "moment";
import 'moment/locale/ru';
import { apiUrl } from "./../../config";
import axios from "axios";
import SberApiKeyWidget from '../../components/widgets/sber_id_api_key_settings.vue';

const finances = {
  balance: 80000,
  bill_summ: 500,
  bill_date: moment("1.10.2024", "DD.MM.YYYY"),
}

const current_plan = {
  id: 0,
  name: "base",
  verbose_name: "Базовый",
  description: "Базовый тариф, 5 должностей, бухгалтерский модуль, 5гб хранилища",
  color: "teal",
  price: 500,
  roles: 3,
  storage: 5,
  role_price: 20,
  storage_price: 5,
  modules: [
    {
      id: 0,
      name: "bills",
      verbose_name: "Бухгалтерия"
    }
  ]
};

const current_stats = {
  roles: 5,
  storage: 2,
};


const plans = [
  {
    id: 0,
    name: "base",
    verbose_name: "Базовый",
    description: "Базовый тариф, 5 должностей, бухгалтерский модуль, 5гб хранилища",
    color: "teal",
    price: 500,
    roles: 5,
    storage: 5,
    modules: [
      {
        id: 0,
        name: "bills",
        verbose_name: "Бухгалтерия"
      }
    ]
  },
  {
    id: 1,
    name: "microprod",
    verbose_name: "Микропредприятие",
    description: "Тариф для микропредприятий, 15 должностей, бухгалтерский модуль, модуль статистики, 15гб  хранилища",
    color: "orange",
    price: 1500,
    roles: 15,
    storage: 15,
    modules: [
      {
        id: 0,
        name: "bills",
        verbose_name: "Бухгалтерия"
      }
    ]
  },
  {
    id: 2,
    name: "prod",
    verbose_name: "Бизнес",
    description: "Премиум тариф, 50 должностей, все доступные модули, 50гб хранилища",
    color: "red",
    price: 5000,
    roles: 50,
    storage: 50,
    modules: [
      {
        id: 0,
        name: "bills",
        verbose_name: "Бухгалтерия"
      }
    ]
  }
]

export default {
  name: "org_main",
  components: [
    SberApiKeyWidget
  ],
  data() {
    this.$store.commit("getOrCreateDepartmentWidgetSettings");
    var widget_settings = {};
    try {
      widget_settings = this.$store.state.department_structure_widget;
    } catch {
      widget_settings = {
        is_hidden: false,
        horizontal: false
      }
    }
    const additional_storage = ref(5);
    axios
      .post(apiUrl + '/api/billing/list/', {})
      .then((response) => {
        this.emitter.emit('onReceiveBillingPlansList', response.data);
      })
      .catch((error) => {
        console.log(error)
      })
    if (this.connection.isAuthenticated) {
      this.connection.getOrgStructure('1', this.$store.state.current_org_id);
      this.connection.getCurrentTarifPlan('1', this.$store.state.current_org_id);
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getOrgStructure('1', this.$store.state.current_org_id);
        this.connection.getCurrentTarifPlan('1', this.$store.state.current_org_id);
      });
    }

    this.emitter.on('didReceiveOrgStructure', (data) => {
      this.$store.commit('updateDepartmentStructure', data.roles);
      this.department_structure = data.roles;
      this.is_all_data_loaded = true;
      this.roles_count = data.roles_count;
    });
    return {
      widget_settings: ref(widget_settings),
      department_structure_tree: ref(0),
      is_all_data_loaded: ref(this.$store.state.department_structure.length > 0),
      department_structure: reactive(this.$store.state.department_structure),
      roles_count: ref(0),
      sber_api_key: ref(null),

      current_plan: ref(current_plan),
      current_stats: ref(current_stats),
      plans: ref(plans),
      finances: finances,
      tarif_plan: ref('base'),
      tarif_plan_loading: ref(false),
      bill_to_pay_presenter: ref(false),
      bill_to_pay_summ: ref(0),
      periods: ref([]),
      period_options: ref([]),
      period_new: ref(null),
      total_price_new: ref(0),
      role_price: ref(0),
      storage_price: ref(0),
      modules_roles: ref(1),
      additional_roles: ref(3),
      storageLabel: computed(() => `${additional_storage.value}ГБ`),
      additional_storage,
      additional_storage_price: ref(0),
      additional_roles_price: ref(0),
      plan_price: ref(0),
      edit_mode: ref(false),
      current_plan_price: ref(current_plan.price),
      additional_storage_current: ref(current_plan.storage),
      additional_roles_current: ref(this.$store.state.max_roles),
      current_plan_roles_min_value: ref(this.$store.state.max_roles),
      current_plan_storage_min_value: ref(5),
      sber_api_key_dialog: ref(false)
    }
  },
  methods: {

    onHorizontalSwitchSettingsToggle() {
      console.log("onHorizontalSwitchSettingsToggle");
      this.department_structure_tree += 1;
      this.$store.commit("updateDepartmentWidgetSettings", this.widget_settings);
    },
    onCreateNewRole() {
      console.log('ASDADASDAASDASD', this.$store.state.max_roles, this.roles_count)
      if (this.$store.state.max_roles > this.roles_count) {
        this.$router.push({ name: 'org_role_new', params: {} })
      } else {
        this.$q.notify('Вы не можете создавать больше рабочих мест.');
      }
    },
    onNodeClick(data, context) {
      console.log(data, context)
      this.$router.push({ name: 'org_role_settings', params: { id: context.key } })
    },
    onInvoiceTouchUp() {
      window.open("./../assets/invoice.pdf", "_blank")
    },
    saveNewTarifPlan() {
      this.tarif_plan_loading = true;
      setTimeout(() => {
        this.tarif_plan_loading = false
      }, 1500);
      this.connection.updateTarifPlan({
        plan: this.tarif_plan
      });
    },
    newBillToPay() {
      this.connection.createNewTarifBillToPay({
        summ: this.bill_to_pay_summ
      })
    },
    rolesValueWillChange(value) {
      console.log(value);
      if (this.period_new == null) {
        this.total_price_new = 0;
        return
      }

      const index = this.periods.findIndex((element) => {
        return element.verbose_name == this.period_new
      })

      this.additional_roles_price = this.periods[index].role_price * (this.additional_roles - 3);
      this.total_price_new = this.plan_price + this.additional_roles_price + this.additional_storage_price;
    },
    storageValueWillChange(value) {
      console.log(value);
      if (this.period_new == null) {
        this.total_price_new = 0;
        return
      }

      const index = this.periods.findIndex((element) => {
        return element.verbose_name === this.period_new
      })

      this.additional_storage_price = this.periods[index].storage_price * (this.additional_storage - 5);
      this.total_price_new = this.plan_price + this.additional_roles_price + this.additional_storage_price;
    },
    periodValueWillChange(value) {
      console.log(value);
      if (value == null) {
        this.total_price_new = 0;
        return
      }
      const index = this.periods.findIndex((element) => {
        return element.verbose_name === value
      })

      this.plan_price = this.periods[index].base_price_month;
      this.additional_roles_price = this.periods[index].role_price * (this.additional_roles - 3);
      this.additional_storage_price = this.periods[index].storage_price * (this.additional_storage - 5);
      this.total_price_new = this.plan_price + this.additional_roles_price + this.additional_storage_price;
    },
    changeNewTarifPlan() {
      if (this.edit_mode) {
        this.current_plan_storage_min_value = this.additional_storage_current;
        this.current_plan_roles_min_value = this.additional_roles_current;
        this.$store.commit('updateMaxRoles', this.additional_roles_current)
        this.edit_mode = false
      } else {
        this.edit_mode = true
      }
    },
    updateCurrentTarifPlan(value) {
      console.log(value);
      this.current_plan_price = current_plan.price + (this.additional_roles_current - current_plan.roles) * current_plan.role_price + (this.additional_storage_current - current_plan.storage) * current_plan.storage_price;
    }
  }
}
</script>

<style></style>