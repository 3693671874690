<template>
  <div class="row">
    <div class="q-pa-md">
      <finance-breadcrumb label="Расчетный счет"/>
      <div class="q-pa-md">
        <div style="min-width: 500px" v-show="!isRequestSended">
          <div class="q-pa-md">
            <q-input v-model="account_number" label="Расчетный счет" />
          </div>
          <div class="q-pa-md">
            <q-input v-model="bank_name" label="Название банка" />
          </div>
          <div class="q-pa-md">
            <q-input v-model="bik" label="БИК банка" />
          </div>
          <div class="q-pa-md">
            <q-input v-model="kor_bill" label="Кор. счет" />
          </div>
          <div class="q-pa-md">
            <q-toggle v-model="is_active" label="Активный"/>
          </div>
          <div class="q-pa-md">
            <q-btn label="Сохранить" color="primary"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceBreadcrumb from '../../components/bills/finance_breadcrumb'
import {ref} from 'vue'


export default {
  name: "account_bills",
  components: {
    FinanceBreadcrumb,
  },
  data() {
    return {
      id: ref(''),
      account_number: ref(''),
      bank_name: ref(''),
      bik: ref(''),
      kor_bill: ref(''),
      is_active: ref(false)
    }
  }
}
</script>

<style scoped>

</style>