<template>
  <div class="row">
    <div class="q-pa-md">
      <q-btn color="primary" label="Назад" padding="xs lg" icon="ion-arrow-back" @click="$router.go(-1)"/>
    </div>
  </div>
  <div class="row">
    <div class="text-h5 text-left q-pa-md">Новая задача</div>
  </div>
  <div class="row">
    <q-form
        @submit="onSubmit"
        @reset="onReset"
        class="q-gutter-md"
    >

      <div class="row col-6">
        <div class="col-8">
          <div class="q-pa-md">
            <q-input v-model="text" label="Название" />
          </div>
          <div class="q-pa-md">
            <q-select v-model="select_model" :options="select_options" label="Назначена" />
          </div>
          <div class="q-pa-md">
            <div class="text-left">
              Описание задачи
            </div>
            <q-editor
                v-model="editor"
                :definitions="{
                upload: {
                  tip: 'Прикрепить файл к задаче',
                  icon: 'cloud_upload',
                  label: 'Прикрепить файл',
                  handler: uploadIt
                }
              }"
                :toolbar="[
                  ['left','center','right','justify'],
                  ['bold', 'italic', 'strike', 'underline'],
                  ['undo','redo'],
                  ['upload']
              ]"
            />
          </div>
        </div>
        <div class="col-4">
          <div class="q-pa-md">
            <div class="text-h6 text-left">Сроки выполнения</div>
            <q-date flat v-model="model" range />
          </div>
        </div>
        <div class="row q-pa-md">
          <div class="text-left">
            <q-btn color="secondary" icon="ion-save" label="Создать задачу" />
          </div>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: "new_task",
  setup () {
    return {
      select_model: ref(null),
      select_options: [
        'Иванов А.В.', 'Петров В.А.', 'Сидоров. Н.А.', 'Лопухин Ж.В.'
      ],
      model: ref({ from: '2021/09/26', to: '2021/09/30' }),
      editor: ref(
          ''
      )
    }
  }
}
</script>

<style scoped>

</style>