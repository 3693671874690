<template>
  <div class="row">
    <div class="q-pa-md">
      <finance-breadcrumb label="Исходящие документы"/>
      <div class="q-pa-md">
        <q-table
            title=""
            :rows="documents"
            :columns="table_cols"
            row-key="name"
            flat
            wrap-cells="true"
            separator="cell"
            table-colspan="8"
        >
          <template v-slot:top="props">
            <div class="row text-h6 q-pa-md">
              {{ table_title }}
            </div>
            <div class="row" style="width: 100%">
              <div class="col-8">
                <div class="inline-block q-pa-sm">
                  <q-btn label="Начало периода" icon-right="event" dense flat color="white" text-color="primary">
                    <q-popup-proxy @before-show="updateFilterProxy" cover transition-show="scale" transition-hide="scale">
                      <q-date v-model="proxyFilterDate">
                        <div class="row items-center justify-end q-gutter-sm">
                          <q-btn label="Cancel" color="primary" flat v-close-popup />
                          <q-btn label="OK" color="primary" flat @click="filterSave" v-close-popup />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-btn>
                </div>
                <div class="inline-block q-pa-sm">
                  <q-btn label="Конец периода" icon-right="event" dense flat color="white" text-color="primary">
                    <q-popup-proxy @before-show="updateFilterProxy" cover transition-show="scale" transition-hide="scale">
                      <q-date v-model="proxyFilterDate">
                        <div class="row items-center justify-end q-gutter-sm">
                          <q-btn label="Cancel" color="primary" flat v-close-popup />
                          <q-btn label="OK" color="primary" flat @click="filterSave" v-close-popup />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-btn>
                </div>
              </div>
              <div class="col-4 text-right">
                <div class="q-pa-sm inline-block">
                  <q-btn
                      color="white" glossy text-color="primary"
                      label="Новый документ"
                      @click="$router.push({name: 'create_new_document', params: {direction: 'out'}})"
                  />
                </div>
                <div class="q-pa-sm inline-block">
                  <q-btn
                      flat round dense
                      icon="download"
                      @click="exportTable"
                      class="q-ml-md"
                      text-color="primary"
                  />
                  <q-btn
                      flat round dense
                      :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="props.toggleFullscreen"
                      class="q-ml-md"
                      text-color="primary"
                  />
                </div>
              </div>
            </div>
          </template>
        </q-table>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceBreadcrumb from '../../components/bills/finance_breadcrumb'

const table_cols = [
  { name: 'date', align: 'left', label: 'Дата', field: 'date', sortable: true},
  { name: 'name', style: 'width: 250px', align: 'left', label: 'Наименование', field: 'name'},
  { name: 'customer.name', style: 'width: 250px', align: 'left', label: 'Контрагент', field: (item) => item.customer.name},
  {
    name: 'total_price',
    align: 'left',
    label: 'Сумма',
    field: (item) => item.values.map(function (x) { return x.gross_price}).reduce((acc, value) => {return acc + value},0)
  },
]
export default {
  name: "outgoing",
  components: {
    FinanceBreadcrumb,
  },
  data() {
    const docs = this.$store.state.base_documents.filter(function (item) { return item.is_outgoing });
    console.log(docs);
    return {
      documents: docs,
      table_cols
    }
  }
}
</script>

<style scoped>

</style>