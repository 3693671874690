<template>
  <div class="row">
    <div class="q-pa-md" style="width: 100%">
      <finance-breadcrumb label="Информация об операции" />
    </div>
    <div class="q-pa-md">
      <q-table
          v-model="document"
          :title="document.name"
          :rows="document.values"
          :columns="table_columns"
          row-key="name"
          flat
          dense
          wrap-cells="true"
          separator="cell"
          table-colspan="8"
          :pagination="tablePagination"
      >
        <template v-slot:top="props">
          <div class="row" style="width: 100%">
            <div class="col-8 text-h6">
              {{ document.name }}
            </div>
            <div class="col-4 text-right">
              <div class="q-pa-sm inline-block">
                <div class="q-pa-sm inline-block">
                  <q-btn
                      flat round dense
                      icon="download"
                      @click="exportTable"
                      class="q-ml-md"
                      text-color="primary"
                  />
                  <q-btn
                      flat round dense
                      :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="props.toggleFullscreen"
                      class="q-ml-md"
                      text-color="primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:header="props">
          <q-tr>
            <q-th colspan="16" class="text-left text-bold">
              <b>Продавец</b>
            </q-th>
          </q-tr>
          <q-tr class=" no-border">
            <q-th colspan="3" class="text-left no-border">
              Адрес
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.seller.adres }}
            </q-th>
          </q-tr>
          <q-tr class=" no-border">
            <q-th colspan="3" class="text-left no-border">
              ИНН/КПП продавца
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.seller.inn_kpp }}
            </q-th>
          </q-tr>
          <q-tr class=" no-border">
            <q-th colspan="3" class="text-left no-border">
              Грузоотправитель и его адрес
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.seller.sender }}
            </q-th>
          </q-tr>
          <q-tr class=" no-border">
            <q-th colspan="3" class="text-left no-border">
              Грузополучатель и его адрес
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.seller.receiver }}
            </q-th>
          </q-tr>
          <q-tr class=" no-border">
            <q-th colspan="3" class="text-left no-border">
              К платеже-расчетному документу
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.seller.to_ref_document }}
            </q-th>
          </q-tr>
          <q-tr class=" no-border">
            <q-th colspan="3" class="text-left no-border">
              Документ об отгрузке
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.seller.senders_document }}
            </q-th>
          </q-tr>
          <q-tr>
            <q-th colspan="16" class="text-left">
              <b>Покупатель</b>
            </q-th>
          </q-tr>
          <q-tr class=" no-border">
            <q-th colspan="3" class="text-left no-border">
              Адрес
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.customer.adres }}
            </q-th>
          </q-tr>
          <q-tr class=" no-border">
            <q-th colspan="3" class="text-left no-border">
              ИНН/КПП покупателя
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.customer.inn_kpp }}
            </q-th>
          </q-tr>
          <q-tr class=" no-border">
            <q-th colspan="3" class="text-left no-border">
              Валюта: наименование, код
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.customer.cash_name }}
            </q-th>
          </q-tr>
          <q-tr class=" no-border">
            <q-th colspan="3" class="text-left no-border">
              Идентификатор государственного контракта, договора
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.customer.gov_document }}
            </q-th>
          </q-tr>
          <q-tr>
            <q-th colspan="17" class="text-left" />
          </q-tr>
          <q-tr>
            <q-th colspan="4" />
            <q-th colspan="2" class="text-left">
              Единица измерения
            </q-th>
            <q-th colspan="7" />
            <q-th colspan="2" class="text-left">
              Страна происхождения товара
            </q-th>
            <q-th colspan="1" />
            <q-th colspan="1">
<!--              Статус-->
            </q-th>
          </q-tr>
          <q-tr :props="props">
            <q-th
                v-for="col in props.cols"
                :key="col.name"
                :props="props"
            >
              {{ col.label }}
            </q-th>
            <q-th>
<!--              <q-btn size="sm" color="white" text-color="primary" flat label="Проводка документа" @click="create_new_operation = true" />-->
            </q-th>
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td
                v-for="col in props.cols"
                :key="col.name"
                :props="props"
            >
              {{ col.value }}
            </q-td>
            <q-td auto-width>
<!--              <q-btn size="sm" color="white" text-color="primary" flat label="Проводка позиции" @click="create_new_operation = true" />-->
            </q-td>
          </q-tr>
        </template>
        <template v-slot:bottom-row>
          <q-tr>
            <q-th colspan="2" />
            <q-th colspan="6" class="text-left text-bold">
              Всего к оплате
            </q-th>
            <q-th colspan="1" class="text-left text-bold">0.0</q-th>
            <q-th colspan="2" class="text-left text-bold"></q-th>
            <q-th colspan="1">0.00</q-th>
            <q-th colspan="1">0.00</q-th>
            <q-th colspan="3" />
          </q-tr>
          <q-tr>
            <q-th colspan="16" style="border: 2px black" />
          </q-tr>
          <q-tr>
            <q-th colspan="3" class="text-left no-border">
              Руководитель организации или иное уполномоченное лицо
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.director }}
            </q-th>
          </q-tr>
          <q-tr>
            <q-th colspan="3" class="text-left no-border">
              Главный бухгалтер или иное уполномоченное лицо
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.fin_director }}
            </q-th>
          </q-tr>
          <q-tr>
            <q-th colspan="3" class="text-left no-border">
              Ответственный за правильность ведения хозяйственной жизни
            </q-th>
            <q-th colspan="13" class="text-left no-border">
              {{ document.stockman }}
            </q-th>
          </q-tr>
        </template>
      </q-table>
    </div>
    <div class="q-pa-md">
      <q-table
          title="Проводки по документу"
          :rows="document.transfers"
          :columns="operations_columns"
          row-key="name"
          flat
          wrap-cells="true"
          separator="cell"
          table-colspan="8"
      >
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th
                v-for="col in props.cols"
                :key="col.name"
                :props="props"
            >
              {{ col.label }}
            </q-th>
            <q-th>

            </q-th>
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td
                v-for="col in props.cols"
                :key="col.name"
                :props="props"
            >
              {{ col.value }}
            </q-td>
<!--            <q-td auto-width>-->
<!--              <q-btn size="sm" color="white" text-color="primary" flat label="Редактировать" />-->
<!--            </q-td>-->
          </q-tr>
        </template>
      </q-table>
    </div>
    <q-dialog v-model="create_new_operation" persistent>
      <create-new-operation :initial_bills="bills_list"></create-new-operation>
    </q-dialog>
  </div>
</template>

<script>
import FinanceBreadcrumb from '../../../components/bills/finance_breadcrumb'
import CreateNewOperation from '../../../components/bills/create_new_operation'
import { exportFile, useQuasar } from 'quasar'
import {ref} from "vue";
/*
* document_id: doc_id,
          position_id: value.id,
          bill_from: 1,
          bill_to: 41,
          summ: value.net_price,
          date: doc_date
* */
var document = {};
var bills_list = []

const operations_columns = [
  { name: 'period', align: 'left', label: 'Дата', field: 'date', sortable: true},
  { name: 'name', align: 'left', label: 'Наименование', field: (item) => document.values.find(x => x.id === item.position_id).name},
  { name: 'bill_debet', align: 'left', label: 'Счет списания', field: (item) => bills_list.find(x => x.id === item.bill_from).name},
  { name: 'bill_credit', align: 'left', label: 'Счет зачисления', field: (item) => bills_list.find(x => x.id === item.bill_to).name},
  { name: 'summ', align: 'left', label: 'Сумма', field: 'summ'},
]

var operations_list = [
]

const table_columns = [
  { name: 'row_number', align: 'left', label: '№ п/п', field: 'row_number'},
  { name: 'product_code', align: 'left', label: 'Код товара/услуги', field: 'product_code'},
  { name: 'name', align: 'left', label: 'Наименование/услуги', field: 'name'},
  { name: 'product_kind_code', align: 'left', label: 'Код вида товара', field: 'product_kind_code'},
  { name: 'measure_code', align: 'left', label: 'Код', field: 'measure_code'},
  { name: 'measure', align: 'left', label: 'Условное обозначение', field: 'measure'},
  { name: 'quantity', align: 'left', label: 'Количество', field: 'quantity'},
  { name: 'price', align: 'left', label: 'Цена за единицу', field: 'price'},
  { name: 'net_price', align: 'left', label: 'Стоимость без налогов, всего', field: 'net_price'},
  { name: 'excise_price', align: 'left', label: 'Сумма акциза', field: 'excise_price'},
  { name: 'tax_percent', align: 'left', label: 'Налоговая ставка', field: 'tax_percent'},
  { name: 'tax_summ', align: 'left', label: 'Сумма налогов', field: 'tax_summ'},
  { name: 'gross_price', align: 'left', label: 'Стоимость с налогами, всего', field: 'gross_price'},
  { name: 'country_code', align: 'left', label: 'код', field: 'country_code'},
  { name: 'country_short_name', align: 'left', label: 'Краткое наименование', field: 'country_short_name'},
  { name: 'customs_decl_number', align: 'left', label: 'Номер таможенной декларации', field: 'customs_decl_number'}
]
function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
      ? formatFn(val)
      : val

  formatted = formatted === void 0 || formatted === null
      ? ''
      : String(formatted)

  formatted = formatted.split('"').join('""')

  return `"${formatted}"`
}
export default {
  name: "operation_info",
  components: {
    FinanceBreadcrumb,
    CreateNewOperation
  },
  data() {
    document = this.getDocumentById(this.$route.params.id);
    bills_list = this.$store.state.base_bills;
    // console.log(doc);
    return {
      operations_columns: operations_columns,
      operations_list: operations_list,
      bills_list: bills_list,
      document: document

    }
  },
  methods: {
    getDocumentById(id) {
      id = parseInt(id);
      const index = this.$store.state.base_documents.findIndex(function (item) {
        return item.id === id;
      });
      console.log(index);
      if (index !== undefined) {
        return this.$store.state.base_documents[index];
      }
    }
  },
  setup() {
    const $q = useQuasar()
    // const doc = this.getDocumentById(this.$route.params.id);
    return {
      create_new_operation: ref(false),
      table_columns,
      tablePagination: {
        rowsPerPage: 0
      },
      exportTable() {
        // naive encoding to csv format
        const columns = table_columns
        const rows = []
        const content = [columns.map(col => wrapCsvValue(col.label))].concat(
            rows.map(row => columns.map(col => wrapCsvValue(
                typeof col.field === 'function'
                    ? col.field(row)
                    : row[col.field === void 0 ? col.name : col.field],
                col.format
            )).join(','))
        ).join('\r\n')

        const status = exportFile(
            'table-export.csv',
            content,
            'text/csv'
        )

        if (status !== true) {
          $q.notify({
            message: 'Browser denied file download...',
            color: 'negative',
            icon: 'warning'
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.no-border
  border: 0
</style>