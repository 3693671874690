<template>
  <div class="container">
    <div class="row">
      <div class="col"> <q-input dense float borderless v-model="postup_bank_plat" label="Назначение платежа" /></div>
      <div class="col"> <q-input dense float borderless v-model="spis_sch_plat" label="Отметки банка" /></div>
    </div>

    <div class="row">
      <div class="col-6  text-h6" style="padding-top: 28px;">ПЛАТЕЖНОЕ ПОРУЧЕНИЕ № {{ in_org_number }}</div>
      <div class="col-3 q-pa-md">
        <q-input dense flat label="Дата" v-model="document_date">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                <q-date mask="DD.MM.YYYY" v-model="document_date"
                  @update:model-value="(newValue) => onDateChanged(newValue)">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Закрыть" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-3 q-pa-md">
        <q-input dense flat v-model="plat_kind" label="Вид платежа" />
      </div>
      <!-- <div class="rectangle">0401060</div> -->
    </div>
    <table>
      <tr>
        <td class="no-left-border" colspan="2" rowspan="1">Сумма прописью</td>
        <td class="no-right-border" colspan="10" rowspan="1"><q-input dense float borderless v-model="summ_letter"
            label="Сумма прописью" hint="Десять тысяч рублей ноль копеек" /></td>
      </tr>
      <tr>
        <td class="no-left-border" colspan="3"><q-input dense float borderless v-model="plat_inn" label="ИНН" /></td>
        <td colspan="2"><q-input dense float borderless v-model="plat_kpp" label="КПП" /></td>
        <td colspan="2" rowspan="2">Сумма</td>
        <td class="no-right-border" colspan="5" rowspan="2"><q-input dense float borderless v-model="summ"
            label="Сумма" />
        </td>
      </tr>
      <tr>
        <td class="no-left-border" colspan="5" rowspan="2"><q-input dense float borderless v-model="plat"
            label="Плательщик" /></td>
      </tr>
      <tr>
        <td colspan="2" rowspan="1">Сч. №</td>
        <td class="no-right-border" colspan="5" rowspan="3">
          <div>
            <q-input dense float borderless v-model="plat_bill_num" label="Сч. №" />
          </div>
          <div>
            <q-input dense float borderless v-model="plat_bik" label="БИК" />
          </div>
          <div>
            <q-input dense float borderless v-model="plat_bill_num_ext" label="Сч. №" />
          </div>
        </td>
      </tr>
      <tr>
        <td class="no-left-border" colspan="5" rowspan="2"><q-input dense float borderless v-model="plat_bank"
            label="Банк плательщика" /></td>
        <td colspan="2">БИК</td>
      </tr>
      <tr>
        <td colspan="2">Сч. №</td>
      </tr>
      <tr>
        <td class="no-left-border" colspan="5" rowspan="2"><q-input dense float borderless v-model="rec_bank"
            label="Банк получателя" /></td>
        <td colspan="2">БИК</td>

        <td class="no-right-border" colspan="5" rowspan="4">
          <div>
            <q-input dense float borderless v-model="rec_bik" label="БИК" />
          </div>
          <div>
            <q-input dense float borderless v-model="rec_bill_num" label="Сч. №" />
          </div>
          <div>
            <q-input dense float borderless v-model="rec_bill_num_ext" label="Сч. №" />
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2">Сч. №</td>
      </tr>
      <tr>
        <td class="no-left-border" colspan="3"><q-input dense float borderless v-model="rec_inn" label="ИНН" /></td>
        <td colspan="2"><q-input dense float borderless v-model="rec_kpp" label="КПП" /></td>
        <td colspan="2" rowspan="2">Сч. №</td>
      </tr>
      <tr>
        <td class="no-left-border" colspan="5" rowspan="4"><q-input dense float borderless v-model="rec"
            label="Получатель" /></td>
      </tr>
      <tr>
        <td colspan="2">Вид. оп</td>
        <td colspan="2" rowspan="3">
          <q-input dense float borderless v-model="vid_op" label="Вид. оп" />
          <q-input dense float borderless v-model="naz_pl" label="Наз. пл." />
          <q-input dense float borderless v-model="code" label="Код" />
        </td>
        <td colspan="2">Срок плат.</td>
        <td class="no-right-border" colspan="" rowspan="3">
          <q-input dense float borderless v-model="ts_plat" label="Срок плат." />
          <q-input dense float borderless v-model="naz_pl_ext" label="Наз. пл." />
          <q-input dense float borderless v-model="rez_field" label="Рез. поле" />
        </td>
      </tr>
      <tr>
        <td colspan="2">Наз. пл.</td>
        <!-- <td colspan="2"><q-input /></td> -->
        <td colspan="2">Очер. плат.</td>
        <!-- <td colspan=""><q-input /></td> -->
      </tr>
      <tr>
        <td colspan="2">Код</td>
        <!-- <td colspan="2"><q-input /></td> -->
        <td colspan="2">Рез. поле</td>
        <!-- <td colspan="1"><q-input /></td> -->
      </tr>
      <tr>
        <td class="no-left-border" colspan="2"><q-input dense float borderless v-model="ext_field_1" /></td>
        <td><q-input dense float borderless v-model="ext_field_2" /></td>
        <td><q-input dense float borderless v-model="ext_field_3" /></td>
        <td colspan="2"><q-input dense float borderless v-model="ext_field_4" /></td>
        <td colspan="2"><q-input dense float borderless v-model="ext_field_5" /></td>
        <td colspan="2"><q-input dense float borderless v-model="ext_field_6" /></td>
        <td class="no-right-border" colspan="2"><q-input dense float borderless v-model="ext_field_7" /></td>
      </tr>
    </table>
    <div class="row">
      <div class="col">
        <q-select v-model="with_nds" :options="nds_percentage_options" label="Ставка НДС" />
      </div>
    </div>
    <div class="row">
      <div class="col"> <q-input dense float borderless v-model="naz_pl_full" label="Назначение платежа" /></div>
      <div class="col"> <q-input dense float borderless v-model="bank_comment" label="Отметки банка" /></div>
    </div>
  </div>
  <div style="margin-top: 10mm;">
    <q-btn color="primary" icon="save" label="Сохранить" @click="onSave" />
  </div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const nds_percentage_options = [
  {
    label: 'НДС 20%',
    value: 0.2,
  },
  {
    label: 'НДС 10%',
    value: 0.1,
  },
  {
    label: 'Без НДС',
    value: 0,
  }
];
export default {
  name: "edit_document_payment",
  data() {
    this.emitter.on("onSaveDocumentUpd", (data) => {
      console.log(data);
      this.$q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Документ не был сохранен',
          cancel: false,
          persistent: true
        }).onOk(() => {
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        this.$q.notify('Документ был изменен');
        this.$router.back();
      }
    })
    this.emitter.on('didReceiveDocumentInfo', (response) => {
      const body = response.data.body;
      this.bank_comment = body.bank_comment;
      this.naz_pl_full = body.naz_pl_full;
      this.ext_field_1 = body.ext_field_1;
      this.ext_field_2 = body.ext_field_2;
      this.ext_field_3 = body.ext_field_3;
      this.ext_field_4 = body.ext_field_4;
      this.ext_field_5 = body.ext_field_5;
      this.ext_field_6 = body.ext_field_6;
      this.ext_field_7 = body.ext_field_7;
      this.rez_field = body.rez_field;
      this.naz_pl_ext = body.naz_pl_ext;
      this.ts_plat = body.ts_plat;
      this.code = body.code;
      this.naz_pl = body.naz_pl;
      this.vid_op = body.vid_op;
      this.rec = body.rec;
      this.rec_kpp = body.rec_kpp;
      this.rec_inn = body.rec_inn;
      this.rec_bill_num_ext = body.rec_bill_num_ext;
      this.rec_bill_num = body.rec_bill_num;
      this.rec_bik = body.rec_bik;
      this.rec_bank = body.rec_bank;
      this.plat_bank = body.plat_bank;
      this.plat_bill_num_ext = body.plat_bill_num_ext;
      this.plat_bill_num = body.plat_bill_num;
      this.plat_bik = body.plat_bik;
      this.plat = body.plat;
      this.summ = body.summ;
      this.plat_kpp = body.plat_kpp;
      this.plat_inn = body.plat_inn;
      this.summ_letter = body.summ_letter;
      this.plat_kind = body.plat_kind;
      this.document_date = moment.unix(body.document_date).format('DD.MM.YYYY');
      this.document_date_unix = body.document_date;
      this.spis_sch_plat = body.spis_sch_plat;
      this.postup_bank_plat = body.postup_bank_plat;
      this.in_org_number = body.in_org_number;
      this.with_nds = body.with_nds;
    });
    if (this.connection.isAuthenticated) {
      this.connection.getDocumentInfo(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getDocumentInfo(
          '1',
          this.$store.state.current_org_id,
          this.$route.params.id
        );
      })
    }
    return {
      step: ref(1),
      document_type: ref(null),
      in_org_number: ref(null),
      bank_comment: ref(null),
      naz_pl_full: ref(null),
      ext_field_1: ref(null),
      ext_field_2: ref(null),
      ext_field_3: ref(null),
      ext_field_4: ref(null),
      ext_field_5: ref(null),
      ext_field_6: ref(null),
      ext_field_7: ref(null),
      rez_field: ref(null),
      naz_pl_ext: ref(null),
      ts_plat: ref(null),
      code: ref(null),
      naz_pl: ref(null),
      vid_op: ref(null),
      rec: ref(null),
      rec_kpp: ref(null),
      rec_inn: ref(null),
      rec_bill_num_ext: ref(null),
      rec_bill_num: ref(null),
      rec_bik: ref(null),
      rec_bank: ref(null),
      plat_bank: ref(null),
      plat_bill_num_ext: ref(null),
      plat_bill_num: ref(null),
      plat_bik: ref(null),
      plat: ref(null),
      summ: ref(null),
      plat_kpp: ref(null),
      plat_inn: ref(null),
      summ_letter: ref(null),
      plat_kind: ref(null),
      document_date: ref(null),
      spis_sch_plat: ref(null),
      postup_bank_plat: ref(null),
      document_date_unix: ref(0),
      with_nds: ref({ value: 0, label: 'Без НДС' }),
      nds_percentage_options: ref(nds_percentage_options),
    }
  },
  methods: {
    onDateChanged(newValue) {
      this.document_date_unix = moment(newValue, 'DD.MM.YYYY').unix()
    },
    onSave() {
      const body = {
        bank_comment: this.bank_comment,
        naz_pl_full: this.naz_pl_full,
        ext_field_1: this.ext_field_1,
        ext_field_2: this.ext_field_2,
        ext_field_3: this.ext_field_3,
        ext_field_4: this.ext_field_4,
        ext_field_5: this.ext_field_5,
        ext_field_6: this.ext_field_6,
        ext_field_7: this.ext_field_7,
        rez_field: this.rez_field,
        naz_pl_ext: this.naz_pl_ext,
        ts_plat: this.ts_plat,
        code: this.code,
        naz_pl: this.naz_pl,
        vid_op: this.vid_op,
        rec: this.rec,
        rec_kpp: this.rec_kpp,
        rec_inn: this.rec_inn,
        rec_bill_num_ext: this.rec_bill_num_ext,
        rec_bill_num: this.rec_bill_num,
        rec_bik: this.rec_bik,
        rec_bank: this.rec_bank,
        plat_bank: this.plat_bank,
        plat_bill_num_ext: this.plat_bill_num_ext,
        plat_bill_num: this.plat_bill_num,
        plat_bik: this.plat_bik,
        plat: this.plat,
        summ: this.summ,
        plat_kpp: this.plat_kpp,
        plat_inn: this.plat_inn,
        summ_letter: this.summ_letter,
        plat_kind: this.plat_kind,
        document_date: this.document_date_unix,
        spis_sch_plat: this.spis_sch_plat,
        postup_bank_plat: this.postup_bank_plat,
        in_org_number: this.in_org_number,
        with_nds: this.with_nds,
        document_type: 'payment'
      };
      console.log(body);
      this.$q.loading.show();
      this.connection.saveDocument(
        'id',
        this.$store.state.current_org_id,
        body,
        this.$route.params.id,
        'payment'
      )
    }

  }
}
</script>

<style scoped>
@page {
  size: A4;
  margin: 0;
}

.container {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  background: white;
  color: black;
  width: 210mm;
  height: 297mm;
  padding: 20mm;
  box-sizing: border-box;
  display: block;
  margin: auto;
  position: relative;
}

.header-section {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 5mm;
}

.title {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5mm;
}

.sub-header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 5mm;
}

.top-right {
  position: absolute;
  top: 15mm;
  right: 15mm;
  text-align: right;
  font-size: 12px;
}

.checkbox {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid black;
  vertical-align: middle;
  margin-left: 5px;
}

.rectangle {
  border: 1px solid black;
  padding: 2px 5px;
  margin-top: 5px;
  display: inline-block;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 10px;
}

th,
td {
  border: 0.5mm solid black;
  text-align: left;
  vertical-align: top;
}

.signature-area {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.signature-box {
  border-top: 1px solid black;
  padding-top: 3px;
  text-align: center;
  width: 45%;
}

.footer {
  margin-top: 20px;
  font-size: 12px;
}

.footer-row {
  display: flex;
  justify-content: space-between;
}

.footer-cell {
  border: 1px solid black;
  padding: 5px;
  text-align: center;
  flex: 1;
}

.footer-cell:not(:last-child) {
  margin-right: 5px;
}

.footer-signatures {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.footer-signature-box {
  width: 45%;
  border-top: 1px solid black;
  text-align: center;
  padding-top: 3px;
}

.no-right-border {
  border-right: transparent !important;
}

.no-left-border {
  border-left: transparent !important;
}
</style>