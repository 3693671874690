<template>
	<div class="q-pa-md">
		<div class="q-ma-md text-h5">
			Мой профиль
		</div>
		<div class="row">
			<div class="col-10 q-pa-lg">
				<div>
					<div class="text-h6">
						Организации
					</div>
					<div>
						<q-list>
							<q-item v-for="item in user_info.organisations" v-bind:key="item.id" clickable v-ripple>
								<q-item-section avatar>
									<q-avatar color="primary" text-color="white">
										{{ item.name.charAt(0) }}
									</q-avatar>
								</q-item-section>
								<q-item-section>{{ item.display_name }}</q-item-section>
								<q-item-section>
									<div>
										{{ user_info.role.role }}
									</div>
									<div v-if="item.admin.id == user_info.id">
										Администратор
									</div>
								</q-item-section>
								<q-item-section>
									<div v-if="isCurrentOrg(item.id)">
										Выбрано сейчас
									</div>
									<div v-else>
										<q-btn dense flat @click="selectOrg(item.id)" label="Переключиться" />
									</div>
									<div>
										<q-btn dense flat @click="leaveOrg(item.id)" label="Покинуть" />
									</div>
								</q-item-section>
							</q-item>
						</q-list>
						<div class="q-ma-sm">
							<q-separator />
						</div>
					</div>
					<div class="q-ma-lg">
						<div v-show="user_info.organisations.length === 0" class="q-pa-md">
							Вы пока не состоите ни в одной организации. Вы можете дождаться приглашения, либо создать
							новую организацию.
						</div>
						<div v-show="user_info.organisations.length === 0" class="q-pa-md">
							<q-btn flat @click="$router.push({ name: 'org_new', params: {} })"
								label="Создать организацию" />
						</div>

						<div v-show="user_info.organisations.length > 0" class="q-pa-md">
							<q-btn flat @click="$router.push({ name: 'org_new', params: {} })"
								label="Добавить организацию" />
						</div>
					</div>
					<div v-show="user_info.organisations.length === 0">
						<div class="text-h6" style="margin-top: 64px">
							Входящие приглашения
						</div>
						<div v-if="user_info.incoming_invites.length > 0" class="q-ma-lg">
							<q-list>
								<q-item v-for="item in user_info.incoming_invites" v-bind:key="item.id" clickable
									v-ripple>
									<q-item-section avatar>
										<q-avatar color="primary" text-color="white">
											{{ item.organisation.name.charAt(0) }}
										</q-avatar>
									</q-item-section>
									<q-item-section>
										<q-item-label>{{ item.organisation.display_name }}</q-item-label>
										<q-item-label caption>Роль: {{ item.role.role }}</q-item-label>
										<q-item-label caption>Пригласил: {{ item.invited_user.email }}</q-item-label>
									</q-item-section>
									<q-item-section>
										<q-btn text-color="white" color="primary" @click="acceptInvite(item.id)"
											label="Принять" />
									</q-item-section>
								</q-item>
							</q-list>
							<div class="q-ma-sm">
								<q-separator />
							</div>
						</div>
						<div v-else class="q-ma-lg">
							<div class="q-pa-sm">
								Вас пока не пригласили ни в одну организацию. Свяжитесь с реководством вашей организации
								для получения приглашения.
							</div>
							<div class="q-ma-sm">
								<q-separator />
							</div>
						</div>
					</div>

					<div class="text-h6" style="margin-top: 64px">
						Исходящие приглашения
					</div>
					<div v-if="user_info.outgoing_invites.length > 0" class="q-ma-lg">
						<q-list>
							<q-item v-for="item in user_info.outgoing_invites" v-bind:key="item.id" clickable v-ripple>
								<q-item-section avatar>
									<q-avatar color="primary" text-color="white">
										{{ item.organisation.name.charAt(0) }}
									</q-avatar>
								</q-item-section>
								<q-item-section>
									<q-item-label>{{ item.organisation.display_name }}</q-item-label>
									<q-item-label caption>Роль: {{ item.role.role }}</q-item-label>
									<q-item-label caption>Пользователь: {{ item.email }}</q-item-label>
								</q-item-section>
								<q-item-section>
									<q-btn text-color="white" color="primary" @click="declineInvite(item.id)"
										label="Отозвать" />
								</q-item-section>
							</q-item>
						</q-list>
						<div class="q-ma-sm">
							<q-separator />
						</div>
					</div>
					<div v-else class="q-ma-lg">
						<div class="q-pa-sm">
							Вы пока не пригласили никого в организацию.
						</div>
						<div class="q-ma-sm">
							<q-separator />
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import { ref } from "vue";

export default {
	name: "my_org",
	data() {
		this.emitter.on('didAcceptInvite', (data) => {
			this.$store.commit('updateCurrentOrgId', data.org_id);
			this.connection.getOrganisationInfo('1', data.org_id);
			this.$q.loading.hide();
			this.$router.push({ name: 'home', params: {} });
		});
		// this.emitter.on('*', (type, e) => console.log(type, e))
		this.emitter.on('didReceiveLeaveOrganisation', (data) => {
			console.log(data);
			this.connection.getUserInfo();
		});
		this.emitter.on("didReceiveUserInfo", (data) => {
			this.user_info = data;
			this.last_name = data.last_name;
			this.first_name = data.first_name;
			this.surname = data.surname;
			this.is_confirmed = data.is_confirmed;
			this.user_info_loaded = true;
			this.email = data.email;
		});
		this.emitter.on('onResendConfirmationCode', (data) => {
			console.log("resend", data);
			setTimeout(() => {
				this.code_request_sended = false;
			}, 1000);
		});
		this.emitter.on('didEditUserInfo', (data) => {
			console.log(data);
			setTimeout(() => {
				this.isRequestSended = false
			}, 1000);
			// this.isRequestSended = false;
		});
		this.emitter.on('didConfirmUser', (data) => {
			setTimeout(() => {
				this.code_request_sended = false;
			}, 1000)
			if (data.error) {
				this.$q.dialog({
					title: 'Ошибка',
					message: 'Неверный код подтверждения',
					cancel: false,
					persistent: true
				}).onOk(() => {
					this.confirmation_code = null;
				}).onCancel(() => {
					console.log('cancel')
				}).onDismiss(() => {
					console.log('dismiss')
				})
			} else {
				console.log(data);
				this.confirmation_code = null;
				// this.$q.loading.hide();
			}
		});

		if (this.connection.isAuthenticated) {
			this.connection.getUserInfo()
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getUserInfo();
			})
		}
		return {
			isRequestSended: ref(false),
			last_name: ref(''),
			first_name: ref(''),
			surname: ref(''),
			user_info: ref(null),
			confirmation_code: ref(null),
			user_info_loaded: ref(true),
			is_confirmed: ref(false),
			code_request_sended: ref(false),
			selected_avatar: ref(null),
			email: ref('')
		}
	},
	mounted() {

	},
	methods: {
		changeUser() {
			this.isRequestSended = true
			const data = {
				last_name: this.last_name,
				first_name: this.first_name,
				surname: this.surname
			};
			this.connection.editUserInfo(data);
		},
		sendConfirmationCode() {
			// this.$q.loading.show();
			this.connection.confirmUser('1', parseInt(this.confirmation_code.replace(/\s/g, '')));
			this.code_request_sended = true;
		},
		resendConfirmationCode() {
			this.connection.resendConfirmationCode('1');
			this.code_request_sended = true;
		},
		acceptInvite(invite_id) {
			this.$q.loading.show();
			this.connection.acceptInvite('1', invite_id);
		},
		declineInvite(invite_id) {
			this.connection.declineInvite('1', invite_id);
		},
		isCurrentOrg(org_id) {
			return this.$store.state.current_org_id == org_id;
		},
		selectOrg(org_id) {
			this.$store.commit('updateCurrentOrgId', org_id);
            this.connection.getOrganisationInfo('1', org_id);
			this.connection.getUserInfoAcrossOrg('1', org_id);
            // this.$router.push({ name: 'home', params: {} });
		},
		leaveOrg(org_id) {
			if (this.isCurrentOrg(org_id)) {
				const new_org_list = this.user_info.organisations.map((x) => {
					return x.id;
				}).filter ((x) => x.id != org_id);
				if (new_org_list.length > 0) {
					const new_org_id = new_org_list[0];
					console.log('new org id', new_org_id);
					this.$store.commit('updateCurrentOrgId', new_org_id);
					this.connection.getOrganisationInfo('1', new_org_id);
					this.connection.getUserInfoAcrossOrg('1', new_org_id);
				} else {
					const new_org_id = -1;
					console.log('new org id', new_org_id);
					this.$store.commit('updateCurrentOrgId', new_org_id);
				}
			}
			this.connection.leaveOrganisation('1', org_id);
		}
	}
}
</script>

<style scoped></style>