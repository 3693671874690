<template>
  <div class="row">
    <div class="q-pa-md">
      <store-braadcrumb label="Позиции на складе"/>
      <div class="q-pa-md">
        <q-table
            title="Позиции на складе"
            :rows="original_positions_list"
            :columns="table_columns"
            row-key="name"
            flat
            separator="cell"
            :pagination="tablePagination"
            @row-click="onRowClick"
            v-model="table_rows"
            style="min-width: 1024px"
        >
          <template v-slot:top="props">
            <div class="row" style="width: 100%">
              <div class="col-8">
              </div>
              <div class="col-4 text-right">
                <div class="q-pa-sm inline-block">
                  <q-btn-dropdown color="primary" label="Добавить" icon="add" text-color="white">
                    <q-list>
                      <q-item clickable v-close-popup @click="$router.push({name: 'create_new_document', params: {direction: 'in'}})">
                        <q-item-section>
                          <q-item-label>Новая позиция</q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>
                </div>
                <div class="q-pa-sm inline-block">
                  <q-btn
                      flat round dense
                      icon="download"
                      @click="exportTable"
                      class="q-ml-md"
                      text-color="primary"
                  />
                  <q-btn
                      flat round dense
                      :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="props.toggleFullscreen"
                      class="q-ml-md"
                      text-color="primary"
                  />
                </div>
              </div>
            </div>
          </template>
        </q-table>
      </div>
    </div>
  </div>
</template>

<script>
import StoreBraadcrumb from '../../components/store/store_breadcrumb'
import {ref} from "vue";

const table_columns = [
  // {
  //   name: 'index',
  //   label: '№ п/п',
  //   field: 'index'
  // },
  { name: 'code', align: 'left', label: 'Код товара', field: 'code'},
  { name: 'common_name', align: 'left', label: 'Название', field: 'common_name'},
  { name: 'common_quantity', align: 'left', label: 'Количество', field: 'common_quantity'},
]

export default {
  name: "abstract_positions_list",
  components: {
    StoreBraadcrumb
  },
  data() {
    this.emitter.on('didReceiveAbstractPositionsList', (data) => {
      this.original_positions_list = data.abstract_positions;
    });
    if (this.connection.isAuthenticated) {
      this.connection.getAbstractPositionsList(
          '1',
          this.$store.state.current_org_id
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getAbstractPositionsList(
            '1',
            this.$store.state.current_org_id
        );
      })
    }
    return {
      table_columns: ref(table_columns),
      original_positions_list: ref([])
    }
  }
}
</script>

<style scoped>

</style>