<template>
  <div class="row">
    <div class="q-pa-md">
      <finance-breadcrumb label="Карточка счета" />
      <div class="q-pa-md">
        <q-table
            :title="this.$route.params.name + ' - ' + this.$route.params.description"
            :rows="table_rows"
            :columns="table_columns"
            row-key="name"
            flat
            wrap-cells="true"
            separator="cell"
            table-style="max-width: 800px;"
            table-colspan="8"
            :pagination="tablePagination"
            @row-click="onBillInfoRowClick"
        >
          <template v-slot:top="props">
            <div class="row text-h6 q-pa-md">
              {{ table_title }}
            </div>
            <div class="row" style="width: 100%">
              <div class="col-8">
                <div class="inline-block q-pa-sm">
                  <q-btn label="Начало периода" icon-right="event" dense flat color="white" text-color="primary">
                    <q-popup-proxy @before-show="updateFilterProxy" cover transition-show="scale" transition-hide="scale">
                      <q-date v-model="proxyFilterDate">
                        <div class="row items-center justify-end q-gutter-sm">
                          <q-btn label="Cancel" color="primary" flat v-close-popup />
                          <q-btn label="OK" color="primary" flat @click="filterSave" v-close-popup />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-btn>
                </div>
                <div class="inline-block q-pa-sm">
                  <q-btn label="Конец периода" icon-right="event" dense flat color="white" text-color="primary">
                    <q-popup-proxy @before-show="updateFilterProxy" cover transition-show="scale" transition-hide="scale">
                      <q-date v-model="proxyFilterDate">
                        <div class="row items-center justify-end q-gutter-sm">
                          <q-btn label="Cancel" color="primary" flat v-close-popup />
                          <q-btn label="OK" color="primary" flat @click="filterSave" v-close-popup />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-btn>
                </div>
              </div>
              <div class="col-4 text-right">
                <div class="q-pa-sm inline-block">
                  <q-btn
                      color="white" glossy text-color="primary"
                      label="Новая проводка"
                      @click="$router.push({name: 'create_new_transfer', params: {}})"
                  />
                </div>
                <div class="q-pa-sm inline-block">
                  <q-btn
                      flat round dense
                      icon="download"
                      @click="exportTable"
                      class="q-ml-md"
                      text-color="primary"
                  />
                  <q-btn
                      flat round dense
                      :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="props.toggleFullscreen"
                      class="q-ml-md"
                      text-color="primary"
                  />
                </div>
              </div>
            </div>
          </template>
          <template v-slot:header="props">
            <q-tr>
              <q-th colspan="2" />
              <q-th colspan="2">
                Дебет
              </q-th>
              <q-th colspan="2">
                Кредит
              </q-th>
              <q-th colspan="1" />
            </q-tr>
            <q-tr :props="props">
              <q-th
                  v-for="col in props.cols"
                  :key="col.name"
                  :props="props"

              >
                {{ col.label }}
              </q-th>
            </q-tr>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td
                  key="period"
                  :props="props"
              >
                {{ props.row.period }}
                <q-popup-edit v-model="props.row.period" buttons v-slot="scope">
                  <q-input dense v-model="scope.value" autofocus @keyup.enter="scope.set" :rules="['date']">
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
                          <q-date v-model="scope.value">
                            <div class="row items-center justify-end">
                              <q-btn v-close-popup label="Close" color="primary" flat />
                            </div>
                          </q-date>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </q-popup-edit>
              </q-td>
              <q-td
                  key="period"
                  :props="props"
                  @click="onRowClick('', props.row)"
              >
                {{ props.row.name }}
              </q-td>
              <q-td
                  key="period"
                  :props="props"
              >
                {{ props.row.bill_debet }}
                <q-popup-edit v-model="props.row.bill_debet" buttons v-slot="scope">
                  <q-select v-model="scope.value" @keyup.enter="scope.set" :options="initial_for_select"/>
                </q-popup-edit>
              </q-td>
              <q-td
                  key="period"
                  :props="props"
              >
                {{ props.row.summ_debet }}
                <q-popup-edit v-model="props.row.summ_debet" buttons v-slot="scope">
                  <q-input v-model.number="scope.value" dense autofocus @keyup.enter="scope.set" />
                </q-popup-edit>
              </q-td>
              <q-td
                  key="period"
                  :props="props"
              >
                {{ props.row.bill_credit }}
                <q-popup-edit v-model="props.row.bill_credit" buttons v-slot="scope">
                  <q-select v-model="scope.value" @keyup.enter="scope.set" :options="initial_for_select"/>
                </q-popup-edit>
              </q-td>
              <q-td
                  key="period"
                  :props="props"
              >
                {{ props.row.summ_credit }}
                <q-popup-edit v-model="props.row.summ_credit" buttons v-slot="scope">
                  <q-input v-model.number="scope.value" dense autofocus @keyup.enter="scope.set" />
                </q-popup-edit>
              </q-td>
              <q-td
                  key="period"
                  :props="props"
              >
                {{ props.row.current_saldo }}
                <q-popup-edit v-model="props.row.current_saldo" buttons v-slot="scope">
                  <q-input v-model.number="scope.value" dense autofocus @keyup.enter="scope.set" />
                </q-popup-edit>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
      <q-dialog v-model="create_new_operation" persistent>
        <create-new-operation :initial_bills="bills_list"></create-new-operation>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import FinanceBreadcrumb from '../../../components/bills/finance_breadcrumb'
import CreateNewOperation from '../../../components/bills/create_new_operation'
import {ref} from "vue";
import { exportFile, useQuasar } from 'quasar'

const table_columns = [
  { name: 'period', align: 'left', label: 'Период', field: 'period', sortable: true},
  { name: 'name', align: 'left', label: 'Документ', field: 'name'},
  { name: 'bill_to', align: 'left', label: 'Счет', field: 'bill_to'},
  { name: 'summ', align: 'left', label: 'Сумма', field: 'summ'},
  { name: 'bill_from', align: 'left', label: 'Счет', field: 'bill_from'},
  { name: 'summ', align: 'left', label: 'Сумма', field: 'summ'},
  { name: 'summ', align: 'left', label: 'Текущее сальдо', field: 'summ'},
]

var table_rows = [
  {
    id: 0,
    period: new Date(2021, 3, 22).toLocaleDateString(),
    name: 'Накладная №000-001 от 22.03.21',
    document_id: 12,
    analytic: '',
    analytic_credit: 'Завод металлоизделий, накладная №000-001 от 22.03.21',
    bill_debet: '40.01',
    summ_debet: '0',
    bill_credit: '60.01',
    summ_credit: '20000',
    current_saldo: '280000',
    is_work_act: false
  },
  {
    id: 1,
    period: new Date(2021, 3, 22).toLocaleDateString(),
    name: 'Акт выполненых работ №000-012 от 2.08.21',
    document_id: 13,
    analytic: '',
    analytic_credit: 'Завод металлоизделий, накладная №000-001 от 22.03.21',
    bill_debet: '40.01',
    summ_debet: '0',
    bill_credit: '60.01',
    summ_credit: '40000',
    current_saldo: '320000',
    is_work_act: true
  }
]

function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
      ? formatFn(val)
      : val

  formatted = formatted === void 0 || formatted === null
      ? ''
      : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}
var table_title = ""
function prepareOptions(arr) {
  return arr.map(function (item){
    return item.name
  });
}
export default {
  name: "bill_info",
  components: {
    FinanceBreadcrumb,
    CreateNewOperation
  },
  created() {
    console.log(this.$route.params);
    table_title = this.$route.params.name + ' - ' + this.$route.params.description;
  },
  mounted() {
    this.emitter.on("addNewOperation", (newOperationInfo) => {
      console.log('token 2', newOperationInfo);
      this.updateTableFilter();
    })
  },
  data() {

    var bill_index = this.$store.state.base_bills.findIndex((element) => {
      console.log(element.name, this.$route.params.name)
      return element.name == this.$route.params.name
    })
    if (bill_index < 0) {
      bill_index = 0;
    }
    return {
      table_rows: this.$store.state.base_bills[bill_index].transfers,
      bills_list: this.$store.state.base_bills,
      initial_for_select: prepareOptions(this.$store.state.base_bills)
    }
  },
  setup() {
    const $q = useQuasar()
    const filterDate = ref({from: '2021/11/01', to: new Date().toLocaleDateString() })
    const proxyFilterDate = ref({from: '2021/11/01', to: new Date().toLocaleDateString()})
    return {
      table_title,
      filterDate,
      proxyFilterDate,
      table_columns,
      create_new_operation: ref(false),
      tablePagination: {
        rowsPerPage: 0
      },
      updateFilterProxy () {
        proxyFilterDate.value = filterDate.value
      },
      filterSave () {
        filterDate.value = proxyFilterDate.value
      },
      exportTable() {
        // naive encoding to csv format
        const columns = table_columns
        const rows = table_rows
        const content = [columns.map(col => wrapCsvValue(col.label))].concat(
            rows.map(row => columns.map(col => wrapCsvValue(
                typeof col.field === 'function'
                    ? col.field(row)
                    : row[col.field === void 0 ? col.name : col.field],
                col.format
            )).join(','))
        ).join('\r\n')

        const status = exportFile(
            'table-export.csv',
            content,
            'text/csv'
        )

        if (status !== true) {
          $q.notify({
            message: 'Browser denied file download...',
            color: 'negative',
            icon: 'warning'
          })
        }
      }
    }
  },
  methods: {
    onBillInfoRowClick(event, row, index) {
      console.log(row);
      if (table_rows[index].is_work_act) {
        this.$router.push({ name: 'work_act_operation_info', params: table_rows[index] })
      } else {
        this.$router.push({ name: 'bill_operation_info', params: table_rows[index] })
      }
    },
    onRowClick(event, row, index) {
      console.log(row);
      console.log(index)
      if (row.is_work_act) {
        this.$router.push({ name: 'work_act_operation_info', params: row })
      } else {
        this.$router.push({ name: 'bill_operation_info', params: row })
      }
    },
    onSubRowClick(event, row, index) {
      console.log(index);
      if (row) {
        this.$router.push({ name: 'work_act_operation_info', params: row })
      } else {
        this.$router.push({ name: 'bill_operation_info', params: row })
      }
    },
    updateTableFilter() {
      var bill_index = this.$store.state.base_bills.findIndex((element) => {
        console.log(element.name, this.$route.params.name)
        return element.name == this.$route.params.name
      })
      if (bill_index < 0) {
        bill_index = 0;
      }
      this.table_rows = this.$store.state.base_bills[bill_index].documents
    }
  },
}
</script>

<style scoped>

</style>