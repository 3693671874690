var base_summ_bills = {
    debet_start: 0,
    credit_start: 0,
    debet_period: 0,
    credit_period: 0,
    debet_end: 0,
    credit_end: 0,
}

const store = {
    state: {
        isHasCredentials: localStorage.getItem("isHasCredentials") || false,
        token: localStorage.getItem("token"),
        token_uuid: localStorage.getItem("token_uuid"),
        base_bills: localStorage.getItem('bills'),
        base_documents: localStorage.getItem('documents'),
        base_summ_bills: base_summ_bills,
        organisation_info: localStorage.getItem('organisation_info'),
        organisation_structure: JSON.parse(localStorage.getItem('organisation_structure')),
        user_info: localStorage.getItem('user_info') || {},
        department_structure_widget: {},
        user_widgets_settings: {},
        user_info_across_org: {},
        department_structure: [],
        max_roles: localStorage.getItem('max_roles') || 5,
        current_org_id: localStorage.getItem('current_org_id') || -1
    },
    mutations: {
        initStore(state) {
            console.log('initStore', state);
        },
        // eslint-disable-next-line no-unused-vars
        clearStore(state) {
            state = {};
        },
        updateCurrentOrgId(state, org_id) {
            state.current_org_id = org_id;
            localStorage.setItem('current_org_id', org_id);
        },
        updateDepartmentStructure(state, data) {
            state.department_structure = data;
        },
        updateMaxRoles(state, value) {
            state.max_roles = value;
            localStorage.setItem('max_roles', value);
        },
        getOrCreateDepartmentWidgetSettings(state) {
            try {
                state.department_structure_widget = JSON.parse(localStorage.getItem('department_structure_widget'));
                if (Object.keys(state.department_structure_widget).length === 0) {
                    throw 'not-found'
                }
            } catch {
                state.department_structure_widget = {
                    is_hidden: false,
                    horizontal: false,
                };
                localStorage.setItem('department_structure_widget', JSON.stringify(state.department_structure_widget));
            }
        },
        updateDepartmentWidgetSettings(state, data) {
            try {
                state.department_structure_widget.is_hidden = data.is_hidden;
                state.department_structure_widget.horizontal = data.horizontal;
                localStorage.setItem('department_structure_widget', JSON.stringify(state.department_structure_widget));
            } catch {
                console.log('undefined keys for action updateDepartmentWidgetSettings')
            }
        },
        updateBills(state) {
            state.base_summ_bills = {
                debet_start: 0,
                credit_start: 0,
                debet_period: 0,
                credit_period: 0,
                debet_end: 0,
                credit_end: 0,
            }
            state.base_bills.forEach((bill) => {
                // console.log(bill);
                if (bill.transfers == undefined) {
                    return
                }
                bill.transfers.forEach((transfer) => {
                    if (transfer.bill_from == bill.id) {
                        bill.credit_period += parseFloat(transfer.summ);
                    }
                    if (transfer.bill_to == bill.id) {
                        bill.debet_period += parseFloat(transfer.summ);
                    }
                });
            });
            state.base_bills.forEach(element => {
                element.credit_start = 0;
                element.debet_start = 0;
                if (element.documents == undefined) {
                    element.documents = []
                }
                element.credit_period = element.documents.map(item => parseFloat(item.summ_credit)).reduce((acc, value) => {
                    return acc + value
                },0)
                element.debet_period = element.documents.map(item => parseFloat(item.summ_debet)).reduce((acc, value) => {
                    return parseFloat(acc) + parseFloat(value)
                }, 0)
                element.credit_end = parseFloat(element.credit_start) + parseFloat(element.credit_period)
                element.debet_end = parseFloat(element.debet_start) + parseFloat(element.debet_period)
                state.base_summ_bills.credit_start += element.credit_start
                state.base_summ_bills.debet_start += element.debet_start
                state.base_summ_bills.credit_period += element.credit_period
                state.base_summ_bills.debet_period += element.debet_period
                state.base_summ_bills.credit_end += element.credit_end
                state.base_summ_bills.debet_end += element.debet_end
            })
        },
        updateUserOrganisation(state, user_data) {
            state.organisation_info = user_data;
            localStorage.setItem('organisation_info', JSON.stringify(user_data));
        },
        setAuthenticate(state, user_data) {
            state.isHasCredentials = true;
            localStorage.setItem('isHasCredentials', true);
            state.token = user_data.token;
            state.token_uuid = user_data.token_uuid;
            if (state.token != undefined) {
                localStorage.setItem("token", state.token);
                localStorage.setItem("token_uuid", state.token_uuid);
            }
        },
        updateUserInfo(state, data) {
            state.user_info = data;
            localStorage.setItem('user_info', JSON.stringify(data));
        },
        updateOrganisationStructure(state, data) {
            state.organisation_structure = data;
            localStorage.setItem('organisation_structure', JSON.stringify(data));
        },
        addNewBillToStore(state, newBillInfo) {
            // console.log(this.parent_bill, this.bill_description, this.bill_name)
            const newBill = {
                name: newBillInfo.bill_name,
                description: newBillInfo.bill_description,
                debet_start: 0,
                credit_start: 0,
                debet_period: 0,
                credit_period: 0,
                debet_end: 0,
                credit_end: 0,
            }
            const index = state.base_bills.findIndex(function (element) {
                return element.name == newBillInfo.parent_bill
            })
            if (index != undefined) {
                state.base_bills[index].subbils.push(newBill)
            }
            this.syncData(state);
        },
        addNewDocument(state, newDocument) {
            state.base_documents.push(newDocument)
        },
        syncData(state) {
            localStorage.setItem('documents', JSON.stringify(state.base_documents));
            localStorage.setItem('bills', JSON.stringify(state.base_bills));
        },
        addNewTransfer(state, newTransfer) {
            const bill_from_parent_id = newTransfer.bill_from_parent;
            const bill_from_id = newTransfer.bill_from;
            if (bill_from_parent_id != undefined) {
                const index_parent = state.base_bills.findIndex(function (element){ return element.id == bill_from_parent_id });
                const index = state.base_bills[index_parent].subbils.findIndex(function (element){ return element.id == bill_from_id });
                state.base_bills[index_parent].subbils[index].transfers.push(newTransfer);
            } else {
                const index = state.base_bills.findIndex(function (element){ return element.id == bill_from_id });
                state.base_bills[index].transfers.push(newTransfer);
            }

            const bill_to_parent_id = newTransfer.bill_to_parent;
            const bill_to_id = newTransfer.bill_to;
            if (bill_to_parent_id != undefined) {
                const index_parent = state.base_bills.findInde(function (element){ return element.id == bill_to_parent_id });
                const index = state.base_bills[index_parent].subbils.findIndex(function (element){ return element.id == bill_to_id });
                state.base_bills[index_parent].subbils[index].transfers.push(newTransfer);
            } else {
                const index = state.base_bills.findIndex(function (element){ return element.id == bill_to_id });
                state.base_bills[index].transfers.push(newTransfer);
            }

            const document_id = newTransfer.document_id;
            if (document_id != undefined) {
                const index = state.base_documents.findIndex(function (element) { return element.id == document_id });
                state.base_documents[index].transfers.push(newTransfer);
            }
        },
        userInfoAcrossOrg(state, data) {
            state.user_info_across_org = data
        },
        addNewOperationToStore(state, newOperationInfo) {
            // console.log(this.parent_bill, this.bill_description, this.bill_name)
            const newOperationFrom = {
                id: 0,
                period: new Date(2021, 3, 22).toLocaleDateString(),
                name: newOperationInfo.operation_name + ' ' + newOperationInfo.operation_description,
                document_id: 1111,
                bill_debet: newOperationInfo.bill_to,
                summ_debet: 0,
                bill_credit: newOperationInfo.bill_from,
                summ_credit: newOperationInfo.operation_summ,
                current_saldo: 0,
                is_work_act: false,
                transfers: []
            }
            const newOperationTo = {
                id: 0,
                period: new Date(2021, 3, 22).toLocaleDateString(),
                name: newOperationInfo.operation_name + ' ' + newOperationInfo.operation_description,
                document_id: 1111,
                bill_debet: newOperationInfo.bill_from,
                summ_debet: newOperationInfo.operation_summ,
                bill_credit: newOperationInfo.bill_tp,
                summ_credit: 0,
                current_saldo: 0,
                is_work_act: false,
                transfers: []
            }
            const indexFrom = state.base_bills.findIndex(function (element) {
                return element.name == newOperationInfo.bill_from
            })
            const indexTo = state.base_bills.findIndex(function (element) {
                return element.name == newOperationInfo.bill_to
            })
            if (indexFrom != undefined) {
                state.base_bills[indexFrom].documents.push(newOperationFrom)
            }
            if (indexTo != undefined) {
                state.base_bills[indexTo].documents.push(newOperationTo)
            }
        }
    },
    modules: {}

}

export default store