import {wsUrl} from "../config";

class Connection {
    constructor(emitter) {
        this.emitter = emitter
        this.token = undefined
        this.token_uuid = undefined
        this.socket = undefined
        this.isAuthenticated = false
    }
    updateCredentials(token, token_uuid) {
        this.token = token;
        this.token_uuid = token_uuid;
    }
    updateUser(user_data) {
        this.token = user_data.token;
        this.token_uuid = user_data.token_uuid;
    }
    makeConnection() {
        if (this.socket !== undefined) { return }
        console.log('connect to', wsUrl);
        this.socket = new WebSocket(wsUrl);
        this.socket.addEventListener('open', (() => { this.onOpen() }));
        this.socket.addEventListener('message', ( event => { this.onMessage(event) }))
        this.socket.addEventListener('close', ( () => { this.onClose() }))
    }
    onClose() {
        if (this.isAuthenticated === false) {
            return
        }
        this.socket = undefined
        this.isAuthenticated = false
        this.makeConnection()
    }
    confirmUser(request_id, code) {
        this.send({
            request_id: request_id,
            action: 'confirm_user',
            code: code
        })
    }
    resendConfirmationCode(request_id) {
        this.send({
            action: 'resend_confirmation_code',
            request_id: request_id
        })
    }
    getUserInfo() {
        this.send({
            action: 'user_info'
        })
    }
    leaveOrganisation(request_id, org_id) {
        this.send({
            action: 'leave_organisation',
            id: request_id,
            org_id: org_id
        })
    }
    getOrgStructure(request_id, org_id) {
        this.send({
            action: 'get_org_structure',
            request_id: request_id,
            org_id: org_id
        })
    }
    getOrgStructureFlat(request_id, org_id) {
        this.send({
            action: 'get_org_structure_flat',
            request_id: request_id,
            org_id: org_id
        })
    }
    editUserInfo(data) {
        this.send({
            action: 'edit_user_info',
            request_id: 'request_id',
            last_name: data.last_name,
            first_name: data.first_name,
            surname: data.surname
        })
    }
    createNewRole(request_id, org_id, data){
        const out = Object.assign({
            action: 'add_new_role',
            request_id: request_id,
            org_id: org_id
        }, data)
        this.send(out)
    }
    createOrganisation(inn, is_director, request_id) {
        this.send({
            action: 'create_organisation',
            type: 'set',
            id: request_id,
            inn: inn,
            is_director: is_director
        })
    }
    onOrganisationCreated(data) {
        if (data.action === 'create_organisation') {
            this.emitter.emit('organisationCreated', data);
            this.getUserInfo();
        }
    }
    getAllOrganisations() {
        this.send({
            action: 'get_all_organisations'
        })
    }
    getUserInfoAcrossOrg(request_id, org_id) {
        this.send({
            action: 'get_user_info_across_org',
            request_id: request_id,
            org_id: org_id
        })
    }
    updateTarifPlan(data) {
        this.send({
            action: 'update_tarif_plan',
            new_plan: data.plan
        })
    }
    inviteUserToOrg(request_id, data) {
        this.send({
            action: 'invite_user_to_organisation',
            request_id: request_id,
            org_id: data.org_id,
            email: data.email,
            role_id: data.role_id
        })
    }
    createNewTarifBillToPay(data) {
        this.send({
            action: "create_new_bill_to_pay",
            summ: data.summ
        })
    }
    acceptInvite(request_id, invite_id) {
        this.send({
            action: 'accept_invite',
            request_id: request_id,
            invite_id: invite_id
        })
    }
    declineInvite(request_id, invite_id) {
        this.send({
            action: 'decline_invite',
            request_id: request_id,
            invite_id: invite_id
        })
    }
    getRoleInfoById(request_id, org_id, role_id) {
        this.send({
            action: 'get_role_info_by_id',
            org_id: org_id,
            request_id: request_id,
            role_id: role_id
        })
    }
    updateRoleInfoById(request_id, role_id, data) {
        const out = Object.assign({
            action: 'update_role_info_by_id',
            request_id: request_id,
            role_id: role_id
        }, data)
        this.send(out)
    }
    deleteRoleFromOrganisation(request_id, role_id, org_id) {
        this.send({
            action: 'delete_role_from_organisation',
            request_id: request_id,
            role_id: role_id,
            org_id: org_id
        })
    }
    cancelInvitation(request_id, role_id, org_id) {
        this.send({
            action: 'cancel_invitation',
            request_id: request_id,
            role_id: role_id,
            org_id: org_id
        })
    }
    addUserToRole(request_id, affected_user, role_id, org_id) {
        this.send({
            action: 'add_user_to_role',
            request_id: request_id,
            affected_user: affected_user,
            role_id: role_id,
            org_id: org_id
        })
    }
    removeUserFromRole(request_id, role_id, org_id) {
        this.send({
            action: 'remove_user_from_role',
            request_id: request_id,
            role_id: role_id,
            org_id: org_id
        })
    }
    removeUserFromOrg(request_id, affected_user, role_id, org_id) {
        this.send({
            action: 'remove_user_from_org',
            request_id: request_id,
            affected_user: affected_user,
            role_id: role_id,
            org_id: org_id
        })
    }
    getCurrentTarifPlan(request_id, org_id) {
        this.send({
            action: 'get_current_tarif_plan',
            org_id: org_id,
            request_id: request_id,
        })
    }
    getFinanceSettings(request_id, org_id) {
        this.send({
            action: 'get_finance_settings',
            org_id: org_id,
            request_id: request_id,
        })
    }
    updateFinanceSettings(request_id, org_id, data) {
        const out = Object.assign({
            action: 'update_finance_settings',
            org_id: org_id,
            request_id: request_id,
        }, data)
        this.send(out)
    }
    createFinanceBillsAccount(request_id, org_id, bill_id, data) {
        const out = Object.assign({
            action: 'create_finance_bills_account',
            org_id: org_id,
            request_id: request_id,
            bill_id: bill_id,
        }, data)
        this.send(out)
    }
    updateFinanceBillsAccount(request_id, org_id, bill_id, data) {
        const out = Object.assign({
            action: 'update_finance_bills_account',
            org_id: org_id,
            request_id: request_id,
            bill_id: bill_id,
        }, data)
        this.send(out)
    }
    deleteFinanceBillsAccount(request_id, org_id, bill_id) {
        this.send({
            action: 'delete_finance_bills_account',
            org_id: org_id,
            request_id: request_id,
            bill_id: bill_id
        })
    }
    saveInitialBillsData(request_id, org_id, data) {
        this.send({
            action: 'save_initial_bills_data',
            org_id: org_id,
            request_id: request_id,
            bills: data
        })
    }
    getBillsList(request_id, org_id, period_start, period_end) {
        this.send({
            action: 'get_bills_list',
            org_id: org_id,
            request_id: request_id,
            period_start: period_start,
            period_end: period_end
        })
    }
    gitBillsListFlatCompact(request_id, org_id) {
        this.send({
            action: 'get_bills_flat_list_compact',
            org_id: org_id,
            request_id: request_id
        })
    }
    getTransfersByBillCardsList(request_id, org_id, bill_id, period_start, period_end) {
        this.send({
            action: 'get_transfers_for_bill_card',
            org_id: org_id,
            bill_id: bill_id,
            request_id: request_id,
            period_start: period_start,
            period_end: period_end
        })
    }
    getDocumentsListFlatCompact(request_id, org_id) {
        this.send({
            action: 'get_documents_flat_list_compact',
            org_id: org_id,
            request_id: request_id
        })
    }
    createNewSubbill(request_id, org_id, data) {
        const out = Object.assign({
            action: 'create_subbill',
            org_id: org_id,
            request_id: request_id,
        }, data)
        this.send(out)
    }
    createNewTransfer(request_id, org_id, data) {
        const out = Object.assign({
            action: 'create_new_transfer',
            org_id: org_id,
            request_id: request_id,
        }, data)
        this.send(out)
    }
    getOrganisationInfo(request_id, org_id) {
        this.send({
            action: 'get_organisation_info',
            org_id: org_id,
            request_id: request_id
        })
    }
    getTransferInfo(request_id, org_id, transfer_id) {
        this.send({
            action: 'get_transfer_info',
            org_id: org_id,
            request_id: request_id,
            transfer_id: transfer_id
        })
    }
    updateTransferInfo(request_id, org_id, transfer_id, data) {
        const out = Object.assign({
            action: 'update_transfer_info',
            org_id: org_id,
            request_id: request_id,
            transfer_id: transfer_id
        }, data)
        this.send(out)
    }
    getAgentInfoByInn(request_id, inn) {
        this.send({
            action: 'get_agent_info_by_inn',
            request_id: request_id,
            inn: inn
        })
    }
    getAgentInfoByBik(request_id, bik) {
        this.send({
            action: 'get_agent_info_by_bik',
            request_id: request_id,
            bik: bik
        })
    }
    getDocumentFieldsList(request_id) {
        this.send({
            action: 'get_document_fields_list',
            request_id: request_id,
        })
    }
    getBillInfo(request_id, org_id, bill_id, period_start, period_end) {
        this.send({
            action: 'get_bill_info',
            request: request_id,
            org_id: org_id,
            bill_id: bill_id,
            period_start: period_start,
            period_end: period_end
        })
    }
    deleteDocument(request_id, org_id, document_id) {
        this.send({
            action: 'delete_document',
            org_id: org_id,
            request_id: request_id,
            document_id: document_id
        })
    }
    restoreDocument(request_id, org_id, document_id) {
        this.send({
            action:'restore_document',
            org_id: org_id,
            request_id: request_id,
            document_id: document_id
        })
    }
    getNewDocumentNumber(request_id, org_id, document_type) {
        this.send({
            action: 'get_new_document_number',
            org_id: org_id,
            request_id: request_id,
            document_type: document_type
        })
    }
    getDocumentsTemplatesList(request_id, org_id) {
        this.send({
            action: 'get_documents_templates_list',
            org_id: org_id,
            request_id: request_id,
        })
    }
    getDocumentTemplate(request_id, org_id, template_id) {
        this.send({
            action: 'get_document_template_structure',
            org_id: org_id,
            request_id: request_id,
            template_id: template_id
        })
    }
    createNewDocumentUpd(request_id, org_id, data) {
        const out = Object.assign({
            action: 'create_new_document_upd',
            org_id: org_id,
            request_id: request_id,
        }, data)
        this.send(out)
    }
    createNewDocument(request_id, org_id, data) {
        this.send({
            action: 'create_new_document',
            org_id: org_id,
            request_id: request_id,
            body: data
        })
    }
    saveDocument(request_id, org_id, data, document_id, document_type) {
        this.send({
            action:'save_document',
            org_id: org_id,
            request_id: request_id,
            body: data,
            document_id: document_id,
            document_type: document_type
        })
    }
    editDocument(request_id, org_id, data) {
        const out = Object.assign({
            action: 'edit_document',
            org_id: org_id,
            request_id: request_id,
        }, data)
        this.send(out)
    }
    getDocumentsList(request_id, org_id, period_start, period_end, direction, document_type, is_active) {
        this.send({
            action: 'get_document_list',
            org_id: org_id,
            request_id: request_id,
            period_start: period_start,
            period_end: period_end,
            direction: direction,
            document_type: document_type,
            is_active: is_active
        })
    }
    getBillSaldoList(request_id, org_id, period_start, period_end, bill_id, filter, filter2, first_level_filter) {
        this.send({
            action: 'get_bill_saldo_list',
            org_id: org_id,
            request_id: request_id,
            period_start: period_start,
            period_end: period_end,
            bill_id: bill_id,
            filter: filter,
            filter2: filter2,
            first_level_filter: first_level_filter,
        })
    }
    getUsersList(request_id, org_id) {
        this.send({
            action: 'get_users_list',
            org_id: org_id,
            request_id: request_id,
        })
    }
    getTransfersTemplatesList(request_id, org_id) {
        this.send({
            action: 'list_trasfers_templates',
            org_id: org_id,
            request_id: request_id,
        })
    }
    getAllUsersList(request_id, org_id) {
        this.send({
            action: 'get_all_users_list',
            org_id: org_id,
            request_id: request_id,
        })
    }
    getDocumentInfo(request_id, org_id, document_id, history_id) {
        this.send({
            action: 'get_document_info',
            org_id: org_id,
            request_id: request_id,
            document_id: document_id,
            history_id: history_id
        })
    }
    getDocumentInfoShort(request_id, org_id, document_id) {
        this.send({
            action: 'get_document_info_short',
            org_id: org_id,
            request_id: request_id,
            document_id: document_id
        })
    }
    getPrintedDocument(request_id, org_id, document_id) {
        this.send({
            action: 'get_printed_document_file',
            org_id: org_id,
            request_id: request_id,
            doc_id: document_id
        })
    }
    getAbstractPositionsList(request_id, org_id) {
        this.send({
            action: 'get_abstract_position_list',
            org_id: org_id,
            request_id: request_id
        })
    }
    getContragentsList(request_id, org_id) {
        this.send({
            action: 'get_contragents_list',
            org_id: org_id,
            request_id: request_id
        })
    }
    getAbstractPositionsShortList(request_id, org_id) {
        this.send({
            action: 'get_abstract_positions_short_list',
            org_id: org_id,
            request_id: request_id
        })
    }
    getPositionsList(request_id, org_id, filter_code) {
        this.send({
            action: 'get_position_list',
            org_id: org_id,
            request_id: request_id,
            filter_code: filter_code
        })
    }
    getTransfersList(request_id, org_id, document_id, limit, filter_active=null) {
        this.send({
            action: 'get_transfers_list',
            org_id: org_id,
            request_id: request_id,
            document_id: document_id,
            limit: limit,
            filter_active: filter_active
        })
    }
    saveTransfersList(request_id, org_id, transfers_list) {
        this.send({
            action: 'save_transfers_list',
            org_id: org_id,
            request_id: request_id,
            transfers_list: transfers_list
        })
    }
    updateTransferActiveState(request_id, org_id, transfer_id, is_active) {
        this.send({
            action: 'update_transfer_active_state',
            org_id: org_id,
            request_id: request_id,
            transfer_id: transfer_id,
            is_active: is_active
        });
    }
    updateTransferBillState(request_id, org_id, transfer_id, bill_id, direction) {
        this.send({
            action: 'update_transfer_bill_state',
            org_id: org_id,
            request_id: request_id,
            transfer_id: transfer_id,
            bill_id: bill_id,
            direction: direction
        });
    }
    getBillAnalyticFields(request_id, org_id, bill_id) {
        this.send({
            action: 'get_bill_analityc_fields',
            org_id: org_id,
            request_id: request_id,
            bill_id: bill_id
        })
    }
    createTransferTemplate(request_id, org_id, name, field_id, bill_from, bill_to, direction, comment, document_type) {
        this.send({
            action: 'create_transfer_template',
            org_id: org_id,
            request_id: request_id,
            name: name,
            field_id: field_id,
            bill_from: bill_from,
            bill_to: bill_to,
            direction: direction,
            comment: comment,
            document_type: document_type
        })
    }

    deleteUpdTransferTemplate(request_id, org_id, transfer_id) {
        this.send({
            action: 'delete_upd_transfer_template',
            org_id: org_id,
            request_id: request_id,
            transfer_id: transfer_id
        })
    }
    getUpdTransferTemplate(request_id, org_id, transfer_id) {
        this.send({
            action: 'get_upd_transfer_template',
            org_id: org_id,
            request_id: request_id,
            transfer_id: transfer_id
        })
    }
    createUpdTrasferWildcard(request_id, org_id, document_id, summ, name, comment, bill_from, bill_to) {
        this.send({
            action: 'create_upd_transfer_wildcard',
            org_id: org_id,
            request_id: request_id,
            document_id: document_id,
            summ: summ,
            name: name,
            comment: comment,
            bill_from: bill_from,
            bill_to: bill_to
        })
    }
    updateUpdTransferTemplate(request_id, org_id, transfer_id, name, field_id, bill_from, bill_to, direction,comment) {
        this.send({
            action: 'delete_upd_transfer_template',
            org_id: org_id,
            request_id: request_id,
            transfer_id: transfer_id,
            name: name,
            field_id: field_id,
            bill_from: bill_from,
            bill_to: bill_to,
            direction: direction,
            comment: comment
        })
    }
    send(data) {
        console.log(data)
        this.socket.send(JSON.stringify(data));
    }
    onOpen() {
        this.send({
            action: 'auth',
            token: this.token,
            token_uuid: this.token_uuid
        });
        this.emitter.emit('connectionOpen');
    }
    onMessage(event) {
        console.log(event.data)
        const json = JSON.parse(event.data)
        console.log('ws_action', json.action)
        if (this.isAuthenticated) {
            this.didReceiveTransfersList(json)
            this.didReceiveOrganisationInfo(json)
            this.didReceiveUserInfo(json)
            this.onOrganisationCreated(json)
            this.didReceiveOrgStructure(json)
            this.didReceiveOrgStructureFlat(json)
            this.didCreateNewRole(json)
            this.didEditUserInfo(json)
            this.didGetAllOrganisations(json)
            this.didConfirmUser(json)
            this.didAcceptInvite(json)
            this.didReceoveUserInfoAcrossOrg(json)
            this.didInviteUserToOrg(json)
            this.didReceiveRoleInfoById(json)
            this.didUpdateRoleInfoById(json)
            this.didReceiveFinanceSettings(json)
            this.didUpdateFinanceSettings(json)
            this.didCreateFinanceBillsAccount(json)
            this.didUpdateFinanceBillsAccount(json)
            this.didDeleteFinanceBillsAccount(json)
            this.didSaveInitialBillsData(json)
            this.didReceiveBillsList(json)
            this.didDeleteRoleFromOrganisation(json)
            this.didReceiveBillListFlatCompact(json)
            this.didCreateNewSubbill(json)
            this.didReceiveDocumentListFlatCompact(json)
            this.didCreateNewTransfer(json)
            this.didReceiveTransfersListByBillCard(json)
            this.didReceiveTransferInfo(json)
            this.didUpdateTransferInfo(json)
            this.didReceiveAgentInfoByInn(json)
            this.didReceiveAgentInfoByBik(json)
            this.didReceiveBillInfo(json)
            this.didReceiveNewDocumentNumber(json)
            this.didReceiveDocumentsTemplates(json)
            this.didReceiveDocumentTemplate(json)
            this.didCreateNewDocument(json)
            this.didEditDocument(json)
            this.didReceiveDocumentsList(json)
            this.didReceiveUsersList(json)
            this.didReceiveDocumentInfo(json)
            this.didReceiveAbstractPositionsList(json)
            this.didReceivePositionsList(json)
            this.didReceiveAbstractPositionsShortList(json)
            this.didReceiveDocumentFieldsList(json)
            this.didReceiveBillSaldoList(json)
            this.didSaveTransfersList(json)
            this.didReceiveBillAnalyticFields(json)
            this.didReceiveContragentsList(json)
            this.onResendConfirmationCode(json)
            this.onGetAllUsersList(json)
            this.onCancelInvitation(json)
            this.onAddUserToRole(json)
            this.onRemoveUserFromRole(json)
            this.onRemoveUserFromOrg(json)
            this.didReceiveDocumentForPrint(json)
            this.didCreateNewDocumentUpd(json)
            this.onSaveDocument(json)
            this.onReceiveTrasnferTemplateList(json)
            this.onCreateTransferTemplate(json)
            this.onUpdateTransferActiveState(json)
            this.onUpdateTransferBillState(json)
            this.onDeleteUpdTransferTemplate(json)
            this.onGetUpdTransferTemplate(json)
            this.onUpdateUpdTransferTemplate(json)
            this.onCreateUpdTransferWildcard(json)
            this.didReceiveDocumentInfoShort(json)
            this.didReceiveLeaveOrganisation(json)
            this.onDeleteDocument(json)
            this.onRestoreDocument(json)
        } else {
            this.didReceiveAuthMessage(json)
        }
    }
    didReceiveAuthMessage(data) {
        console.log("rec_packet", data);
        if (data.action === 'auth') {
            console.log("auth_packet", data);
            if (data.success === 1) {
                console.log("suc_packet", JSON.parse(data.user_info));
                this.isAuthenticated = true;
                this.emitter.emit('didReceiveUserInfo', JSON.parse(data.user_info));
                this.emitter.emit('connectionReady');
            } else {
                this.isAuthenticated = false;
                this.emitter.emit('didReceiveAuthFail', data);
            }
        }
    }
    didReceiveOrganisationInfo(data) {
        if (data.action === 'get_organisation_info') {
            this.emitter.emit('organisationInfoWasReceived', data)
        }
    }
    didReceiveDocumentForPrint(data) {
        if (data.action === 'get_printed_document_file') {
            this.emitter.emit('didReceiveDocumentForPrint', data)
        }
    }
    didReceiveUserInfo(data) {
        if (data.action === 'user_info') {
            // try {
            //     const org_id = data.organisations[0].id;
            //
            //     // this.getUserInfoAcrossOrg('1', org_id);
            // // eslint-disable-next-line no-empty
            // } catch {
            //
            // }
            this.emitter.emit('didReceiveUserInfo', data);
        }
    }
    didReceiveOrgStructure(data) {
        if (data.action === 'get_org_structure') {
            this.emitter.emit('didReceiveOrgStructure', data);
        }
    }
    didReceiveOrgStructureFlat(data) {
        if (data.action === 'get_org_structure_flat') {
            this.emitter.emit('didReceiveOrgStructureFlat', data);
        }
    }
    didCreateNewRole(data) {
        if (data.action === 'add_new_role') {
            this.emitter.emit('didCreateNewRole', data);
        }
    }
    didEditUserInfo(data) {
        if (data.action === 'edit_user_info') {
            this.emitter.emit('didEditUserInfo', data);
        }
    }
    didGetAllOrganisations(data) {
        if (data.action === 'get_all_organisations') {
            this.emitter.emit('didGetAllOrganisations', data);
        }
    }
    didGetResultOfUpdateTarifPlan(data) {
        if (data.action === 'update_tarif_plan') {
            this.emitter.emit('didGetResultOfUpdateTarifPlan', data);
        }
    }
    didInviteUserToOrg(data) {
        if (data.action === 'invite_user_to_organisation') {
            this.emitter.emit('didInviteUserToOrg', data);
        }
    }
    didConfirmUser(data) {
        if (data.action === 'confirm_user') {
            this.emitter.emit('didConfirmUser', data)
        }
    }
    didAcceptInvite(data) {
        if (data.action === 'accept_invite') {
            this.emitter.emit('didAcceptInvite', data)
        }
    }
    didReceoveUserInfoAcrossOrg(data) {
        if (data.action === 'get_user_info_across_org') {
            this.emitter.emit('didReceoveUserInfoAcrossOrg', data)
        }
    }
    didReceiveRoleInfoById(data) {
        if (data.action === 'get_role_info_by_id') {
            this.emitter.emit('didReceiveRoleInfoById', data)
        }
    }
    onGetAllUsersList(data) {
        if (data.action === 'get_all_users_list') {
            this.emitter.emit('onGetAllUsersList', data)
        }
    }
    didUpdateRoleInfoById(data) {
        if (data.action === 'update_role_info_by_id') {
            this.emitter.emit('didUpdateRoleInfoById', data)
        }
    }
    didReceiveFinanceSettings(data) {
        if (data.action === 'get_finance_settings') {
            this.emitter.emit('didReceiveFinanceSettings', data)
        }
    }
    didUpdateFinanceSettings(data) {
        if (data.action === 'update_finance_settings') {
            this.emitter.emit('didUpdateFinanceSettings', data)
        }
    }
    didCreateFinanceBillsAccount(data) {
        if (data.action === 'create_finance_bills_account') {
            this.emitter.emit('didCreateFinanceBillsAccount', data)
        }
    }
    didUpdateFinanceBillsAccount(data) {
        if (data.action === 'update_finance_bills_account') {
            this.emitter.emit('didUpdateFinanceBillsAccount', data)
        }
    }
    didDeleteFinanceBillsAccount(data) {
        if (data.action === 'delete_finance_bills_account') {
            this.emitter.emit('didDeleteFinanceBillsAccount', data)
        }
    }
    didSaveInitialBillsData(data) {
        if (data.action === 'save_initial_bills_data') {
            this.emitter.emit('didSaveInitialBillsData', data)
        }
    }
    didReceiveBillsList(data) {
        if (data.action === 'get_bills_list') {
            this.emitter.emit('didReceiveBillsList', data)
        }
    }
    didDeleteRoleFromOrganisation(data) {
        if (data.action === 'delete_role_from_organisation') {
            this.emitter.emit('didDeleteRoleFromOrganisation', data)
        }
    }
    didReceiveBillListFlatCompact(data) {
        if (data.action === 'get_bills_flat_list_compact') {
            this.emitter.emit('didReceiveBillListFlatCompact', data)
        }
    }
    didReceiveDocumentListFlatCompact(data) {
        if (data.action === 'get_documents_flat_list_compact') {
            this.emitter.emit('didReceiveDocumentListFlatCompact', data)
        }
    }
    didCreateNewSubbill(data) {
        if (data.action === 'create_subbill') {
            this.emitter.emit('didCreateNewSubbill', data)
        }
    }
    didCreateNewTransfer(data) {
        if (data.action === 'create_new_transfer') {
            this.emitter.emit('didCreateNewTransfer', data)
        }
    }
    didReceiveTransfersListByBillCard(data) {
        if (data.action === 'get_transfers_for_bill_card') {
            this.emitter.emit('didReceiveTransfersListByBillCard', data)
        }
    }
    didReceiveTransferInfo(data) {
        if (data.action === 'get_transfer_info') {
            this.emitter.emit('didReceiveTransferInfo', data)
        }
    }
    didUpdateTransferInfo(data) {
        if (data.action === 'update_transfer_info') {
            this.emitter.emit('didUpdateTransferInfo', data)
        }
    }
    didReceiveAgentInfoByInn(data) {
        if (data.action === 'get_agent_info_by_inn') {
            this.emitter.emit('didReceiveAgentInfoByInn', data)
        }
    }
    didReceiveAgentInfoByBik(data) {
        if (data.action === 'get_agent_info_by_bik') {
            this.emitter.emit('didReceiveAgentInfoByBik', data)
        }
    }
    didReceiveBillInfo(data) {
        if (data.action === 'get_bill_info') {
            this.emitter.emit('didReceiveBillInfo', data)
        }
    }
    didReceiveNewDocumentNumber(data) {
        if (data.action === 'get_new_document_number') {
            this.emitter.emit('didReceiveNewDocumentNumber', data)
        }
    }
    didReceiveDocumentsTemplates(data) {
        if (data.action === 'get_documents_templates_list') {
            this.emitter.emit('didReceiveDocumentsTemplates', data)
        }
    }
    didReceiveDocumentTemplate(data) {
        if (data.action === 'get_document_template_structure') {
            this.emitter.emit('didReceiveDocumentTemplate', data)
        }
    }
    didCreateNewDocument(data) {
        if (data.action === 'create_new_document') {
            this.emitter.emit('didCreateNewDocument', data)
        }
    }
    didCreateNewDocumentUpd(data) {
        if (data.action === 'create_new_document_upd') {
            this.emitter.emit('didCreateNewDocumentUpd', data)
        }
    }
    didEditDocument(data) {
        if (data.action === 'edit_document') {
            this.emitter.emit('didEditDocument', data)
        }
    }
    didReceiveDocumentsList(data) {
        if (data.action === 'get_document_list') {
            this.emitter.emit('didReceiveDocumentsList', data)
        }
    }
    didReceiveUsersList(data) {
        if (data.action === 'get_users_list') {
            this.emitter.emit('didReceiveUsersList', data)
        }
    }
    didReceiveDocumentInfo(data) {
        if (data.action === 'get_document_info') {
            this.emitter.emit('didReceiveDocumentInfo', data)
        }
    }
    didReceiveAbstractPositionsList(data) {
        if (data.action === 'get_abstract_position_list') {
            this.emitter.emit('didReceiveAbstractPositionsList', data)
        }
    }
    didReceiveAbstractPositionsShortList(data) {
        if (data.action === 'get_abstract_positions_short_list') {
            this.emitter.emit('didReceiveAbstractPositionsShortList', data)
        }
    }
    didReceivePositionsList(data) {
        if (data.action === 'get_position_list') {
            this.emitter.emit('didReceivePositionsList', data)
        }
    }
    didReceiveDocumentFieldsList(data) {
        if (data.action === 'get_document_fields_list') {
            this.emitter.emit('didReceiveDocumentFieldsList', data)
        }
    }
    didReceiveBillSaldoList(data) {
        if (data.action === 'get_bill_saldo_list') {
            this.emitter.emit('didReceiveBillSaldoList', data)
        }
    }
    didReceiveTransfersList(data) {
        if (data.action === 'get_transfers_list') {
            this.emitter.emit('didReceiveTransfersList', data)
        }
    }
    didSaveTransfersList(data) {
        if (data.action === 'save_transfers_list') {
            this.emitter.emit('didSaveTransfersList', data)
        }
    }
    didReceiveBillAnalyticFields(data) {
        if (data.action === 'get_bill_analityc_fields') {
            this.emitter.emit('didReceiveBillAnalyticFields', data)
        }
    }
    didReceiveContragentsList(data) {
        if (data.action === 'get_contragents_list') {
            this.emitter.emit('didReceiveContragentsList', data)
        }
    }
    onResendConfirmationCode(data) {
        if (data.action === 'resend_confirmation_code') {
            this.emitter.emit('onResendConfirmationCode', data)
        }
    }
    onCancelInvitation(data) {
        if (data.action === 'cancel_invitation') {
            this.emitter.emit('onCancelInvitation', data)
        }
    }
    onAddUserToRole(data) {
        if (data.action === 'add_user_to_role') {
            this.emitter.emit('onAddUserToRole', data)
        }
    }
    onRemoveUserFromRole(data) {
        if (data.action === 'remove_user_from_role') {
            this.emitter.emit('onRemoveUserFromRole', data)
        }
    }
    onRemoveUserFromOrg(data) {
        if (data.action === 'remove_user_from_org') {
            this.emitter.emit('onRemoveUserFromOrg', data)
        }
    }
    onSaveDocument(data) {
        if (data.action ==='save_document') {
            this.emitter.emit('onSaveDocument', data)
        }
    }
    onReceiveTrasnferTemplateList(data) {
        if (data.action === 'list_trasfers_templates') {
            this.emitter.emit('onReceiveTrasnferTemplateList', data)
        }
    }
    onCreateTransferTemplate(data) {
        if (data.action === 'create_transfer_template') {
            this.emitter.emit('onCreateTransferTemplate', data)
        }
    }
    onUpdateTransferActiveState(data) {
        if (data.action === 'update_transfer_active_state') {
            this.emitter.emit('onUpdateTransferActiveState', data)
        }
    }
    onUpdateTransferBillState(data) {
        if (data.action === 'update_transfer_bill_state') {
            this.emitter.emit('onUpdateTransferBillState', data)
        }
    }
    onDeleteUpdTransferTemplate(data) {
        if (data.action === 'delete_upd_transfer_template') {
            this.emitter.emit('onDeleteUpdTransferTemplate', data)
        }
    }
    onGetUpdTransferTemplate(data) {
        if (data.action === 'get_upd_transfer_template') {
            this.emitter.emit('onGetUpdTransferTemplate', data)
        }
    }
    onUpdateUpdTransferTemplate(data) {
        if (data.action === 'update_upd_transfer_template') {
            this.emitter.emit('onUpdateUpdTransferTemplate', data)
        }
    }
    onCreateUpdTransferWildcard(data) {
        if (data.action === 'create_upd_transfer_wildcard') {
            this.emitter.emit('onCreateUpdTransferWildcard', data)
        }
    }
    didReceiveDocumentInfoShort(data) {
        if (data.action === 'get_document_info_short') {
            this.emitter.emit('didReceiveDocumentInfoShort', data)
        }
    }
    didReceiveLeaveOrganisation(data) {
        if (data.action === 'leave_organisation') {
            this.emitter.emit('didReceiveLeaveOrganisation', data)
        }
    }
    onDeleteDocument(data) {
        if (data.action === 'delete_document') {
            this.emitter.emit('onDeleteDocument', data)
        }
    }
    onRestoreDocument(data) {
        if (data.action ==='restore_document') {
            this.emitter.emit('onRestoreDocument', data)
        }
    }
}

export default Connection