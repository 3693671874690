<template>
  <q-layout view="hHh LpR fFf">
    <login v-if="!isHasCredentials" />
    <dashboard v-else />
  </q-layout>
</template>

<script>
import Login from "./views/core/login.vue";
import dashboard from "./views/home/dashboard";
import { ref } from 'vue'

// var isAuthenticated = false;

export default {
  components: {
    dashboard,
    Login
  },
  beforeCreate() {
    console.log("before create");
    this.$store.commit("initStore");
  },
  data() {
    this.emitter.on('didReceiveAuthFail', (data) => {
      console.log('didReceiveAuthFail', data);
      if (data.error == 'invalid_token') {
        localStorage.clear();
        this.$store.commit('clearStore');
        document.location.reload();
      }
    });
    this.emitter.on('updateAuth', (data) => {
      this.connection.updateCredentials(data.token, data.token_uuid);
      this.emitter.emit('shouldAuth');
    });
    this.emitter.on('shouldAuth', () => {
      this.isHasCredentials = true;
      this.connection.makeConnection();
    });
    if (this.$store.state.isHasCredentials) {
      this.emitter.emit('updateAuth', {
        token: this.$store.state.token,
        token_uuid: this.$store.state.token_uuid
      });
    }
    return {
      isHasCredentials: ref(this.$store.state.isHasCredentials)
    }
  },
  methods: {
    establishWSConnection(user_data) {
      this.connection.updateUser(user_data);
      this.connection.makeConnection();
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.dashboard-main-block {
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
