<template>
  <q-card style="min-width: 350px">
    <q-card-section>
      <div class="text-h6">Новый субсчет</div>

      <q-select v-model="parent_bill" :options="initial" label="Родительский счет" />

      <q-input
          label="Номер счета"
          v-model="bill_name"
          autofocus
          :rules="[ val => val.match(/^[,.0-9]+$/i) || 'Неправильное название счета']"
      />

      <q-input label="Описание счета" v-model="bill_description" />
    </q-card-section>

    <q-card-section>
      <div class="text-h6 q-ma-sm">Начальные значения</div>
      <q-input label="Остаток" type="number" dense v-model="bill_start_saldo" />
      <q-select v-model="bill_start_type" :options="bill_start_type_choices" label="Тип остатка" />
      <div style="max-width: 300px">
        <q-input dense label="Остаток на дату" v-model="transfer_date_proxy" mask="date" :rules="['transfer_date_proxy']">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy @before-show="updateDateProxy" ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
                <q-date v-model="transfer_date_proxy">
                  <div class="row items-center justify-end">
                    <div class="row items-center justify-end q-gutter-sm">
                      <q-btn label="Отмена" color="primary" flat v-close-popup />
                      <q-btn label="Применить" color="primary" flat @click="saveDateProxy" v-close-popup />
                    </div>
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
    </q-card-section>
    <q-card-section>
      <q-input label="Аналитика (теги через запятую)" v-model="analytic" />
    </q-card-section>
    <q-card-actions align="right" class="text-primary">
      <q-btn flat label="Отмена" v-close-popup />
      <q-btn flat label="Добавить счет" v-close-popup @click="addNewSubbill()" />
    </q-card-actions>
  </q-card>
</template>

<script>

import {ref} from "vue";
import moment from "moment";


const bill_start_type_choices = [
  {
    value: 'debet',
    label: 'Дебет'
  },
  {
    value: 'credit',
    label: 'Кредит'
  }
]

export default {
  name: "create_new_bill",
  setup() {
  },
  props: {
    period_start: { required: true, type: Number},
    period_end: { required: true, type: Number},
  },
  data() {
    if (this.connection.isAuthenticated) {
      this.connection.gitBillsListFlatCompact('1', this.$store.state.current_org_id);
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.gitBillsListFlatCompact('1', this.$store.state.current_org_id);
      });
    }
    this.emitter.on('didReceiveBillListFlatCompact', (data) => {
      this.initial = data.bills.map( (element => {
        return {
          label: element.display_name,
          value: element.id
        }
      }));
      this.bills_list = data.bills;
    })
    this.emitter.on('didCreateNewSubbill', (data) => {
      console.log(data);
      this.$q.loading.hide();
    });
    return  {
      parent_bill: ref(null),
      bill_description: ref(null),
      bill_name: ref(null),
      initial: ref([]),
      analytic: ref(''),
      bills_list: ref([]),
      bill_start_saldo: ref(0),
      bill_start_type: ref(bill_start_type_choices[0]),
      bill_start_type_choices: bill_start_type_choices,
      transfer_date_proxy: ref(ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"))),
      transfer_date: ref(ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"))),
      transfer_date_unix: ref(moment(ref(ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD")))).startOf('day').unix())
    }
  },
  methods: {
    updateDateProxy () {
      this.transfer_date_proxy = this.transfer_date
    },
    saveDateProxy() {
      this.transfer_date = this.transfer_date_proxy;
      this.transfer_date_unix = moment(this.transfer_date_proxy).startOf('day').unix();
    },
    addNewSubbill() {
        this.$q.loading.show();
        var bill_start_value = Math.abs(parseFloat(this.bill_start_saldo))
        if (this.bill_start_type.value == 'credit') {
          bill_start_value = -bill_start_value;
        }
        const data = {
          parent_bill_id: this.parent_bill.value,
          parent_bill_name: this.parent_bill.label,
          bill_name: this.bill_name,
          description: this.bill_description,
          bill_start: bill_start_value,
          period_start: this.period_start,
          period_end: this.period_end,
          transfer_date: this.transfer_date_unix,
          analytic: this.analytic
        }
        console.log('asdasdasdasd1111', data)
        this.connection.createNewSubbill('1', this.$store.state.current_org_id, data)
    }
  }
}
</script>

<style scoped>

</style>