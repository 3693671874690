<template>
  <div class="q-pa-md">
    <div class="q-ma-md text-h5">
      Настройки рабочего пространства
    </div>
    <div class="q-pa-md row">
      <q-inner-loading :showing="isRequestSended">
        <q-spinner-gears size="100px" color="primary" />
      </q-inner-loading>
      <div class="col-7" v-show="!isRequestSended">
        <div class="q-pa-md">
          <q-toggle v-model="department_widget" label="Показывать виджет структуры организации"/>
        </div>
        <div class="q-pa-md">
          <q-toggle v-model="workers_widget" label="Показывать виджет списка пользователей"/>
        </div>
        <div class="q-pa-md">
          <q-toggle v-model="transfers_widget" label="Показывать виджет неподтвержденных проводок"/>
        </div>
        <div class="q-pa-md">
          <q-toggle v-model="dark_mode" label="Темная тема"/>
        </div>
        <div class="q-pa-md">
          <q-btn label="Сохранить" @click="updateSettings" color="primary"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { useQuasar } from 'quasar'
import { Dark } from 'quasar'
import {ref} from "vue";

export default {
  name: "profile_settings",
  data() {
    return {
      isRequestSend: ref(false),
      department_widget: ref(true),
      transfers_widget: ref(true),
      workers_widget: ref(true),
      dark_mode: ref(false),
    }
  },
  methods: {
    updateSettings() {
      this.isRequestSend = true

// get status
      console.log(Dark.isActive)

// get configured status
      console.log(Dark.mode) // "auto", true, false

// set status
      Dark.set(!this.dark_mode) // or false or "auto"
// toggle
      Dark.toggle()
      // const $q = useQuasar()
      //
      // // get status
      // console.log($q.dark.isActive) // true, false
      //
      // // get configured status
      // console.log($q.dark.mode) // "auto", true, false
      //
      // // set status
      // $q.dark.set(this.dark_mode) // or false or "auto"
      //
      // // toggle
      // $q.dark.toggle()
      setTimeout(() => {
        this.isRequestSend = false
      }, 1000)
    }
  },
  setup () {

  }
}
</script>

<style scoped>

</style>