<template>
  <div class="q-pa-md">
    <div class="q-ma-md text-h5">
      Мой профиль
    </div>
    <div class="row">

      <div class="col q-pa-md">
        <div class="text-h6">
          Информация
        </div>
        <q-inner-loading :showing="isRequestSended">
          <q-spinner-gears size="100px" color="primary" />
        </q-inner-loading>
        <div class="col-7" v-show="!isRequestSended">
          <transition appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
            <div class="row">
              <div class="col-4">

                <div class="q-pa-md text-center">
                  <!--                  <q-file v-model="selected_avatar" dense borderless>-->
                  <q-avatar size="160px" color="primary" text-color="white">
                    {{ email.slice(0, 2) }}
                  </q-avatar>
                  <!--                  </q-file>-->
                  <!--                  {{ selected_avatar }}-->
                </div>
              </div>
              <div class="col-8">
                <div class="q-pa-md">
                  <q-input v-model="last_name" label="Фамилия" />
                </div>
                <div class="q-pa-md">
                  <q-input v-model="first_name" label="Имя" />
                </div>
                <div class="q-pa-md">
                  <q-input v-model="surname" label="Отчество" />
                </div>
                <div class="q-pa-md">
                  <q-btn label="Сохранить" @click="changeUser" color="primary" />
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="col q-pa-md">
        <div v-if="!is_confirmed">
          <div class="text-h6">
            Подтверждение регистрации
          </div>
          <div class="text-grey text-justify">
            Ваш профиль не подтвержден. Для того, что бы принимать приглашения или создать организацию
            необходимо подтвердить адрес электронной почты. Код подтверждения был отправлен на указанный
            при регистрации адрес электронной почты.
          </div>
          <div>
            <div class="q-ma-md">
              <q-input mask="# # # # # #" :loading="code_request_sended" v-model="confirmation_code"
                label="Код подтверждения" style="width: 180px;" />
            </div>
            <div class="q-pa-sm">
              <q-btn text-color="white" color="primary" @click="sendConfirmationCode" label="Подвтердить" />
              <q-btn text-color="primary" flat color="white" @click="resendConfirmationCode"
                label="Повторно отправить код" />
            </div>
            <div class="q-ma-sm">
              <q-separator />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "profile_edit",
  data() {
    this.emitter.on('didAcceptInvite', (data) => {
      console.log(data);
      this.$store.commit('updateCurrentOrgId', data.org_id);
      this.connection.getOrganisationInfo('1', data.org_id);
      this.$q.loading.hide();
      this.$router.push({name: 'home', params: {} });
    });
    this.emitter.on('*', (type, e) => console.log(type, e) )
    this.emitter.on("didReceiveUserInfo", (data) => {
      console.log("profile_edit", data);
      this.user_info = data;
      this.last_name = data.last_name;
      this.first_name = data.first_name;
      this.surname = data.surname;
      this.is_confirmed = data.is_confirmed;
      this.user_info_loaded = true;
      this.email = data.email;
    });
    this.emitter.on('onResendConfirmationCode', (data) => {
      console.log("resend", data);
      setTimeout(() => {
        this.code_request_sended = false;
      }, 1000);
    });
    this.emitter.on('didEditUserInfo', (data) => {
      console.log(data);
      setTimeout(() => {
        this.isRequestSended = false
      }, 1000);
      // this.isRequestSended = false;
    });
    this.emitter.on('didConfirmUser', (data) => {
      setTimeout(() => {
        this.code_request_sended = false;
      }, 1000)
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Неверный код подтверждения',
          cancel: false,
          persistent: true
        }).onOk(() => {
          this.confirmation_code = null;
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        console.log(data);
        this.confirmation_code = null;
        // this.$q.loading.hide();
      }
    });

    if (this.connection.isAuthenticated) {
      this.connection.getUserInfo()
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getUserInfo();
      })
    }
    return {
      isRequestSended: ref(false),
      last_name: ref(''),
      first_name: ref(''),
      surname: ref(''),
      user_info: ref(null),
      confirmation_code: ref(null),
      user_info_loaded: ref(true),
      is_confirmed: ref(false),
      code_request_sended: ref(false),
      selected_avatar: ref(null),
      email: ref('')
    }
  },
  mounted() {
    
  },
  methods: {
    changeUser() {
      this.isRequestSended = true
      const data = {
        last_name: this.last_name,
        first_name: this.first_name,
        surname: this.surname
      };
      this.connection.editUserInfo(data);
    },
    sendConfirmationCode() {
      // this.$q.loading.show();
      this.connection.confirmUser('1', parseInt(this.confirmation_code.replace(/\s/g,'')));
      this.code_request_sended = true;
    },
    resendConfirmationCode() {
      this.connection.resendConfirmationCode('1');
      this.code_request_sended = true;
    },
    acceptInvite(invite_id) {
      this.$q.loading.show();
      this.connection.acceptInvite('1', invite_id);
    },
    declineInvite(invite_id) {
      this.connection.declineInvite('1', invite_id);
    }
  }
}
</script>

<style scoped>

</style>