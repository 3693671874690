<template>
	<q-card style="min-width: 350px">
		<q-card-section>
			<q-input v-model="sber_api_key" label="СберБизнес API ключ"
				hint="Используется для передачи запросов по каналу API на получение или отправку документов партнера напрямую из его учетной системы без необходимости заходить в клиент-банк, что ускоряет и упрощает процесс ведения бизнеса и минимизирует ошибки при работе с документами." />

		</q-card-section>
		<q-card-actions align="right" class="text-primary">
			<q-btn flat label="Отмена" v-close-popup />
			<q-btn flat label="Сохранить" v-close-popup @click="saveApiKey()" />
		</q-card-actions>
	</q-card>
</template>

<script>
import { ref } from "vue";

export default {
	name: "create_new_bill",
	props: {},
	data() {
		return {
			sber_api_key: ref(null),
		}
	},
	methods: {
		saveApiKey() {
			console.log("Save API Key: ", this.sber_api_key.value);
			// You can save this value to your application's state or use it in your Vue.js components
		}
	}
}
</script>

<style scoped></style>