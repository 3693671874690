<template>
  <q-breadcrumbs class="q-ma-md" separator=">">
    <q-btn icon="arrow_back" class="inline" flat dense @click="$router.back()"/>
    <q-breadcrumbs-el label="Бухгалтерия"  @click="$router.push({ name: 'finance_summary', params: {} })" />
    <q-breadcrumbs-el :label="label" />
  </q-breadcrumbs>
</template>

<script>
export default {
  name: "finance_breadcrumb",
  props: {
    label: { required: true, type: String }
  }
}
</script>

<style scoped>

</style>