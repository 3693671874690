<template>
  <div>
    <router-view />
  </div>
</template>

<script>

// import FinanceWidget from '@/components/widgets/finance_widget.vue'
import { ref } from 'vue'

const nalog_systems = [
  {
    id: 1,
    value: 'osn',
    name: 'ОСН',
    description: 'Общий налоговый режим является основным и применяется по умолчанию, если индивидуальный предприниматель не подал в налоговый орган заявление о переходе на один из специальных налоговых режимов.'
  },
  {
    id: 2,
    value: 'usn_dohod',
    name: 'УСН доходы',
    description: 'УСН доходы – это объекты, учитываемые в упрощенной системе налогообложения – особом порядке уплаты налогов, ориентированном на представителей малого и среднего бизнеса. В качестве объекта выбираются доходы. Налоговая ставка состовляет 6%.'
  },
  {
    id: 3,
    value: 'usn_dohod_rashod',
    name: 'УСН доходы-расходы',
    description: 'УСН доходы – это объекты, учитываемые в упрощенной системе налогообложения – особом порядке уплаты налогов, ориентированном на представителей малого и среднего бизнеса. В качестве объекта выбираются доходы, уменьшенные на величину произведенных расходов. Налоговая ставка состовляет 15%.'
  }
]

export default {
  name: "finance_summary",
  components: {
    // FinanceWidget
  },
  mounted() {
    this.emitter.on('didReceiveFinanceSettings', (data) => {
      console.log(data);
     // if (data.settings.is_complete_first_setup) {
        this.is_module_tuned = true;
        // this.$router.push({ name: 'bills_plan', params: {} });
     // } else {
     //   this.$router.push({ name: 'bills_startup_master', params: {} });
     // }
    });
  },
  data() {
    if (this.connection.isAuthenticated) {
      this.connection.getFinanceSettings('1', this.$store.state.current_org_id);
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getFinanceSettings('1', this.$store.state.current_org_id);
      });
    }

    return {
      nalog_systems,
      tab: ref('settings'),
      splitterModel: ref(20),
      is_module_tuned: ref(false)
    }
  },
  setup() {
    return {
      nalog: ref('osn')
    }
  }
}
</script>

<style scoped lang="sass">
.my-sticky-header-table
  /* height or max-height is important */
  height: 700px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: #c1f4cd

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
</style>
