import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import mitt from 'mitt'
import store from './store'
import Connection from './connection'
import VueBlocksTree from 'vue3-blocks-tree';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';

const blocksTreeDefaultOptions = {treeName: 'blocks-tree'}
const emitter = mitt();

const app = createApp(App)
    .use(Quasar, quasarUserOptions)
    .use(router)
    .use(createStore(store))
    .use(VueBlocksTree, blocksTreeDefaultOptions)

app.config.globalProperties.connection = new Connection(emitter)
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.isAuthenticated = false;
app.mount('#app');
