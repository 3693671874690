<template>
  <div class="row">
    <div class="col-6">
      <div class="col q-pa-md row items-start q-gutter-md">
        <q-card flat class="my-card">
          <q-card-section>
            <q-btn color="primary" label="Назад" padding="xs lg" icon="ion-arrow-back" @click="$router.go(-1)"/>
          </q-card-section>
          <q-card-section>
            <div class="text-h6 text-left">{{ task_info.title }}</div>
            <div class="text-subtitle2 text-left">{{ task_info.author }}</div>
          </q-card-section>
          <q-separator inset />
          <q-card-section>
            <q-table
                title=""
                flat
                :rows="rows"
                :columns="columns"
                row-key="name"
            >
              <template v-slot:bottom>
              </template>
            </q-table>
          </q-card-section>
          <q-card-section class="q-pt-none text-left">
            {{ task_info.description }}
          </q-card-section>
          <q-separator inset />
          <q-card-section>
            <div class="col">
              <div class="q-px-lg q-pb-md">
                <q-timeline color="secondary">
                  <q-timeline-entry class="text-h5">Движение задачи</q-timeline-entry>

                  <q-timeline-entry
                      v-for="item in events"
                      v-bind:key="item.id"
                      :title="item.title"
                      :subtitle="item.date"
                      :icon="item.icon"
                  >
                    <div>
                      {{ item.description }}
                    </div>
                  </q-timeline-entry>

                </q-timeline>
              </div>
            </div>
          </q-card-section>
          <q-card-section>
            <div class="text-h6 text-left">Обновить задачу</div>
            <q-separator inset></q-separator>
            <q-form
                @submit="onSubmit"
                @reset="onReset"
                class="q-gutter-md"
            >
              <div class="row">
                <div class="col q-pa-md">
                  <q-select v-model="status_model" :options="status_options" label="Статус" />
                </div>
                <div class="col q-pa-md">
                  <q-select v-model="employer_model" :options="employer_options" label="Назначена" />
                </div>
              </div>
              <div class="">
                <q-editor
                    v-model="editor"
                    :definitions="{
                  upload: {
                    tip: 'Прикрепить файл к задаче',
                    icon: 'cloud_upload',
                    label: 'Прикрепить файл',
                    handler: uploadIt
                  }
                }"
                    :toolbar="[
                    ['left','center','right','justify'],
                    ['bold', 'italic', 'strike', 'underline'],
                    ['undo','redo'],
                    ['upload']
                ]"
                />
              </div>
              <br>
              <div class="text-left">
                <q-btn color="secondary" icon="ion-save" label="Обновить задачу" />
              </div>
            </q-form>
          </q-card-section>



        </q-card>
      </div>

    </div>
  </div>
</template>

<script>
import { useQuasar } from 'quasar'
import { ref } from 'vue'

const task_info = {
  title: 'Заголовок задачи',
  author: 'Автор задачи',
  description: 'Описание задачи'
}

const events = [
  {
    id: 1,
    title: 'Title',
    date: '21 Февраля 2021, 12:30',
    event_type: 'update',
    icon: 'check',
    status: 'В работе',
    author: 'Иванов. А.Ю.',
    description: 'Обновление сроков задачи'
  },
  {
    id: 2,
    title: 'Title',
    date: '21 Февраля 2021, 12:30',
    event_type: 'update',
    icon: 'info',
    status: 'В работе',
    author: 'Иванов. А.Ю.',
    description: 'Обновление сроков задачи'
  },
  {
    id: 3,
    title: 'Title',
    date: '21 Февраля 2021, 12:30',
    event_type: 'update',
    icon: 'info',
    status: 'В работе',
    author: 'Иванов. А.Ю.',
    description: 'Обновление сроков задачи'
  }
]

const columns = [
  { name: 'status', label: 'Статус', align: 'left', field: 'status' },
  { name: 'start_at', label: 'Начало', align: 'left', field: 'start_at' },
  { name: 'end_at', label: 'Конец', align: 'left', field: 'end_at'},
  { name: 'user', label: 'Назначена', align: 'left', field: 'user' }
]

const rows = [
  {
    status: 'Новая',
    start_at: '20.08.2021',
    end_at: '28.08.2021',
    user: 'Мне',
  }
]

export default {
  name: "task_info",
  created() {
    console.log(this.$route.params.task_id)
  },
  setup () {
    const $q = useQuasar()
    return {
      rows,
      columns,
      events,
      task_info,
      editor: ref(
          ''
      ),

      status_model: ref(null),
      employer_model: ref(null),

      status_options: ['В работе', 'Обратная связь', 'Закрыт'],
      employer_options: ['Иванов И.А.', 'Петров А.В.', 'Лопухин Г.А.'],

      saveWork () {
        $q.notify({
          message: 'Saved your text to local storage',
          color: 'green-4',
          textColor: 'white',
          icon: 'cloud_done'
        })
      },
      uploadIt () {
        $q.notify({
          message: 'Server unavailable. Check connectivity.',
          color: 'red-5',
          textColor: 'white',
          icon: 'warning'
        })
      }
    }
  }
}

</script>

<style lang="sass" scoped>
.my-card
  width: 100%
</style>