<template>
  <div class="q-pa-md">
    <div class="q-ma-md">
      <q-breadcrumbs>
        <q-breadcrumbs-el label="Главная" @click="$router.back()" />
        <q-breadcrumbs-el label="Новое рабочее место" />
      </q-breadcrumbs>
    </div>
    <div class="q-ma-md text-h5">
      Создать рабочее место
    </div>
    <div class="q-pa-md row">
      <div class="col-7">
        <div class="q-pa-md">
          <q-select :disable="!can_save_role" v-model="parent" :options="parents" label="Руководитель" />
        </div>
        <div class="q-pa-md">
          <q-input :disable="!can_save_role" v-model="role_title" label="Название должности" />
        </div>
        <div class="q-gutter-sm">
          <q-list>
            <q-item tag="label" v-ripple>
              <q-item-section avatar top>
                <q-checkbox :disable="!can_save_role" v-model="edit_roles" color="primary" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Редактировать должности</q-item-label>
                <q-item-label caption>
                  Право редактировать должности организации
                </q-item-label>
                <q-slide-transition>
                  <q-list v-show="edit_roles">
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="!can_save_role" v-model="edit_roles_invite" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Добавлять сотрудников</q-item-label>
                        <q-item-label caption>
                          Отсылать приглашения и подтверждать пользователей в системе
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="!can_save_role" v-model="edit_roles_change" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Управлять рабочими местами</q-item-label>
                        <q-item-label caption>
                          Создавать, редактировать и удалять рабочие места
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="!can_save_role" v-model="edit_roles_edit_users" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Редактировать сотрудников</q-item-label>
                        <q-item-label caption>
                          Изменять личные данные сотрудников, назначать рабочие места
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="!can_save_role" v-model="edit_roles_fire" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Увольнять сотрудников</q-item-label>
                        <q-item-label caption>
                          Удалять сотрудников из организации
                        </q-item-label>
                      </q-item-section>
                    </q-item>

                  </q-list>
                </q-slide-transition>
              </q-item-section>
            </q-item>
            <q-item tag="label" v-ripple>
              <q-item-section avatar top>
                <q-checkbox :disable="!can_save_role" v-model="edit_organisation" color="primary" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Редактировать организацию</q-item-label>
                <q-item-label caption>
                  Право редактировать данные организации
                </q-item-label>
                <q-slide-transition>
                  <q-list v-show="edit_organisation">
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="!can_save_role" v-model="edit_organisation_balance" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Баланс организации</q-item-label>
                        <q-item-label caption>
                          Просмотр баланса организации и запрос счетов на оплату
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="!can_save_role" v-model="edit_organisation_bills" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Акты и счета</q-item-label>
                        <q-item-label caption>
                          Получение актов и счетов-фактур
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section avatar top>
                        <q-checkbox :disable="!can_save_role" v-model="edit_organisation_plans" color="primary" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Тарифный план</q-item-label>
                        <q-item-label caption>
                          Просмотр и изменение тарифного плана организации
                        </q-item-label>
                      </q-item-section>
                    </q-item>

                  </q-list>
                </q-slide-transition>
              </q-item-section>
            </q-item>
            <q-separator/>
            <q-item-label header>Документы</q-item-label>
            <q-item tag="label" v-ripple>
              <q-item-section avatar top>
                <q-checkbox :disable="!can_save_role" v-model="open_documents" color="primary" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Просматривать документы</q-item-label>
                <q-item-label caption>
                  Право просматривать документы организации
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item tag="label" v-ripple>
              <q-item-section avatar top>
                <q-checkbox :disable="!can_save_role" v-model="edit_documents" color="primary" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Редактировать документы</q-item-label>
                <q-item-label caption>
                  Право создавать, редактировать, удалять документы организации
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-separator/>
            <q-item-label header>Бухгалтерия</q-item-label>
            <q-item tag="label" v-ripple>
              <q-item-section avatar top>
                <q-checkbox :disable="!can_save_role" v-model="open_finance" color="primary" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Бухгалтерия</q-item-label>
                <q-item-label caption>
                  Доступ к просмотру бухучета организации
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
        <div class="q-pa-md">
          <q-btn v-if="can_save_role" label="Сохранить" @click="addRole" color="primary"/>
        </div>
      </div>
      <div class="q-pa-lg col-5">
        <div class="q-pa-lg">
          <q-list>
            <q-item v-if="can_invite_user" clickable v-ripple>

              <q-item-section>
                <q-item-label>
                  <q-btn label="Пригласить" color="primary" @click="prompt=true"/>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
          <q-dialog v-model="prompt" persistent>
            <q-card style="min-width: 350px">
              <q-card-section>
                <div class="text-h6">Email</div>
              </q-card-section>
              <q-card-section class="q-pt-none">
                <q-input dense v-model="email_invite" autofocus @keyup.enter="prompt = false" />
              </q-card-section>
              <q-card-actions align="right" class="text-primary">
                <q-btn flat label="Отмена" v-close-popup />
                <q-btn flat label="Пригласить" v-close-popup @click="inviteUserToOrganisation"/>
              </q-card-actions>
            </q-card>
          </q-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import { useQuasar } from 'quasar'

export default {
  name: "new_settings",
  mounted() {

  },
  data() {
    setTimeout(() => {
      this.connection.getOrgStructureFlat('1', this.$store.state.current_org_id);
    }, 2000);
    
    this.emitter.on("didReceiveOrgStructureFlat", (data) => {
      console.log(data);
      this.organisation_flat = data;
      this.parents = this.organisation_flat.roles.map(function (x) { return {label: x.label, id: x.id} } );
      this.options = this.organisation_flat.roles.map(function (x) { return x.label } )
      // this.parent = this.parents[0].label
    });
    const $q = useQuasar();
    this.emitter.on("didCreateNewRole", (data) => {
      console.log(data);
      this.role_id = data.role_id;
      this.can_invite_user = true;
      this.can_save_role = false;
      $q.notify('Новая роль успешно создана');
    });
    this.emitter.on("didInviteUserToOrg", (data) => {
      console.log(data);
      this.can_invite_user = false;
      $q.notify('Приглашение было успешно отправлено');
    });
    return {
      can_save_role: ref(true),
      can_invite_user: ref(false),
      prompt: ref(false),
      organisation_flat: ref({roles: []}),
      parents: [],
      options: ref([]),
      parent: ref(''),
      role_title: ref(''),
      edit_users: ref(false),
      edit_roles: ref(false),
      edit_roles_invite: ref(true),
      edit_roles_change: ref(true),
      edit_roles_fire: ref(true),
      edit_roles_edit_users: ref(true),
      edit_organisation: ref(false),
      edit_organisation_balance: ref(true),
      edit_organisation_bills: ref(true),
      edit_organisation_plans: ref(true),
      open_documents: ref(false),
      edit_documents: ref(false),
      open_finance: ref(false),
      edit_finance: ref(false),
      role_id: ref(null)
    }
  },
  methods: {
    inviteUserToOrganisation() {
      const org_id = this.$store.state.current_org_id;
      const data = {
        org_id: org_id,
        email: this.email_invite,
        role_id: this.role_id
      }
      this.connection.inviteUserToOrg('1', data)
      this.email_invite = null
    },
    addRole() {
      const org_id = this.$store.state.current_org_id;
      const data = {
        role_title: this.role_title,
        parent_role_id: this.parent.id,
        edit_users: this.edit_users,
        edit_roles: this.edit_roles,
        edit_roles_invite: this.edit_roles_invite,
        edit_roles_change: this.edit_roles_change,
        edit_roles_fire: this.edit_roles_fire,
        edit_roles_edit_users: this.edit_roles_edit_users,
        edit_organisation: this.edit_organisation,
        edit_organisation_balance: this.edit_organisation_balance,
        edit_organisation_bills: this.edit_organisation_bills,
        edit_organisation_plans: this.edit_organisation_plans,
        open_documents: this.open_documents,
        edit_documents: this.edit_documents,
        open_finance: this.open_finance,
        edit_finance: this.edit_finance,
      }
      console.log(data)
      this.connection.createNewRole('1', org_id, data);
    }
  }
}
</script>

<style scoped>

</style>