const apiUrl = 'https://api.siderp.ru'
const wsUrl = 'wss://ws.siderp.ru'
const mediaUrl = 'https//media.siderp.ru'


export {
    apiUrl,
    wsUrl,
    mediaUrl
}
