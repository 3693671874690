<template>
  <div class="q-pa-md">
    <div class="q-ma-md text-h5">
      Информация об организации
    </div>
    <div class="q-pa-md row">
      <q-inner-loading :showing="isRequestSended">
        <q-spinner-gears size="100px" color="primary" />
      </q-inner-loading>
      <div class="col-7" v-show="!isRequestSended">
        <div class="q-pa-md">
          <q-input v-model="family" label="Полное наименование" />
        </div>
        <div class="q-pa-md">
          <q-input v-model="family" label="Адрес" />
        </div>
        <div class="q-pa-md">
          <q-input v-model="family" label="ОГРН" />
        </div>
        <div class="q-pa-md">
          <q-input v-model="family" label="ОКПО" />
        </div>
        <div class="q-pa-md">
          <q-input v-model="name" label="ОКВЭД" />
        </div>
        <div class="q-pa-md">
          <q-input v-model="surname" label="Дата создания" />
        </div>
        <div class="q-pa-md">
          <q-btn label="Сохранить" color="primary"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "info"
}
</script>

<style scoped>

</style>