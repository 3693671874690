<template>

  <table class="updskeleton" width="100%" border="0" cellspacing="0" cellpadding="0">
    <tbody>
      <tr>
        <td width="110" style="padding: 5px">
          Универсальный передаточный документ

          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td style="vertical-align: middle !important;">Статус:</td>
                <td style="width: 50%; border: 1px solid #000; text-align: center !important; padding: 5px">
                  <q-select :options="[1, 2]" borderless v-model="status" />
                </td>
              </tr>
            </tbody>
          </table>

          1 — счет-фактура и передаточный документ (акт)

          2 — передаточный документ (акт)
        </td>
        <td style="border-left: 2px solid #000">
          <table width="100%" border="0" cellspacing="0" cellpadding="5">
            <tbody>
              <tr>
                <td width="100">Счет-фактура №</td>
                <td width="160" style="border-bottom: 1px solid #000; text-align: center !important"><q-input dense
                    borderless v-model="in_org_number" /></td>
                <td width="20">от</td>
                <td width="160" style="border-bottom: 1px solid #000; text-align: center !important">
                  <span class="inline-block">{{ document_date }}</span> <q-btn class="inline-block" icon="event" flat
                    dense>
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                      <q-date v-model="document_date">
                        <div class="row items-center justify-end q-gutter-sm">
                          <q-btn label="Отмена" color="primary" flat v-close-popup />
                          <q-btn label="Применить" color="primary" flat v-close-popup />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-btn>
                </td>
                <td width="20">(1)</td>
                <td rowspan="2" style="text-align: right !important; font-size: 9px">
                  Приложение № 1 к постановлению Правительства Российской Федерации от 26 декабря 2011 г. № 1137
                  (в редакции постановления Правительства Российской Федерации от 2 апреля 2021 г. № 534)
                </td>
              </tr>
              <tr>
                <td>Исправление №</td>
                <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="change_n" /></td>
                <td>от</td>
                <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="change_date" /></td>
                <td>(1а)</td>
              </tr>
            </tbody>
          </table>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td width="50%">
                  <table width="100%" border="0" cellspacing="0" cellpadding="5">
                    <tbody>
                      <tr>
                        <td width="170"><b>Продавец:</b></td>
                        <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="seller" />
                        </td>
                        <td width="20">(2)</td>
                      </tr>
                      <tr>
                        <td>Адрес:</td>
                        <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="seller_adr" /></td>
                        <td>(2а)</td>
                      </tr>
                      <tr>
                        <td>ИНН/КПП продавца:</td>
                        <td style="border-bottom: 1px solid #000">
                          <q-input dense mask="## ## ###### ##" borderless v-model="seller_inn" hint="ИНН / КПП"
                            @update:model-value="checkAgentBy(seller_inn, 'seller_inn')" />
                        </td>
                        <td>(2б)</td>
                      </tr>
                      <tr>
                        <td>Грузоотправитель и его адрес:</td>
                        <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="seller_sender" />
                        </td>
                        <td>(3)</td>
                      </tr>
                      <tr>
                        <td>Грузополучатель и его адрес:</td>
                        <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="seller_receiver" />
                        </td>
                        <td>(4)</td>
                      </tr>
                      <tr>
                        <td>К платежно-расчетному документу №</td>
                        <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="seller_doc" /></td>
                        <td>(5)</td>
                      </tr>
                      <tr>
                        <td>Документ об отгрузке</td>
                        <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="seller_send_doc" />
                        </td>
                        <td>(5a)</td>
                      </tr>
                    </tbody>
                  </table>

                </td>
                <td>
                  <table width="100%" border="0" cellspacing="0" cellpadding="5">
                    <tbody>
                      <tr>
                        <td width="170"><b>Покупатель:</b></td>
                        <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="cust" /></td>
                        <td width="20">(6)</td>
                      </tr>
                      <tr>
                        <td>Адрес:</td>
                        <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="cust_adr" />
                        </td>
                        <td>(6а)</td>
                      </tr>
                      <tr>
                        <td>ИНН/КПП покупателя:</td>
                        <td style="border-bottom: 1px solid #000">
                          <q-input dense mask="## ## ###### ##" borderless v-model="cust_inn" hint="ИНН / КПП"
                            @update:model-value="checkAgentBy(cust_inn, 'cust_inn')" />
                        </td>
                        <td>(6б)</td>
                      </tr>
                      <tr>
                        <td>Валюта: наименование, код</td>
                        <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="currency" />
                        </td>
                        <td>(7)</td>
                      </tr>
                      <tr>
                        <td>Идентификатор государственного контракта, договора (соглашения) (при наличии):</td>
                        <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="goscon" />
                        </td>
                        <td>(8)</td>
                      </tr>
                    </tbody>
                  </table>

                </td>
              </tr>
            </tbody>
          </table>

        </td>
      </tr>
    </tbody>
  </table>

  <table class="updorderlist" width="100%" border="0" cellspacing="0" cellpadding="5">
    <tbody>
      <tr>
        <td rowspan="2" style="width: 10mm;">Код товара / работ, услуг</td>
        <td rowspan="2" style="width: 5mm;">№ п/п</td>
        <td style="width: 60mm;" rowspan="2">Наименование товара (описание выполненных работ, оказанных услуг),
          имущественного права</td>
        <td rowspan="2" style="width: 15mm;">Код вида товара</td>
        <td colspan="2" style="width: 30mm;">Единица измерения</td>
        <td rowspan="2" style="width: 15mm;">Количество (объём)</td>
        <td rowspan="2" style="width: 15mm;">Цена (тариф) за единицу измерения</td>
        <td rowspan="2" style="width: 20mm;">Стоимость товаров (работ, услуг), имущественных прав без налога — всего
        </td>
        <td rowspan="2" style="width: 10mm;">В том числе сумма акциза</td>
        <td rowspan="2" style="width: 15mm;">Налоговая ставка</td>
        <td rowspan="2" style="width: 15mm;">Сумма налога, предъявляемая покупателю</td>
        <td rowspan="2" style="width: 20mm;">Стоимость товаров (работ, услуг), имущественных прав с налогом — всего
        </td>
        <td colspan="2" style="width: 30mm;">Страна происхождения товара</td>
        <td rowspan="2" style="width: 30mm;">Регистрационный номер декларации</td>
      </tr>
      <tr>
        <td style="width: 10mm;">Код</td>
        <td style="width: 20mm;">Условное обозначение (национальное)</td>
        <td style="width: 10mm;">Цифровой код</td>
        <td style="width: 20mm;">Краткое наименование</td>
      </tr>
      <!-- <tr style="font-size:9px">
        <td width="110" rowspan="2">Код товара / работ, услуг</td>
        <td width="30" rowspan="2" style="border-left:2px solid #000">№ п/п</td>
        <td rowspan="2">Наименование товара (описание выполненных работ, оказанных услуг), имущественного права
        </td>
        <td rowspan="2" width="40">Код вида това-
          ра</td>
        <td colspan="2">Единица измерения</td>
        <td rowspan="2" width="70">Количест-
          во (объём)</td>
        <td rowspan="2" width="70">Цена (тариф) за единицу измерения</td>
        <td rowspan="2" width="70">Стоимость товаров (работ, услуг), имущест-
          венных прав без налога — всего</td>
        <td rowspan="2" width="50">В том числе сумма акциза</td>
        <td rowspan="2" width="50">Нало-
          говая ставка</td>
        <td rowspan="2" width="70">Сумма налога, предъяв-
          ляемая покупателю</td>
        <td rowspan="2" width="70">Стоимость товаров (работ, услуг), имущест-
          венных прав с налогом — всего</td>
        <td colspan="2">Страна происхождения товара</td>
        <td rowspan="2" width="60" style="font-size:9px">Регистраци-
          онный номер декларации на товары или регист-
          рационный номер пар-
          тии товара, подлежаще-
          го просле-
          живаемости</td>
      </tr>
      <tr>
        <td width="40">Код</td>
        <td width="30">Условно-
          е обоз-
          начение (нацио-
          нальное)</td>
        <td width="30">Циф-
          ровой код</td>
        <td width="30">Краткое наиме-
          нование</td>
      </tr> -->
      <tr>
        <td style="border-right:2px solid #000; text-align: center; font-size: 9px">A</td>
        <td style="text-align: center; font-size: 9px">1</td>
        <td style="text-align: center; font-size: 9px">1а</td>
        <td style="text-align: center; font-size: 9px">1б</td>
        <td style="text-align: center; font-size: 9px">2</td>
        <td style="text-align: center; font-size: 9px">2а</td>
        <td style="text-align: center; font-size: 9px">3</td>
        <td style="text-align: center; font-size: 9px">4</td>
        <td style="text-align: center; font-size: 9px">5</td>
        <td style="text-align: center; font-size: 9px">6</td>
        <td style="text-align: center; font-size: 9px">7</td>
        <td style="text-align: center; font-size: 9px">8</td>
        <td style="text-align: center; font-size: 9px">9</td>
        <td style="text-align: center; font-size: 9px">10</td>
        <td style="text-align: center; font-size: 9px">10а</td>
        <td style="text-align: center; font-size: 9px">11</td>
      </tr>
      <tr v-for="item in table_rows" v-bind:key="item.id">
        <td style="border-right:2px solid #000"><q-input dense borderless v-model="item.code" /></td>
        <td><q-btn dense flat icon="delete" color="negative" @click="deleteRow(item.id)" /></td>
        <td><q-input dense borderless v-model="item.name" /></td>
        <td><q-input dense borderless v-model="item.kind_code" /></td>
        <td>{{ (item.measure_value || { value: '' }).value }}</td>
        <td><q-select :options="count_measures_options" dense borderless v-model="item.measure_value" /></td>
        <td><q-input dense borderless @update:model-value="(newValue) => onCountDataChanged(newValue, item.id - 1)"
            v-model="item.count" />
        </td>
        <td><q-input dense borderless @update:model-value="(newValue) => onPriceDataChanged(newValue, item.id - 1)"
            v-model="item.price" />
        </td>
        <td>{{ item.cost_without_nds }}</td>
        <td><q-input dense borderless @update:model-value="(newValue) => onAcizDataChanged(newValue, item.id - 1)"
            v-model="item.aciz" /></td>
        <td><q-select :options="nds_percentage_options"
            @update:model-value="(newValue) => onNDSDataChanged(newValue.value, item.id - 1)" dense borderless
            v-model="item.nds" /></td>
        <td>{{ item.nalog }}</td>
        <td>{{ item.summtotal }}</td>
        <td>{{ (item.countryname || { value: '' }).value }}</td>
        <td><q-select :options="country_options" dense borderless v-model="item.countryname" /></td>
        <td><q-input dense borderless v-model="item.doc" /></td>
      </tr>
      <tr>
        <td style="border-right:2px solid #000"> </td>
        <td><q-btn icon="add" dense flat @click="addTableRow" /></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td style="border-right:2px solid #000"> </td>
        <td colspan="7">Всего к оплате (9)</td>
        <td>ИТОГО</td>
        <td colspan="2" style="text-align: center !important"></td>
        <td>{{ summary.nalog }}</td>
        <td>{{ summary.summtotal }}</td>
        <td colspan="3"></td>
      </tr>
    </tbody>
  </table>
  <table class="updskeleton" width="100%" border="0" cellspacing="0" cellpadding="0">
    <tbody>
      <tr>
        <td width="110" style="padding: 5px">
          Документ составлен на x листe
        </td>
        <td style="border-left: 2px solid #000; border-bottom: 2px solid #000; padding-bottom: 5px">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td width="49%">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                      <tr>
                        <td width="170" style="padding: 5px">Руководитель организации или иное уполномоченное лицо
                        </td>
                        <td style="border-bottom: 1px solid #000; padding: 5px" width="100"> </td>
                        <td width="10"> </td>
                        <td style="vertical-align: bottom !important; border-bottom: 1px solid #000; padding: 5px">
                          <q-input dense borderless v-model="rukvod" />
                        </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td style="text-align: center; font-size:9px">(подпись)</td>
                        <td> </td>
                        <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td width="2%"> </td>
                <td>
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                      <tr>
                        <td width="170" style="padding: 5px">Главный бухгалтер или иное уполномоченное лицо</td>
                        <td style="border-bottom: 1px solid #000; padding: 5px" width="100"> </td>
                        <td width="10"> </td>
                        <td style="vertical-align: bottom !important; border-bottom: 1px solid #000; padding: 5px">
                          <q-input dense borderless v-model="glavbuh" />
                        </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td style="text-align: center; font-size:9px">(подпись)</td>
                        <td> </td>
                        <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td width="170" style="padding: 5px">Индивидуальный предприниматель или иное уполномоченное лицо
                </td>
                <td style="border-bottom: 1px solid #000; padding: 5px" width="100"> </td>
                <td width="10"> </td>
                <td style="vertical-align: bottom !important; border-bottom: 1px solid #000; padding: 5px">
                  <q-input dense borderless v-model="ip_name" />
                </td>
                <td width="2%"> </td>
                <td style="vertical-align: bottom !important; border-bottom: 1px solid #000; padding: 5px" width="49%">
                  <q-input dense borderless v-model="ip_req" />
                </td>
              </tr>
              <tr>
                <td> </td>
                <td style="text-align: center; font-size:9px">(подпись)</td>
                <td> </td>
                <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                <td> </td>
                <td style="text-align: center; font-size:9px">(реквизиты свидетельства о государственной
                  регистрации индивидуального предпринимателя)</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  <table width="100%" border="0" cellspacing="0" cellpadding="0">
    <tbody>
      <tr>
        <td width="280" style="padding: 5px">Основание передачи (сдачи) / получения (приемки)</td>
        <td style="border-bottom: 1px solid #000; padding: 5px"><q-input dense borderless v-model="osn_rec_sent" /></td>
        <td width="10" style="padding: 5px">[8]</td>
      </tr>
      <tr>
        <td> </td>
        <td style="text-align: center; font-size:9px">(договор, доверенность и др.)</td>
        <td> </td>
      </tr>
    </tbody>
  </table>
  <table width="100%" border="0" cellspacing="0" cellpadding="0">
    <tbody>
      <tr>
        <td width="190" style="padding: 5px; padding-top:0px;">Данные о транспортировке и грузе</td>
        <td style="border-bottom: 1px solid #000; padding: 5px; padding-top:0px;"><q-input dense borderless
            v-model="transp" /></td>
        <td width="10" style="padding: 5px; padding-top:0px;">[9]</td>
      </tr>
      <tr>
        <td> </td>
        <td style="text-align: center; font-size:9px; padding-bottom: 5px">(транспортная накладная, поручение
          экспедитору, экспедиторская/складская расписка и др., масса нетто/брутто груза, если не приведены ссылки
          на транспортные документы, содержащие эти сведения)</td>
        <td> </td>
      </tr>
    </tbody>
  </table>
  <table width="100%" border="0" cellspacing="0" cellpadding="0" style="page-break-inside:avoid">
    <tbody>
      <tr>
        <td width="50%" style="padding-right: 5px; border-right: 2px solid #000">
          <div style="padding-left:5px;">Товар (груз) передал / услуги, результаты работ, права сдал</div>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td width="200" style="border-bottom: 1px solid #000; padding: 5px"><q-input dense borderless
                    v-model="goods_sent_workplace" /></td>
                <td width="10"> </td>
                <td style="border-bottom: 1px solid #000" width="100"> </td>
                <td width="10"> </td>
                <td style="border-bottom: 1px solid #000; padding: 5px"><q-input dense borderless
                    v-model="goods_sent_fio" /></td>
                <td width="10" style="padding: 5px">[10]</td>
              </tr>
              <tr>
                <td style="text-align: center; font-size:9px">(должность)</td>
                <td> </td>
                <td style="text-align: center; font-size:9px">(подпись)</td>
                <td> </td>
                <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                <td> </td>
              </tr>
            </tbody>
          </table>
          <table width="100%" border="0" cellspacing="0" cellpadding="5">
            <tbody>
              <tr>
                <td width="210">Дата отгрузки, передачи (сдачи)</td>
                <td style="border-bottom: 1px solid #000;"><q-input dense borderless v-model="date_sent" /></td>
                <td width="10">[11]</td>
              </tr>
            </tbody>
          </table>

          <div style="padding-left:5px;">Иные сведения об отгрузке, передаче</div>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="sent_info" /></td>
                <td width="10" style="padding: 5px">[12]</td>
              </tr>
              <tr>
                <td style="text-align: center; font-size:9px">(ссылки на неотъемлемые приложения, сопутствующие
                  документы, иные документы и т.п.)</td>
                <td> </td>
              </tr>
            </tbody>
          </table>

          <div style="padding-left:5px;">Ответственный за правильность оформления факта хозяйственной жизни</div>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td width="200" style="border-bottom: 1px solid #000; padding: 5px"><q-input dense borderless
                    v-model="hozvod_workplace" /></td>
                <td width="10"> </td>
                <td style="border-bottom: 1px solid #000" width="100"> </td>
                <td width="10"> </td>
                <td style="border-bottom: 1px solid #000; padding: 5px"><q-input dense borderless
                    v-model="hozvod_fio" /></td>
                <td width="10" style="padding: 5px">[13]</td>
              </tr>
              <tr>
                <td style="text-align: center; font-size:9px">(должность)</td>
                <td> </td>
                <td style="text-align: center; font-size:9px">(подпись)</td>
                <td> </td>
                <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                <td> </td>
              </tr>
            </tbody>
          </table>

          <div style="padding-left:5px;">Наименование экономического субъекта — составителя документа (в т. ч.
            комиссионера / агента)</div>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td style="border-bottom: 1px solid #000; padding: 5px"><q-input dense borderless
                    v-model="econom_name" /></td>
                <td width="10" style="padding: 5px">[14]</td>
              </tr>
              <tr>
                <td style="text-align: center; font-size:9px">(может не заполняться при проставлении печати в
                  М.П., может быть указан ИНН/КПП)</td>
                <td> </td>
              </tr>
            </tbody>
          </table>

          <div style="padding-left:50px;">М.П.</div>
        </td>
        <td style="padding-left: 5px">
          <div style="padding-left:5px;">Товар (груз) получил / услуги, результаты работ, права принял</div>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td width="200" style="border-bottom: 1px solid #000;"><q-input dense borderless
                    v-model="goods_rec_workplace" /></td>
                <td width="10"> </td>
                <td style="border-bottom: 1px solid #000" width="100"></td>
                <td width="10"> </td>
                <td style="border-bottom: 1px solid #000;"><q-input dense borderless v-model="goods_rec_fio" /></td>
                <td width="10" style="padding: 5px">[15]</td>
              </tr>
              <tr>
                <td style="text-align: center; font-size:9px">(должность)</td>
                <td> </td>
                <td style="text-align: center; font-size:9px">(подпись)</td>
                <td> </td>
                <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                <td> </td>
              </tr>
            </tbody>
          </table>
          <table width="100%" border="0" cellspacing="0" cellpadding="5">
            <tbody>
              <tr>
                <td width="210">Дата получения (приемки)</td>
                <td style="border-bottom: 1px solid #000;"><q-input dense borderless v-model="date_rec" /></td>
                <td width="10">[16]</td>
              </tr>
            </tbody>
          </table>

          <div style="padding-left:5px;">Иные сведения о получении, приемке</div>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="rec_info" /></td>
                <td width="10" style="padding: 5px">[17]</td>
              </tr>
              <tr>
                <td style="text-align: center; font-size:9px">(информация о наличии/отсутствии претензии; ссылки
                  на неотъемлемые приложения и другие документы и т. п.)</td>
                <td> </td>
              </tr>
            </tbody>
          </table>

          <div style="padding-left:5px;">Ответственный за правильность оформления факта хозяйственной жизни</div>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td width="200" style="border-bottom: 1px solid #000"><q-input dense borderless
                    v-model="hozvod_rec_workplace" /></td>
                <td width="10"> </td>
                <td style="border-bottom: 1px solid #000" width="100"> </td>
                <td width="10"> </td>
                <td style="border-bottom: 1px solid #000"><q-input dense borderless v-model="hozvod_rec_fio" />
                </td>
                <td width="10" style="padding: 5px">[18]</td>
              </tr>
              <tr>
                <td style="text-align: center; font-size:9px">(должность)</td>
                <td> </td>
                <td style="text-align: center; font-size:9px">(подпись)</td>
                <td> </td>
                <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                <td> </td>
              </tr>
            </tbody>
          </table>

          <div style="padding-left:5px;">Наименование экономического субъекта — составителя документа</div>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td style="border-bottom: 1px solid #000; padding: 5px"><q-input dense borderless
                    v-model="econom_rec_name" /></td>
                <td width="10" style="padding: 5px">[19]</td>
              </tr>
              <tr>
                <td style="text-align: center; font-size:9px">(может не заполняться при проставлении печати в
                  М.П., может быть указан ИНН/КПП)</td>
                <td> </td>
              </tr>
            </tbody>
          </table>

          <div style="padding-left:50px;">М.П.</div>
        </td>
      </tr>
    </tbody>
  </table>

  <div style="margin-top: 10mm;">
    <q-btn color="primary" icon="save" :disable="table_rows.length == 0" label="Сохранить" @click="onSave" />
  </div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";
import { nanoid } from "nanoid";

const document_direction_options = [
  {
    label: 'Входящий',
    value: 'in'
  },
  {
    label: 'Исходящий',
    value: 'out'
  },
  {
    label: 'Внутренний',
    value: 'internal'
  }
]

const nds_percentage_options = [
  {
    label: '20%',
    value: 0.2,
  },
  {
    label: '10%',
    value: 0.1,
  },
  {
    label: '0%',
    value: 0,
  },
  {
    label: 'Без НДС',
    value: 0,
  }
];

const transfers_table_columns = [
  { name: 'name', align: 'left', label: 'Название', field: 'name', sortable: true },
  { name: 'bill_from', align: 'left', label: 'Счет списания', field: 'bill_from', sortable: true },
  { name: 'bill_to', align: 'left', label: 'Счет зачисления', field: 'bill_to', sortable: true },
  { name: 'summ', align: 'left', label: 'Сумма', field: 'summ' },
  { name: 'status', align: 'left', label: 'Статус', field: 'status' }
]

const count_measures_options = [
  { value: '3', label: 'Миллиметр' },
  { value: '4', label: 'Сантиметр' },
  { value: '5', label: 'Дециметр' },
  { value: '6', label: 'Метр' },
  { value: '8', label: 'Километр^тысяча метров' },
  { value: '9', label: 'Мегаметр^миллион метров' },
  { value: '39', label: 'Дюйм (25,4 мм)' },
  { value: '41', label: 'Фут (0,3048 м)' },
  { value: '43', label: 'Ярд (0,9144 м)' },
  { value: '47', label: 'Морская миля (1852 м)' },
  { value: '50', label: 'Квадратный миллиметр' },
  { value: '51', label: 'Квадратный сантиметр' },
  { value: '53', label: 'Квадратный дециметр' },
  { value: '55', label: 'Квадратный метр' },
  { value: '58', label: 'Тысяча квадратных метров' },
  { value: '59', label: 'Гектар' },
  { value: '61', label: 'Квадратный километр' },
  { value: '71', label: 'Квадратный дюйм (645,16 мм2)' },
  { value: '73', label: 'Квадратный фут (0,092903 м2)' },
  { value: '75', label: 'Квадратный ярд (0,8361274 м2)' },
  { value: '109', label: 'Ар (100 м2)' },
  { value: '110', label: 'Кубический миллиметр' },
  { value: '111', label: 'Кубический сантиметр^миллилитр' },
  { value: '112', label: 'Литр^кубический дециметр' },
  { value: '113', label: 'Кубический метр' },
  { value: '118', label: 'Децилитр' },
  { value: '122', label: 'Гектолитр' },
  { value: '126', label: 'Мегалитр' },
  { value: '131', label: 'Кубический дюйм (16387,1 мм3)' },
  { value: '132', label: 'Кубический фут (0,02831685 м3)' },
  { value: '133', label: 'Кубический ярд (0,764555 м3)' },
  { value: '159', label: 'Миллион кубических метров' },
  { value: '160', label: 'Гектограмм' },
  { value: '161', label: 'Миллиграмм' },
  { value: '162', label: 'Метрический карат^(1 карат=200 мг=2.10[-4*] кг)' },
  { value: '163', label: 'Грамм' },
  { value: '164', label: 'Микрограмм' },
  { value: '166', label: 'Килограмм' },
  { value: '168', label: 'Тонна^метрическая тонна (1000 кг)' },
  { value: '170', label: 'Килотонна' },
  { value: '173', label: 'Сантиграмм' },
  { value: '181', label: 'Брутто-регистровая тонна (2,8316 м[3*])' },
  { value: '185', label: 'Грузоподъемность в метрических тоннах' },
  { value: '206', label: 'Центнер (метрический)^(100 кг)^ гектокилограмм^ квинтал[*] (метрический)^децитоннах' },
  { value: '212', label: 'Ватт' },
  { value: '214', label: 'Киловатт' },
  { value: '215', label: 'Мегаватт^тысяча киловатт' },
  { value: '222', label: 'Вольт' },
  { value: '223', label: 'Киловольт' },
  { value: '227', label: 'Киловольт-ампер' },
  { value: '228', label: 'Мегавольт-ампер (тысяча киловольт-ампер)' },
  { value: '230', label: 'Киловар' },
  { value: '243', label: 'Ватт-час' },
  { value: '245', label: 'Киловатт-час' },
  { value: '246', label: 'Мегаватт-час^1000 киловатт-часов' },
  { value: '247', label: 'Гигаватт-час (миллион киловатт-часов)' },
  { value: '260', label: 'Ампер' },
  { value: '263', label: 'Ампер-час (3,6 кКл)' },
  { value: '264', label: 'Тысяча ампер-часов' },
  { value: '270', label: 'Кулон' },
  { value: '271', label: 'Джоуль' },
  { value: '273', label: 'Килоджоуль' },
  { value: '274', label: 'Ом' },
  { value: '280', label: 'Градус Цельсия' },
  { value: '281', label: 'Градус Фаренгейта' },
  { value: '282', label: 'Кандела' },
  { value: '283', label: 'Люкс' },
  { value: '284', label: 'Люмен' },
  { value: '288', label: 'Кельвин' },
  { value: '289', label: 'Ньютон' },
  { value: '290', label: 'Герц' },
  { value: '291', label: 'Килогерц' },
  { value: '292', label: 'Мегагерц' },
  { value: '294', label: 'Паскаль' },
  { value: '296', label: 'Сименс' },
  { value: '297', label: 'Килопаскаль' },
  { value: '298', label: 'Мегапаскаль' },
  { value: '300', label: 'Физическая атмосфера (101325 Па)' },
  { value: '301', label: 'Техническая атмосфера (98066,5 Па)' },
  { value: '302', label: 'Гигабеккерель' },
  { value: '303', label: 'Килобеккерель' },
  { value: '304', label: 'Милликюри' },
  { value: '305', label: 'Кюри' },
  { value: '306', label: 'Грамм делящихся изотопов' },
  { value: '307', label: 'Мегабеккерель' },
  { value: '308', label: 'Миллибар' },
  { value: '309', label: 'Бар' },
  { value: '310', label: 'Гектобар' },
  { value: '312', label: 'Килобар' },
  { value: '314', label: 'Фарад' },
  { value: '316', label: 'Киллограмм на кубический метр' },
  { value: '318', label: 'Зиверт' },
  { value: '319', label: 'Микрозиверт' },
  { value: '320', label: 'Моль' },
  { value: '321', label: 'Миллизиверт' },
  { value: '323', label: 'Беккерель' },
  { value: '324', label: 'Вебер' },
  { value: '327', label: 'Узел (миля/ч)' },
  { value: '328', label: 'Метр в секунду' },
  { value: '330', label: 'Оборот в секунду' },
  { value: '331', label: 'Оборот в минуту' },
  { value: '333', label: 'Километр в час' },
  { value: '335', label: 'Метр на секунду в квадрате' },
  { value: '349', label: 'Кулон на килограмм' },
  { value: '354', label: 'Секунда' },
  { value: '355', label: 'Минута' },
  { value: '356', label: 'Час' },
  { value: '359', label: 'Сутки' },
  { value: '360', label: 'Неделя' },
  { value: '361', label: 'Декада' },
  { value: '362', label: 'Месяц' },
  { value: '364', label: 'Квартал' },
  { value: '365', label: 'Полугодие' },
  { value: '366', label: 'Год' },
  { value: '368', label: 'Десятилетие' },
  { value: '499', label: 'Килограмм в секунду' },
  { value: '533', label: 'Тонна пара в час' },
  { value: '596', label: 'Кубический метр в секунду' },
  { value: '598', label: 'Кубический метр в час' },
  { value: '599', label: 'Тысяча кубических метров в сутки' },
  { value: '616', label: 'Бобина' },
  { value: '625', label: 'Лист' },
  { value: '626', label: 'Сто листов' },
  { value: '630', label: 'Тысяча стандартных условных кирпичей' },
  { value: '641', label: 'Дюжина (12 шт.)' },
  { value: '657', label: 'Изделие' },
  { value: '683', label: 'Сто ящиков' },
  { value: '704', label: 'Набор' },
  { value: '715', label: 'Пара (2 шт.)' },
  { value: '730', label: 'Два десятка' },
  { value: '732', label: 'Десять пар' },
  { value: '733', label: 'Дюжина пар' },
  { value: '734', label: 'Посылка' },
  { value: '735', label: 'Часть' },
  { value: '736', label: 'Рулон' },
  { value: '737', label: 'Дюжина рулонов' },
  { value: '740', label: 'Дюжина штук' },
  { value: '745', label: 'Элемент' },
  { value: '778', label: 'Упаковка' },
  { value: '780', label: 'Дюжина упаковок' },
  { value: '781', label: 'Сто упаковок' },
  { value: '796', label: 'Штука' },
  { value: '797', label: 'Сто штук' },
  { value: '798', label: 'Тысяча штук' },
  { value: '799', label: 'Миллион штук' },
  { value: '800', label: 'Миллиард штук' },
  { value: '801', label: 'Биллион штук (Европа)^триллион штук' },
  { value: '802', label: 'Квинтильон штук (Европа)' },
  { value: '820', label: 'Крепость спирта по массе' },
  { value: '821', label: 'Крепость спирта по объему' },
  { value: '831', label: 'Литр чистого (100 %) спирта' },
  { value: '833', label: 'Гектолитр чистого (100 %) спирта' },
  { value: '841', label: 'Килограмм пероксида водорода' },
  { value: '845', label: 'Килограмм 90 %-го сухого вещества' },
  { value: '847', label: 'Тонна 90 %-го сухого вещества' },
  { value: '852', label: 'Килограмм оксида калия' },
  { value: '859', label: 'Килограмм гидроксида калия' },
  { value: '861', label: 'Килограмм азота' },
  { value: '863', label: 'Килограмм гидроксида натрия' },
  { value: '865', label: 'Килограмм пятиокиси фосфора' },
  { value: '867', label: 'Килограмм урана' }
];
const country_options = [
  {
    "label": "Австралия",
    "value": "AU"
  },
  {
    "label": "Австрия",
    "value": "AT"
  },
  {
    "label": "Азербайджан",
    "value": "AZ"
  },
  {
    "label": "Аландские острова",
    "value": "AX"
  },
  {
    "label": "Албания",
    "value": "AL"
  },
  {
    "label": "Алжир",
    "value": "DZ"
  },
  {
    "label": "Виргинские Острова (США)",
    "value": "VI"
  },
  {
    "label": "Американское Самоа",
    "value": "AS"
  },
  {
    "label": "Ангилья",
    "value": "AI"
  },
  {
    "label": "Ангола",
    "value": "AO"
  },
  {
    "label": "Андорра",
    "value": "AD"
  },
  {
    "label": "Антарктида",
    "value": "AQ"
  },
  {
    "label": "Антигуа и Барбуда",
    "value": "AG"
  },
  {
    "label": "Аргентина",
    "value": "AR"
  },
  {
    "label": "Армения",
    "value": "AM"
  },
  {
    "label": "Аруба",
    "value": "AW"
  },
  {
    "label": "Афганистан",
    "value": "AF"
  },
  {
    "label": "Багамские Острова",
    "value": "BS"
  },
  {
    "label": "Бангладеш",
    "value": "BD"
  },
  {
    "label": "Барбадос",
    "value": "BB"
  },
  {
    "label": "Бахрейн",
    "value": "BH"
  },
  {
    "label": "Белиз",
    "value": "BZ"
  },
  {
    "label": "Белоруссия",
    "value": "BY"
  },
  {
    "label": "Бельгия",
    "value": "BE"
  },
  {
    "label": "Бенин",
    "value": "BJ"
  },
  {
    "label": "Бермуды",
    "value": "BM"
  },
  {
    "label": "Болгария",
    "value": "BG"
  },
  {
    "label": "Боливия",
    "value": "BO"
  },
  {
    "label": "Бонэйр, Синт-Эстатиус и Саба",
    "value": "BQ"
  },
  {
    "label": "Босния и Герцеговина",
    "value": "BA"
  },
  {
    "label": "Ботсвана",
    "value": "BW"
  },
  {
    "label": "Бразилия",
    "value": "BR"
  },
  {
    "label": "Британская территория в Индийском океане",
    "value": "IO"
  },
  {
    "label": "Виргинские Острова (Великобритания)",
    "value": "VG"
  },
  {
    "label": "Бруней",
    "value": "BN"
  },
  {
    "label": "Буркина-Фасо",
    "value": "BF"
  },
  {
    "label": "Бурунди",
    "value": "BI"
  },
  {
    "label": "Бутан",
    "value": "BT"
  },
  {
    "label": "Вануату",
    "value": "VU"
  },
  {
    "label": "Ватикан",
    "value": "VA"
  },
  {
    "label": "Великобритания",
    "value": "GB"
  },
  {
    "label": "Венгрия",
    "value": "HU"
  },
  {
    "label": "Венесуэла",
    "value": "VE"
  },
  {
    "label": "Внешние малые острова (США)",
    "value": "UM"
  },
  {
    "label": "Восточный Тимор",
    "value": "TL"
  },
  {
    "label": "Вьетнам",
    "value": "VN"
  },
  {
    "label": "Габон",
    "value": "GA"
  },
  {
    "label": "Гаити",
    "value": "HT"
  },
  {
    "label": "Гайана",
    "value": "GY"
  },
  {
    "label": "Гамбия",
    "value": "GM"
  },
  {
    "label": "Гана",
    "value": "GH"
  },
  {
    "label": "Гваделупа",
    "value": "GP"
  },
  {
    "label": "Гватемала",
    "value": "GT"
  },
  {
    "label": "Гвиана",
    "value": "GF"
  },
  {
    "label": "Гвинея",
    "value": "GN"
  },
  {
    "label": "Гвинея-Бисау",
    "value": "GW"
  },
  {
    "label": "Германия",
    "value": "DE"
  },
  {
    "label": "Гернси",
    "value": "GG"
  },
  {
    "label": "Гибралтар",
    "value": "GI"
  },
  {
    "label": "Гондурас",
    "value": "HN"
  },
  {
    "label": "Гонконг",
    "value": "HK"
  },
  {
    "label": "Гренада",
    "value": "GD"
  },
  {
    "label": "Гренландия",
    "value": "GL"
  },
  {
    "label": "Греция",
    "value": "GR"
  },
  {
    "label": "Грузия",
    "value": "GE"
  },
  {
    "label": "Гуам",
    "value": "GU"
  },
  {
    "label": "Дания",
    "value": "DK"
  },
  {
    "label": "Джерси",
    "value": "JE"
  },
  {
    "label": "Джибути",
    "value": "DJ"
  },
  {
    "label": "Доминика",
    "value": "DM"
  },
  {
    "label": "Доминиканская Республика",
    "value": "DO"
  },
  {
    "label": "ДР Конго",
    "value": "CD"
  },
  {
    "label": "Флаг ЕС",
    "value": "Европейский"
  },
  {
    "label": "Египет",
    "value": "EG"
  },
  {
    "label": "Замбия",
    "value": "ZM"
  },
  {
    "label": "САДР",
    "value": "EH"
  },
  {
    "label": "Зимбабве",
    "value": "ZW"
  },
  {
    "label": "Израиль",
    "value": "IL"
  },
  {
    "label": "Индия Индия",
    "value": "IN"
  },
  {
    "label": "Индонезия",
    "value": "ID"
  },
  {
    "label": "Иордания",
    "value": "JO"
  },
  {
    "label": "Ирак",
    "value": "IQ"
  },
  {
    "label": "Иран",
    "value": "IR"
  },
  {
    "label": "Ирландия",
    "value": "IE"
  },
  {
    "label": "Исландия",
    "value": "IS"
  },
  {
    "label": "Испания",
    "value": "ES"
  },
  {
    "label": "Италия",
    "value": "IT"
  },
  {
    "label": "Йемен",
    "value": "YE"
  },
  {
    "label": "Кабо-Верде",
    "value": "CV"
  },
  {
    "label": "Казахстан",
    "value": "KZ"
  },
  {
    "label": "Острова Кайман",
    "value": "KY"
  },
  {
    "label": "Камбоджа",
    "value": "KH"
  },
  {
    "label": "Камерун",
    "value": "CM"
  },
  {
    "label": "Канада",
    "value": "CA"
  },
  {
    "label": "Катар",
    "value": "QA"
  },
  {
    "label": "Кения",
    "value": "KE"
  },
  {
    "label": "Кипр",
    "value": "CY"
  },
  {
    "label": "Киргизия",
    "value": "KG"
  },
  {
    "label": "Кирибати",
    "value": "KI"
  },
  {
    "label": "Китайская Республика",
    "value": "TW"
  },
  {
    "label": "КНДР (Корейская Народно-Демократическая Республика)",
    "value": "KP"
  },
  {
    "label": "Китай (Китайская Народная Республика)",
    "value": "CN"
  },
  {
    "label": "Кокосовые острова",
    "value": "CC"
  },
  {
    "label": "Колумбия",
    "value": "CO"
  },
  {
    "label": "Коморы",
    "value": "KM"
  },
  {
    "label": "Коста-Рика",
    "value": "CR"
  },
  {
    "label": "Кот-д’Ивуар",
    "value": "CI"
  },
  {
    "label": "Куба",
    "value": "CU"
  },
  {
    "label": "Кувейт",
    "value": "KW"
  },
  {
    "label": "Кюрасао",
    "value": "CW"
  },
  {
    "label": "Лаос",
    "value": "LA"
  },
  {
    "label": "Латвия",
    "value": "LV"
  },
  {
    "label": "Лесото",
    "value": "LS"
  },
  {
    "label": "Либерия",
    "value": "LR"
  },
  {
    "label": "Ливан",
    "value": "LB"
  },
  {
    "label": "Ливия",
    "value": "LY"
  },
  {
    "label": "Литва",
    "value": "LT"
  },
  {
    "label": "Лихтенштейн",
    "value": "LI"
  },
  {
    "label": "Люксембург",
    "value": "LU"
  },
  {
    "label": "Маврикий",
    "value": "MU"
  },
  {
    "label": "Мавритания",
    "value": "MR"
  },
  {
    "label": "Мадагаскар",
    "value": "MG"
  },
  {
    "label": "Майотта",
    "value": "YT"
  },
  {
    "label": "Макао",
    "value": "MO"
  },
  {
    "label": "Северная Македония",
    "value": "MK"
  },
  {
    "label": "Малави",
    "value": "MW"
  },
  {
    "label": "Малайзия",
    "value": "MY"
  },
  {
    "label": "Мали",
    "value": "ML"
  },
  {
    "label": "Мальдивы",
    "value": "MV"
  },
  {
    "label": "Мальта",
    "value": "MT"
  },
  {
    "label": "Марокко",
    "value": "MA"
  },
  {
    "label": "Мартиника",
    "value": "MQ"
  },
  {
    "label": "Маршалловы Острова",
    "value": "MH"
  },
  {
    "label": "Мексика",
    "value": "MX"
  },
  {
    "label": "Микронезия",
    "value": "FM"
  },
  {
    "label": "Мозамбик",
    "value": "MZ"
  },
  {
    "label": "Молдавия",
    "value": "MD"
  },
  {
    "label": "Монако",
    "value": "MC"
  },
  {
    "label": "Монголия",
    "value": "MN"
  },
  {
    "label": "Монтсеррат",
    "value": "MS"
  },
  {
    "label": "Мьянма",
    "value": "MM"
  },
  {
    "label": "Намибия",
    "value": "NA"
  },
  {
    "label": "Науру",
    "value": "NR"
  },
  {
    "label": "Непал",
    "value": "NP"
  },
  {
    "label": "Нигер",
    "value": "NE"
  },
  {
    "label": "Нигерия",
    "value": "NG"
  },
  {
    "label": "Нидерланды",
    "value": "NL"
  },
  {
    "label": "Никарагуа",
    "value": "NI"
  },
  {
    "label": "Ниуэ",
    "value": "NU"
  },
  {
    "label": "Новая Зеландия",
    "value": "NZ"
  },
  {
    "label": "Новая Каледония",
    "value": "NC"
  },
  {
    "label": "Норвегия",
    "value": "NO"
  },
  {
    "label": "ОАЭ",
    "value": "AE"
  },
  {
    "label": "Оман",
    "value": "OM"
  },
  {
    "label": "Остров Буве",
    "value": "BV"
  },
  {
    "label": "Остров Мэн",
    "value": "IM"
  },
  {
    "label": "Острова Кука",
    "value": "CK"
  },
  {
    "label": "Остров Норфолк",
    "value": "NF"
  },
  {
    "label": "Остров Рождества",
    "value": "CX"
  },
  {
    "label": "Острова Питкэрн",
    "value": "PN"
  },
  {
    "label": "Острова Святой Елены, Вознесения и Тристан-да-Кунья",
    "value": "SH"
  },
  {
    "label": "Пакистан",
    "value": "PK"
  },
  {
    "label": "Палау",
    "value": "PW"
  },
  {
    "label": "Государство Палестина",
    "value": "PS"
  },
  {
    "label": "Панама",
    "value": "PA"
  },
  {
    "label": "Папуа — Новая Гвинея",
    "value": "PG"
  },
  {
    "label": "Парагвай",
    "value": "PY"
  },
  {
    "label": "Перу",
    "value": "PE"
  },
  {
    "label": "Польша",
    "value": "PL"
  },
  {
    "label": "Португалия",
    "value": "PT"
  },
  {
    "label": "Пуэрто-Рико",
    "value": "PR"
  },
  {
    "label": "Республика Конго",
    "value": "CG"
  },
  {
    "label": "Республика Корея",
    "value": "KR"
  },
  {
    "label": "Реюньон",
    "value": "RE"
  },
  {
    "label": "Россия",
    "value": "RU"
  },
  {
    "label": "Руанда",
    "value": "RW"
  },
  {
    "label": "Румыния",
    "value": "RO"
  },
  {
    "label": "Сальвадор",
    "value": "SV"
  },
  {
    "label": "Самоа",
    "value": "WS"
  },
  {
    "label": "Сан-Марино",
    "value": "SM"
  },
  {
    "label": "Сан-Томе и Принсипи",
    "value": "ST"
  },
  {
    "label": "Саудовская Аравия",
    "value": "SA"
  },
  {
    "label": "Эсватини",
    "value": "SZ"
  },
  {
    "label": "Северные Марианские Острова",
    "value": "MP"
  },
  {
    "label": "Сейшельские Острова",
    "value": "SC"
  },
  {
    "label": "Сен-Бартелеми",
    "value": "BL"
  },
  {
    "label": "Сен-Мартен",
    "value": "MF"
  },
  {
    "label": "Сен-Пьер и Микелон",
    "value": "PM"
  },
  {
    "label": "Сенегал",
    "value": "SN"
  },
  {
    "label": "Сент-Винсент и Гренадины",
    "value": "VC"
  },
  {
    "label": "Сент-Китс и Невис",
    "value": "KN"
  },
  {
    "label": "Сент-Люсия",
    "value": "LC"
  },
  {
    "label": "Сербия",
    "value": "RS"
  },
  {
    "label": "Сингапур",
    "value": "SG"
  },
  {
    "label": "Синт-Мартен",
    "value": "SX"
  },
  {
    "label": "Сирия",
    "value": "SY"
  },
  {
    "label": "Словакия",
    "value": "SK"
  },
  {
    "label": "Словения",
    "value": "SI"
  },
  {
    "label": "Соломоновы Острова",
    "value": "SB"
  },
  {
    "label": "Сомали",
    "value": "SO"
  },
  {
    "label": "Судан",
    "value": "SD"
  },
  {
    "label": "Суринам",
    "value": "SR"
  },
  {
    "label": "США",
    "value": "US"
  },
  {
    "label": "Сьерра-Леоне",
    "value": "SL"
  },
  {
    "label": "Таджикистан",
    "value": "TJ"
  },
  {
    "label": "Таиланд",
    "value": "TH"
  },
  {
    "label": "Танзания",
    "value": "TZ"
  },
  {
    "label": "Теркс и Кайкос",
    "value": "TC"
  },
  {
    "label": "Того",
    "value": "TG"
  },
  {
    "label": "Токелау",
    "value": "TK"
  },
  {
    "label": "Тонга",
    "value": "TO"
  },
  {
    "label": "Тринидад и Тобаго",
    "value": "TT"
  },
  {
    "label": "Тувалу",
    "value": "TV"
  },
  {
    "label": "Тунис",
    "value": "TN"
  },
  {
    "label": "Туркмения",
    "value": "TM"
  },
  {
    "label": "Турция",
    "value": "TR"
  },
  {
    "label": "Уганда",
    "value": "UG"
  },
  {
    "label": "Узбекистан",
    "value": "UZ"
  },
  {
    "label": "Украина",
    "value": "UA"
  },
  {
    "label": "Уоллис и Футуна",
    "value": "WF"
  },
  {
    "label": "Уругвай",
    "value": "UY"
  },
  {
    "label": "Фареры",
    "value": "FO"
  },
  {
    "label": "Фиджи",
    "value": "FJ"
  },
  {
    "label": "Филиппины",
    "value": "PH"
  },
  {
    "label": "Финляндия",
    "value": "FI"
  },
  {
    "label": "Фолклендские острова",
    "value": "FK"
  },
  {
    "label": "Франция",
    "value": "FR"
  },
  {
    "label": "Французская Полинезия",
    "value": "PF"
  },
  {
    "label": "Французские Южные и Антарктические Территории",
    "value": "TF"
  },
  {
    "label": "Херд и Макдональд",
    "value": "HM"
  },
  {
    "label": "Хорватия",
    "value": "HR"
  },
  {
    "label": "ЦАР",
    "value": "CF"
  },
  {
    "label": "Чад",
    "value": "TD"
  },
  {
    "label": "Черногория",
    "value": "ME"
  },
  {
    "label": "Чехия",
    "value": "CZ"
  },
  {
    "label": "Чили",
    "value": "CL"
  },
  {
    "label": "Швейцария",
    "value": "CH"
  },
  {
    "label": "Швеция",
    "value": "SE"
  },
  {
    "label": "Флаг Шпицбергена и Ян-Майена Шпицберген и Ян-Майен",
    "value": "SJ"
  },
  {
    "label": "Шри-Ланка",
    "value": "LK"
  },
  {
    "label": "Эквадор",
    "value": "EC"
  },
  {
    "label": "Экваториальная Гвинея",
    "value": "GQ"
  },
  {
    "label": "Эритрея",
    "value": "ER"
  },
  {
    "label": "Эстония",
    "value": "EE"
  },
  {
    "label": "Эфиопия",
    "value": "ET"
  },
  {
    "label": "ЮАР",
    "value": "ZA"
  },
  {
    "label": "Южная Георгия и Южные Сандвичевы Острова",
    "value": "GS"
  },
  {
    "label": "Южный Судан",
    "value": "SS"
  },
  {
    "label": "Ямайка",
    "value": "JM"
  },
  {
    "label": "Япония",
    "value": "JP"
  }
];

export default {
  name: "create_document_upd",
  components: {
  },
  data() {
    this.emitter.on('didCreateNewDocument', (data) => {
      console.log(data);
      this.$q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Документ не был создан',
          cancel: false,
          persistent: true
        }).onOk(() => {
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        this.$q.notify('Документ был создан');
        this.$router.push({ name: 'documents_list', params: {} });
      }
    })
    this.emitter.on('didReceiveNewDocumentNumber', (data) => {
      this.in_org_number = data.number;
      this.step = 2;
    });
    this.emitter.on('didReceiveAgentInfoByInn', (data) => {
      console.log(data);
      if (data.request_id === 'seller_inn') {
        this.seller_adr = data.info.address.unrestricted_value;
        this.seller = data.info.name.short_with_opf;
      }
      if (data.request_id === 'cust_inn') {
        this.cust_adr = data.info.address.unrestricted_value;
        this.cust = data.info.name.short_with_opf;
      }
    })
    if (this.connection.isAuthenticated) {
      this.connection.getNewDocumentNumber(
        nanoid(),
        this.$store.state.current_org_id,
        'upd'
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getNewDocumentNumber(
          nanoid(),
          this.$store.state.current_org_id,
          'upd'
        );
      })
    }
    return {
      step: ref(1),
      templates_list_options: ref([]),
      document_type: ref(null),
      top_section: ref([]),
      table_section: ref([]),
      bottom_section: ref([]),
      bottom_section_left: ref([]),
      bottom_section_right: ref([]),
      table_raw_columns: ref([]),
      table_columns: ref([]),
      table_sections: ref([]),
      table_rows: ref([{
        code: '',
        id: 1,
        uuid: nanoid(),
        name: '',
        kind_code: '',
        measure_value: '',
        measure_label: '',
        count: '',
        price: '',
        cost_without_nds: '',
        aciz: '',
        nds: '',
        nalog: '',
        summtotal: '',
        contrycode: '',
        countryname: '',
        doc: '',
      }]),
      proto_values: ref({}),
      temporary_values: ref({}),
      template_id: ref(null),
      in_org_number: ref(null),
      document_direction_options: document_direction_options,
      document_direction: ref(null),
      document_date_proxy: ref(moment().format("YYYY/MM/DD")),
      document_date: ref(moment().format("YYYY/MM/DD")),
      document_date_unix: ref(moment().unix()),
      bills_list_options: ref([]),
      position_transfers: ref([]),
      document_id: ref(null),
      all_transfers_done: ref(false),
      nds_percentage_options: nds_percentage_options,
      users_list_options: ref([]),
      current_contragent_field: ref(''),
      count_measures_options: count_measures_options,
      country_options: ref(country_options),
      original_positions_list_options: ref([]),
      transfers_table_columns: ref(transfers_table_columns),
      summary: ref({ nalog: 0, summtotal: 0 }),
      cust: ref(null),
      cust_adr: ref(null),
      cust_inn: ref(null),
      currency: ref(null),
      goscon: ref(null),
      seller: ref(null),
      seller_adr: ref(null),
      seller_inn: ref(null),
      seller_sender: ref(null),
      seller_receiver: ref(null),
      seller_doc: ref(null),
      seller_send_doc: ref(null),
      change_n: ref(null),
      change_date: ref(null),
      code: ref(null),
      date: ref(null),
      kind_code: ref(null),
      status: ref(1),
      rukvod: ref(null),
      glavbuh: ref(null),
      ip_name: ref(null),
      ip_req: ref(null),
      osn_rec_sent: ref(null),
      transp: ref(null),
      goods_sent: ref(null),
      goods_sent_workplace: ref(null),
      goods_sent_fio: ref(null),
      date_sent: ref(null),
      sent_info: ref(null),
      hozvod_workplace: ref(null),
      hozvod_fio: ref(null),
      econom_name: ref(null),
      date_rec: ref(null),
      goods_rec_fio: ref(null),
      goods_rec_workplace: ref(null),
      rec_info: ref(null),
      econom_rec_name: ref(null),
      hozvod_rec_fio: ref(null),
      hozvod_rec_workplace: ref(null)
    }
  },
  methods: {
    checkAgentBy(inn_val, field_id) {
      const inn = inn_val.replace(/\s/g, "");
      console.log(inn, field_id)
      if (inn.length < 9) {
        return;
      }
      this.connection.getAgentInfoByInn(
        field_id,
        inn
      );
    },
    addTableRow() {
      const item = {
        code: '',
        id: this.table_rows.length + 1,
        uuid: nanoid(),
        name: '',
        kind_code: '',
        measure_value: '',
        measure_label: '',
        count: '',
        price: '',
        cost_without_nds: '',
        aciz: '',
        nds: '',
        nalog: '',
        summtotal: '',
        contrycode: '',
        countryname: '',
        doc: '',
      }
      this.table_rows.push(item);
    },
    onCountDataChanged(count_raw, row_id) {
      const row = this.table_rows[row_id];
      console.log(row);
      const price = parseFloat(this.table_rows[row_id].price) || 0;
      const count = parseFloat(count_raw) || 0;
      const nds = parseFloat(this.table_rows[row_id].nds.value) || 0;
      const aciz = parseFloat(this.table_rows[row_id].aciz) || 0;
      console.log(nds, count, price);
      if (price == 0) {
        this.table_rows[row_id].cost_without_nds = 0;
        this.table_rows[row_id].summtotal = 0;
        this.table_rows[row_id].nalog = 0;
        this.onSummaryChanged(0);
      } else {
        const nds_value = price * nds;
        console.log(nds_value);
        this.table_rows[row_id].cost_without_nds = price * count;
        this.table_rows[row_id].nalog = nds_value * count + aciz;
        this.table_rows[row_id].summtotal = (price + nds_value) * count + aciz;
        this.onSummaryChanged(0);
      }
    },

    onPriceDataChanged(price_raw, row_id) {
      const row = this.table_rows[row_id];
      console.log(row);
      const price = parseFloat(price_raw) || 0;
      const count = parseFloat(this.table_rows[row_id].count) || 0;
      const nds = parseFloat(this.table_rows[row_id].nds.value) || 0;
      const aciz = parseFloat(this.table_rows[row_id].aciz) || 0;
      console.log(nds, count, price);
      if (price == 0) {
        this.table_rows[row_id].cost_without_nds = 0;
        this.table_rows[row_id].summtotal = 0;
        this.table_rows[row_id].nalog = 0;
        this.onSummaryChanged(0);
      } else {
        const nds_value = price * nds;
        console.log(nds_value);
        this.table_rows[row_id].cost_without_nds = price * count;
        this.table_rows[row_id].nalog = nds_value * count + aciz;
        this.table_rows[row_id].summtotal = (price + nds_value) * count + aciz;
        this.onSummaryChanged(0);
      }
    },

    onNDSDataChanged(nds_raw, row_id) {
      const row = this.table_rows[row_id];
      console.log(row);
      const price = parseFloat(this.table_rows[row_id].price) || 0;
      const count = parseFloat(this.table_rows[row_id].count) || 0;
      const nds = parseFloat(nds_raw) || 0;
      const aciz = parseFloat(this.table_rows[row_id].aciz) || 0;
      console.log(nds, count, price);
      if (price == 0) {
        this.table_rows[row_id].cost_without_nds = 0;
        this.table_rows[row_id].summtotal = 0;
        this.table_rows[row_id].nalog = 0;
        this.onSummaryChanged(0);
      } else {
        const nds_value = price * nds;
        console.log(nds_value);
        this.table_rows[row_id].cost_without_nds = price * count;
        this.table_rows[row_id].nalog = nds_value * count + aciz;
        this.table_rows[row_id].summtotal = (price + nds_value) * count + aciz;
        this.onSummaryChanged(0);
      }
    },

    onAcizDataChanged(aciz_raw, row_id) {
      const row = this.table_rows[row_id];
      console.log(row);
      const price = parseFloat(this.table_rows[row_id].price) || 0;
      const count = parseFloat(this.table_rows[row_id].count) || 0;
      const nds = parseFloat(this.table_rows[row_id].nds.value) || 0;
      const aciz = parseFloat(aciz_raw) || 0;
      console.log(nds, count, price);
      if (price == 0) {
        this.table_rows[row_id].cost_without_nds = 0;
        this.table_rows[row_id].summtotal = 0;
        this.table_rows[row_id].nalog = 0;
        this.onSummaryChanged(0);
      } else {
        const nds_value = price * nds;
        console.log(nds_value);
        this.table_rows[row_id].cost_without_nds = price * count;
        this.table_rows[row_id].nalog = nds_value * count + aciz;
        this.table_rows[row_id].summtotal = (price + nds_value) * count + aciz;
        this.onSummaryChanged(0);
      }
    },
    onRowDataChanged(row_index, field_id) {
      console.log(row_index, field_id);
      const row = this.table_rows[row_index];
      console.log(row);
      if (field_id === 'count') {
        this.table_rows[row_index].cost_without_nds = (parseFloat(row.price) || 0) * (parseFloat(row.count) || 0);
        this.table_rows[row_index].summtotal = (parseFloat(row.price) || 0) + ((parseFloat(row.price) || 0) * (parseFloat(row.nds.value) || 0)) * (parseFloat(row.count) || 0) + (parseFloat(row.aciz) || 0);
        this.table_rows[row_index].nalog = ((parseFloat(row.price) || 0) * (parseFloat(row.nds.value) || 0)) * (parseFloat(row.count) || 0) + (parseFloat(row.aciz) || 0);
      } else if (field_id === 'price') {
        this.table_rows[row_index].cost_without_nds = (parseFloat(row.price) || 0) * (parseFloat(row.count) || 0);
        this.table_rows[row_index].summtotal = (parseFloat(row.price) || 0) + ((parseFloat(row.price) || 0) * (parseFloat(row.nds.value) || 0)) * (parseFloat(row.count) || 0) + (parseFloat(row.aciz) || 0);
        this.table_rows[row_index].nalog = ((parseFloat(row.price) || 0) * (parseFloat(row.nds.value) || 0)) * (parseFloat(row.count) || 0) + (parseFloat(row.aciz) || 0);
      } else if (field_id === 'cost_with_nds') {
        this.table_rows[row_index].cost_without_nds = (parseFloat(row.price) || 0) * (parseFloat(row.count) || 0);
        this.table_rows[row_index].summtotal = (parseFloat(row.price) || 0) + ((parseFloat(row.price) || 0) * (parseFloat(row.nds.value) || 0)) * (parseFloat(row.count) || 0) + (parseFloat(row.aciz) || 0);
        this.table_rows[row_index].nalog = ((parseFloat(row.price) || 0) * (parseFloat(row.nds.value) || 0)) * (parseFloat(row.count) || 0) + (parseFloat(row.aciz) || 0);
      } else if (field_id === 'aciz') {
        this.table_rows[row_index].cost_without_nds = (parseFloat(row.price) || 0) * (parseFloat(row.count) || 0);
        this.table_rows[row_index].summtotal = (parseFloat(row.price) || 0) + ((parseFloat(row.price) || 0) * (parseFloat(row.nds.value) || 0)) * (parseFloat(row.count) || 0) + (parseFloat(row.aciz) || 0);
        this.table_rows[row_index].nalog = ((parseFloat(row.price) || 0) * (parseFloat(row.nds.value) || 0)) * (parseFloat(row.count) || 0) + (parseFloat(row.aciz) || 0);
      } else if (field_id === 'nds') {
        this.table_rows[row_index].cost_without_nds = (parseFloat(row.price) || 0) * (parseFloat(row.count) || 0);
        this.table_rows[row_index].summtotal = (parseFloat(row.price) || 0) + ((parseFloat(row.price) || 0) * (parseFloat(row.nds.value) || 0)) * (parseFloat(row.count) || 0) + (parseFloat(row.aciz) || 0);
        this.table_rows[row_index].nalog = ((parseFloat(row.price) || 0) * (parseFloat(row.nds.value) || 0)) * (parseFloat(row.count) || 0) + (parseFloat(row.aciz) || 0);
      }
      // else if (field_id === 'nalog') {
      //   // con
      // } else if (field_id === 'summtotal') {
      //   this.table_rows[row_index][field_id] = value;
      // }
      // count: '',
      //   price: '',
      //   cost_with_nds: '',
      //   aciz: '',
      //   nds: '',
      //   nalog: '',
      //   summtotal: '',
      this.onSummaryChanged(0);
    },
    onSummaryChanged(value) {
      console.log(value);
      this.summary.nalog = 0;
      this.summary.summtotal = 0;
      this.table_rows.forEach(row => {
        this.summary.summtotal += row.summtotal;
        this.summary.nalog += row.nalog;
      })
    },
    onSave() {
      this.$q.loading.show();
      const body = {
        summary: this.summary,
        cust: this.cust,
        cust_adr: this.cust_adr,
        cust_inn: this.cust_inn,
        currency: this.currency,
        goscon: this.goscon,
        seller: this.seller,
        seller_adr: this.seller_adr,
        seller_inn: this.seller_inn,
        seller_sender: this.seller_sender,
        seller_receiver: this.seller_receiver,
        seller_doc: this.seller_doc,
        seller_send_doc: this.seller_send_doc,
        change_n: this.change_n,
        change_date: this.change_date,
        code: this.code,
        date: this.date,
        kind_code: this.kind_code,
        status: this.status,
        rukvod: this.rukvod,
        glavbuh: this.glavbuh,
        ip_name: this.ip_name,
        ip_req: this.ip_req,
        osn_rec_sent: this.osn_rec_sent,
        transp: this.transp,
        goods_sent: this.goods_sent,
        goods_sent_workplace: this.goods_sent_workplace,
        goods_sent_fio: this.goods_sent_fio,
        date_sent: this.date_sent,
        sent_info: this.sent_info,
        hozvod_workplace: this.hozvod_workplace,
        hozvod_fio: this.hozvod_fio,
        econom_name: this.econom_name,
        date_rec: this.date_rec,
        goods_rec_fio: this.goods_rec_fio,
        goods_rec_workplace: this.goods_rec_workplace,
        rec_info: this.rec_info,
        econom_rec_name: this.econom_rec_name,
        hozvod_rec_fio: this.hozvod_rec_fio,
        hozvod_rec_workplace: this.hozvod_rec_workplace,
        table_rows: this.table_rows,
        in_org_number: this.in_org_number,
        document_date: moment(this.document_date).unix(),
        document_type: 'upd'
      };
      this.connection.createNewDocument(
        nanoid(),
        this.$store.state.current_org_id,
        body
      )
    },
    deleteRow(row_id) {
      this.table_rows = this.table_rows.filter((row) => row.id != row_id);
    }
  }
}
</script>

<style scoped>
@page {
  /*size: 210mm 297mm; */
  size: 297mm 210mm;
  /* Chrome sets own margins, we change these printer settings */
  margin: 10mm 10mm 10mm 10mm;
}

body {
  margin: 0px;
  width: 100%;
  height: 100%;
  background: #FFF;
}

@media screen {
  body {
    padding: 30px;
  }
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
td,
th {
  font-family: sans-serif;
  font-weight: normal;
  color: #000;
}

body .upd,
.upd td,
.upd th {
  font-size: 10px;
  vertical-align: top;
}

.bold {
  font-weight: bold;
}

a {
  color: #000;
  text-decoration: underline !important;
}

a img {
  border: none;
}

img.full_width {
  width: 100%;
  height: auto;
}

h1,
h2,
h3 {
  font-weight: bold;
}

body,
td,
th {
  font-size: 14px;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 16px;
}

.gap {
  height: 30px;
}

.nowrap {
  white-space: nowrap;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
}

.clearfix {
  overflow: hidden;
}

.vertical_centered_content {
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center;
}

.vertical_centered_content>.inner {
  display: inline;
  width: 100%;
}

.horizontal_centered_content {
  display: -webkit-flex;
  -webkit-justify-content: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.horizontal_centered_content>.inner {
  display: inline;
  width: 100%;
}

@media screen {
  .doc.landscape {
    width: 1080px;
  }

  .doc.portrait {
    width: 720px;
  }
}

.updorderlist,
.updorderlist td {
  border: 1px solid #000;
  border-collapse: collapse;
}

.updskeleton,
.updskeleton>tbody>tr>td {
  border: 1px solid #FFF;
}

table {
  border-collapse: collapse;
}
</style>