<template>
  <div class="row">
    <div class="q-pa-md">
      <finance-breadcrumb label="Учетная политика"/>
      <div class="q-pa-md">

      </div>
    </div>
  </div>
</template>

<script>
import FinanceBreadcrumb from '../../components/bills/finance_breadcrumb'
export default {
  name: "finance_policy",
  components: {
    FinanceBreadcrumb,
  }
}
</script>

<style scoped>

</style>