<template>
  <div class="row">
    <div class="q-pa-md">
      <finance-breadcrumb label="Начальные данные"/>
      <div class="q-pa-md">
        <q-stepper
            flat
            v-model="step"
            vertical
            color="primary"
            animated
        >
          <q-step
              :name="1"
              title="Система налогообложения"
              icon="create_new_folder"
              :done="step > 2"
          >

            <div class="row">
              <div class="col-9">
                <div class="q-pa-md">
                  Выберите систему налогообложения
                </div>
                <div class="q-pa-md">
                  <q-list>
                    <q-item v-for="item in nalog_systems_choices" v-bind:key="item.id" tag="label" v-ripple>
                      <q-item-section avatar top>
                        <q-radio v-model="nalog" :val="item.value" color="cyan" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{ item.name }}</q-item-label>
                        <q-item-label caption>{{ item.description }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </div>
            </div>

            <q-stepper-navigation>
              <q-btn @click="onFirstStepContinue" color="primary" label="Далее" />
            </q-stepper-navigation>
          </q-step>


          <q-step
              :name="2"
              title="Финансовые данные"
              icon="add_comment"
          >
            <div class="row">
              <div class="col-12">

                <div class="q-pa-md">
                  Укажите текущее остояние счетов на момент внедрения системы
                </div>
                <div class="q-pa-md">
                  <q-table
                      title=""
                      :rows="bills_table_rows"
                      :columns="bills_table_columns"
                      flat
                      separator="cell"
                      v-model="bills_table_rows"
                  >
                    <template v-slot:header>
                      <q-tr>
                        <q-th colspan="1" />
                        <q-th colspan="2">
                          Сальдо на наало периода
                        </q-th>
                        <q-th />
                      </q-tr>
                      <q-tr>
                        <q-th>№</q-th>
                        <q-th>Счет</q-th>
                        <q-th>Субсчет</q-th>
                        <q-th>Описание</q-th>
                        <q-th>Дебет</q-th>
                        <q-th>Кредит</q-th>
                        <q-th></q-th>
                      </q-tr>
<!--                      {{ props.cols[0].bill }}-->
<!--                      <q-tr :props="props">-->
<!--                        <q-th-->
<!--                            v-for="col in props.cols"-->
<!--                            v-bind:key="col.bill"-->
<!--                            :props="props"-->
<!--                        >-->
<!--                          {{ col.label }}-->
<!--                        </q-th>-->
<!--                        <q-th />-->
<!--                      </q-tr>-->
                    </template>
                    <template v-slot:bottom-row>
                      <q-tr>
                        <q-th></q-th>
                        <q-th><q-select dense v-model="new_initial_bill_name" :options="initial_bill_names"></q-select></q-th>
                        <q-th><q-input dense v-model="new_subbill_name"/></q-th>
                        <q-th><q-input dense v-model="new_description"/></q-th>
                        <q-th><q-input dense v-model="new_initial_bill_start_debet"/></q-th>
                        <q-th><q-input dense v-model="new_initial_bill_start_credit"/></q-th>
                        <q-td auto-width>
                          <q-btn size="sm" color="white" text-color="primary" flat label="Добавить" @click="add_initial_bill" />
                        </q-td>
                      </q-tr>
                    </template>
                  </q-table>
                </div>
              </div>
            </div>

            <q-stepper-navigation>
              <q-btn color="primary" @click="saveInitialBills" label="Сохранить" />
              <q-btn flat @click="step = 1" color="primary" label="Назад" class="q-ml-sm" />
            </q-stepper-navigation>
          </q-step>
        </q-stepper>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceBreadcrumb from '../../components/bills/finance_breadcrumb'
import {ref} from 'vue'
import moment from "moment";

const bills_table_columns = [
  { name: 'id', align: 'left', label: 'Счет', field: 'id', sortable: false},
  { name: 'bill', align: 'left', label: 'Счет', field: 'bill', sortable: false},
  { name: 'subbill_name', align: 'left', label: 'Субсчет', sortable: false, field: 'subbill_name'},
  { name: 'description', align: 'left', label: 'Описание', sortable: false, field: 'description'},
  { name: 'debet_start', align: 'left', label: 'Дебет', sortable: false, field: 'debet_start'},
  { name: 'credit_start', align: 'left', label: 'Кредит', sortable: false, field: 'credit_start'},
];

const org_reg_choices_dict = [
  {
    value: 'ip',
    label: 'Индивидуальный предприниматель',
  },
  {
    value: 'ooo',
    label: 'Общество с ограниченной ответственностью',
  },
];

const nalog_systems_choices = [
  {
    id: 1,
    value: 'osn',
    name: 'ОСН',
    description: 'Общий налоговый режим является основным и применяется по умолчанию, если индивидуальный предприниматель не подал в налоговый орган заявление о переходе на один из специальных налоговых режимов.'
  },
  {
    id: 2,
    value: 'usn_dohod',
    name: 'УСН доходы',
    description: 'УСН доходы – это объекты, учитываемые в упрощенной системе налогообложения – особом порядке уплаты налогов, ориентированном на представителей малого и среднего бизнеса. В качестве объекта выбираются доходы. Налоговая ставка состовляет 6%.'
  },
  {
    id: 3,
    value: 'usn_dohod_rashod',
    name: 'УСН доходы-расходы',
    description: 'УСН доходы – это объекты, учитываемые в упрощенной системе налогообложения – особом порядке уплаты налогов, ориентированном на представителей малого и среднего бизнеса. В качестве объекта выбираются доходы, уменьшенные на величину произведенных расходов. Налоговая ставка состовляет 15%.'
  }
];

export default {
  name: "bills_startup_master",
  components: {
    FinanceBreadcrumb,
  },
  setup() {
    return {
      step: ref(1),
      org_reg_choices: org_reg_choices_dict.map(function (x) { return x.label } ),
      nalog_systems_choices,
    }
  },
  mounted() {
    this.emitter.on('didReceiveFinanceSettings', (data) => {
      console.log(data);
      this.nalog = data.settings.tax_reglament;
      this.loading = false;
    });
    this.emitter.on('didReceiveBillsList', (data) => {
      console.log(data);
      this.initial_bill_names = data.bills.map(function (x) { return x.bill; })
    });
  },
  data() {
    if (this.connection.isAuthenticated) {
      this.connection.getFinanceSettings('1', this.$store.state.current_org_id);
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getFinanceSettings('1', this.$store.state.current_org_id);
      });
    }
    return {
      bills_table_columns: bills_table_columns,
      bills_table_rows: ref([]),
      initial_bill_names: ref([]),
      is_loading: ref(true),
      nalog: ref('osn'),
      new_initial_bill_name: ref('01'),
      new_subbill_name: ref(''),
      new_description: ref(''),
      new_initial_bill_start_debet: ref(0),
      new_initial_bill_start_credit: ref(0)
    }
  },
  methods: {
    onFirstStepContinue() {
      const data = {
        tax_reglament: this.nalog,
        finance_policy: 'default'
      };
      this.loading = true;
      this.$q.loading.show({message: 'Сохранение данных'});
      this.connection.updateFinanceSettings('1', this.$store.state.current_org_id, data);
      const period_start = moment().quarter(moment().quarter()).startOf('quarter');
      const period_end = moment();
      const period_start_unix = period_start.unix();
      const period_end_unix = period_end.unix();
      this.connection.getBillsList(
          '1',
          this.$store.state.current_org_id,
          period_start_unix,
          period_end_unix
      );
      this.emitter.on('didUpdateFinanceSettings', (data) => {
        console.log(data);
        this.nalog = data.settings.tax_reglament;
        this.loading = false;
        this.step = 2;
        this.$q.loading.hide();
      });
    },
    add_initial_bill() {
      const new_item = {
        id: 1,
        bill: this.new_initial_bill_name,
        subbill_name: this.new_subbill_name,
        description: this.new_description,
        debet_start: this.new_initial_bill_start_debet,
        credit_start: this.new_initial_bill_start_credit
      };
      this.bills_table_rows.push(new_item);
      this.new_initial_bill_name = '01';
      this.new_subbill_name = '';
      this.new_description = ''
      this.new_initial_bill_start_debet = 0;
      this.new_initial_bill_start_credit = 0;
    },
    saveInitialBills() {
      this.loading = true;
      this.$q.loading.show({message: 'Сохранение данных'});
      this.connection.saveInitialBillsData('1', this.$store.state.current_org_id, this.bills_table_rows);
      this.emitter.on('didSaveInitialBillsData', (data) => {
        console.log(data);
        this.loading = false;
        this.$q.loading.hide();
        this.$router.push({ name: 'bills_plan', params: {} });
      })
    }
  }

}
</script>

<style scoped>

</style>










































