<template>
  <div class="text-h6">
    Шаблон проводки для счет-фактуры
  </div>
  <div style="width: 500px">
    <div class="q-pa-md">
      <q-input v-model="name" label="Название" />
    </div>
    <div class="q-pa-md">
      <q-select v-model="direction" :options="directions" label="Направление" />
    </div>
    <div class="q-pa-md">
      <q-select v-model="field_id" :options="fields" label="Поле" />
    </div>
    <div class="q-pa-md">
      <q-select v-model="bill_from" :options="bills" label="Счет списания" />
    </div>
    <div class="q-pa-md">
      <q-select v-model="bill_to" :options="bills" label="Счет зачисления" />
    </div>
    <div class="q-pa-md">
      <q-input v-model="comment" label="Комментарий" />
    </div>
    <div class="q-pa-md">
      <q-btn label="Сохранить" @click="addTemplate" color="primary" />
    </div>
  </div>
</template>
  
  <script>
  import {ref} from "vue";
  
  const directions = [
    { value: 'in', label: 'Входящий документ' },
    { value: 'out', label: 'Исходящий документ' }
  ]
  
  const fields = [
    { value: 'summtotal', label: 'Сумма всего' },
    { value: 'nalog', label: 'Сумма налога' },
  ];
  
  export default {
    name: "transfer_templates_avance_new",
    data() {
  
      this.emitter.on('didReceiveBillListFlatCompact', (data) => {
        this.bills = data.bills.map((element) => {
          return {label: element.full_name, value: element.id}
        });
        this.bill_from = this.bills[0];
        this.bill_to = this.bills[1];
      });
  
      this.emitter.on('onCreateTransferTemplate', (response) => {
        console.log(response);
        this.$q.loading.hide();
        this.$router.back()
      });
      if (this.connection.isAuthenticated) {

        this.connection.gitBillsListFlatCompact(
          '1',
          this.$store.state.current_org_id,
        );
      } else {
        this.emitter.on('connectionReady', () => {
          this.connection.gitBillsListFlatCompact(
            '1',
            this.$store.state.current_org_id,
          );
        })
      }
      
      return {
        name: ref(null),
        field_id: ref({ value: 'summtotal', label: 'Сумма всего' }),
        bill_from: ref(null),
        bill_to: ref(null),
        direction: ref({ value: 'in', label: 'Входящий документ' }),
        directions: ref(directions),
        comment: ref(null),
        fields: ref(fields),
        bills: ref([])
      }
    },
    methods: {
      addTemplate() {
        this.$q.loading.show();
        this.connection.createTransferTemplate(
          '1',
          this.$store.state.current_org_id,
          this.name, 
          this.field_id.value, 
          this.bill_from.value, 
          this.bill_to.value, 
          this.direction.value,
          this.comment,
          'avance'
        );
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>