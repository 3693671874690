<template>
  <div class="">
    <q-btn @click="onPrintButtonClick" unelevated color="white" icon-right="download" text-color="primary"
      label="Печать" />
    <q-btn @click="onEditDocument" unelevated color="white" icon-right="edit" text-color="primary"
      label="Проводки и редактирование" />
    <q-btn v-if="is_active" @click="onDeleteDocument" unelevated color="white" icon-right="delete" text-color="negative"
      label="Удалить" />
    <q-btn v-else @click="onRestoreDocument" unelevated color="white" icon-right="history" text-color="primary"
      label="Восстановить" />
  </div>
  <div class="doc print_doc" id="print_content">
    <div>
      <div style="width: 100%; text-align: right; font-size: 6pt;">
        Приложение N 1 к постановлению Правительства Российской Федерации от 26 декабря 2011 г. N 1137<br />(в ред.
        Постановления Правительства РФ от 19.08.2017 No 981)
      </div>
      <div style="font-size: 10pt; line-height: 1.5;">
        <div style="font-weight: bold;">
          Счет-фактура № {{ body.in_org_number || "" }} от {{ formatDate(body.document_date || 0) }}
        </div>
        <div>
          Исправление № {{ body.change_number || "--" }} от {{ body.document_change_date || "--" }}
        </div>
      </div>
      <div style="font-size: 7pt; line-height: 1; margin-bottom: 3mm;">
        <div>
          Продавец: {{ body.seller || "" }}
        </div>
        <div>
          Адрес: {{ body.seller_adr || "" }}
        </div>
        <div>
          ИНН / КПП продавца: {{ body.seller_inn || "" }}
        </div>
        <div>
          Грузоотправитель и его адрес: -
        </div>
        <div>
          Грузополучатель и его адрес: -
        </div>
        <div>
          К платежно-расчетному документу: {{ body.plat_ras || "" }}
        </div>
        <div>
          Покупатель: {{ body.cust || "" }}
        </div>
        <div>
          Адрес: {{ body.cust_adr || "" }}
        </div>
        <div>
          ИНН / КПП покупателя: {{ body.cust_inn || "" }}
        </div>
        <div>
          Валюта: наименование, код: {{ body.currency || "" }}
        </div>
        <div>
          Идентификатор государственного контракта, договора (соглашения) (при наличии): {{ body.goscon || "" }}
        </div>
      </div>
    </div>

    <table class="main-table" cellspacing="0" cellpadding="3"
      style="font-size:7pt; line-height: 1; text-align: center;">
      <tbody>
        <tr>
          <td style="width: 60mm;" rowspan="2">Наименование товара (описание выполненных работ, оказанных услуг),
            имущественного права</td>
          <td rowspan="2" style="width: 15mm;">Код вида товара</td>
          <td colspan="2" style="width: 30mm;">Единица измерения</td>
          <td rowspan="2" style="width: 15mm;">Количество (объём)</td>
          <td rowspan="2" style="width: 15mm;">Цена (тариф) за единицу измерения</td>
          <td rowspan="2" style="width: 20mm;">Стоимость товаров (работ, услуг), имущественных прав без налога — всего
          </td>
          <td rowspan="2" style="width: 10mm;">В том числе сумма акциза</td>
          <td rowspan="2" style="width: 15mm;">Налоговая ставка</td>
          <td rowspan="2" style="width: 15mm;">Сумма налога, предъявляемая покупателю</td>
          <td rowspan="2" style="width: 20mm;">Стоимость товаров (работ, услуг), имущественных прав с налогом — всего
          </td>
          <td colspan="2" style="width: 30mm;">Страна происхождения товара</td>
          <td rowspan="2" style="width: 30mm;">Регистрационный номер декларации</td>
        </tr>
        <tr>
          <td style="width: 10mm;">Код</td>
          <td style="width: 20mm;">Условное обозначение (национальное)</td>
          <td style="width: 10mm;">Цифровой код</td>
          <td style="width: 20mm; border-right: none;">Краткое наименование</td>
        </tr>
        <tr>
          <td style="text-align: center; font-size: 6pt">1</td>
          <td style="text-align: center; font-size: 6pt">1а</td>
          <td style="text-align: center; font-size: 6pt">2</td>
          <td style="text-align: center; font-size: 6pt">2а</td>
          <td style="text-align: center; font-size: 6pt">3</td>
          <td style="text-align: center; font-size: 6pt">4</td>
          <td style="text-align: center; font-size: 6pt">5</td>
          <td style="text-align: center; font-size: 6pt">6</td>
          <td style="text-align: center; font-size: 6pt">7</td>
          <td style="text-align: center; font-size: 6pt">8</td>
          <td style="text-align: center; font-size: 6pt">9</td>
          <td style="text-align: center; font-size: 6pt">10</td>
          <td style="text-align: center; font-size: 6pt">10а</td>
          <td style="text-align: center; font-size: 6pt">11</td>
        </tr>
        <tr v-for="item in body.table_rows" v-bind:key="item.id">
          <td style="text-align: left;">{{ item.name }}</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td style="text-align: right;">-</td>
          <td style="text-align: right;">-</td>
          <td style="text-align: right;">-</td>
          <td style="text-align: right;">-</td>
          <td style="text-align: right;">{{ item.nds.label || "" }} </td>
          <td style="text-align: right;">{{ Math.round(((item || { nalog: 0 }).nalog) * 100) / 100 || "" }} </td>
          <td style="text-align: right;">{{ Math.round(((item || { summtotal: 0 }).summtotal) * 100) / 100 || "" }}</td>
          <td style="border-bottom: 0.4mm solid black !important;">-</td>
          <td style="border-bottom: 0.4mm solid black !important;">-</td>
          <td style="text-align: left; border-bottom: 0.4mm solid black !important;">-</td>
        </tr>
        <tr style="border-bottom: transparent !important; border-right: transparent !important;">

          <td colspan="6" style="font-weight: bold; text-align: left;">Всего к оплате</td>
          <td style="text-align: right;">-</td>
          <td colspan="2">X</td>
          <td style="text-align: right;">{{ Math.round((body.summary || { nalog: 0 }).nalog * 100) / 100 }}</td>
          <td style="text-align: right;">{{ Math.round((body.summary || { summtotal: 0 }).summtotal * 100) / 100 }}</td>
          <td colspan="3" style="border: 0px solid white; border-left: 0.4mm solid black !important;"></td>
        </tr>
      </tbody>
    </table>

    <div style=" height: 10mm; margin-top: 3mm; vertical-align: top; font-size: 7pt;">
      <div style="display: inline-block; width: 40mm; height: 10mm;">
        Руководитель организации или иное уполномоченное лицо
      </div>
      <div style="display: inline-block; width: 35mm; margin-left: 3mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">

        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          Подпись
        </div>
      </div>
      <div style="display: inline-block; width: 50mm; margin-left: 7mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">
          {{ body.rukvod || "" }}
        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          (Ф.И.О.)
        </div>
      </div>
      <div style="display: inline-block; width: 35mm; height: 10mm; margin-left: 8mm;">
        Главный бухгалтер или иное уполномоченное лицо
      </div>
      <div style="display: inline-block; width: 35mm; margin-left: 3mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">

        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          Подпись
        </div>
      </div>
      <div style="display: inline-block; width: 53mm; margin-left: 7mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">
          {{ body.glavbuh || "" }}
        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          (Ф.И.О.)
        </div>
      </div>
    </div>
    <div style="height: 10mm; margin-top: 5mm; font-size: 7pt;">
      <div style="display: inline-block; width: 40mm; height: 10mm;">
        Индивидуальный предприниматель или иное уполномоченное лицо
      </div>
      <div style="display: inline-block; width: 35mm; margin-left: 3mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">

        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          Подпись
        </div>
      </div>
      <div style="display: inline-block; width: 50mm; margin-left: 7mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">
          {{ body.ip_name || "" }}
        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          (Ф.И.О.)
        </div>
      </div>

      <div style="display: inline-block; width: 133mm; margin-left: 8mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">
          {{ body.ip_req || "" }}
        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          (реквизиты свидетельства о государственной регистрации индивидуального предпринимателя)
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { ref } from "vue";
import moment from "moment";




const style = "<style>" +
  "@page {size: 297mm 210mm; margin: 10mm 10mm 10mm 10mm;}" +
  "body {margin: 0px;  width: 100%;  height: 100%;  background: #FFF;}" +
  ".print_doc {font-family: 'Times New Roman', Times, serif; font-size: 7pt;}" +
  ".main-table td { border: 0.3mm solid black;}" +
  "</style>";

export default {
  name: "document_info_factura_avance",
  data() {
    this.emitter.on('onDeleteDocument', (response) => {
      console.log(response);
      this.connection.getDocumentInfo(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id,
        'last'
      );
      this.connection.getDocumentInfoShort(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id
      );
    });

    this.emitter.on('onRestoreDocument', (response) => {
      console.log(response);
      this.connection.getDocumentInfo(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id,
        'last'
      );
      this.connection.getDocumentInfoShort(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id
      );
    });
    this.emitter.on('didReceiveDocumentInfo', (response) => {
      this.name = response.data.name;
      this.body = response.data.body;
      this.is_active = response.data.is_active;
    });
    if (this.connection.isAuthenticated) {
      this.connection.getDocumentInfo(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id,
        this.$route.params.history_id
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getDocumentInfo(
          '1',
          this.$store.state.current_org_id,
          this.$route.params.id,
          this.$route.params.history_id
        );
      })
    }
    return {
      name: ref(''),
      body: ref({}),
      document_id: ref(this.$route.params.id),
      is_active: ref(true),
    }
  },
  methods: {
    formatDate(date) {
      return moment.unix(date).format('DD.MM.YYYY');
    },
    onPrintButtonClick() {
      let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

      mywindow.document.write(`<html><head><title></title>`);
      mywindow.document.write('</head><body >' + style);
      mywindow.document.write(document.getElementById('print_content').innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      return true;
    },
    onEditDocument() {
      this.$router.push({ name: 'document_edit_factura_avance', params: { id: this.$route.params.id } });
    },
    onDeleteDocument() {
      this.connection.deleteDocument(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id
      )
    },
    onRestoreDocument() {
      this.connection.restoreDocument(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id
      )
    }
  }
}
</script>

<style scoped>
@page {
  size: 297mm 210mm;
  margin: 10mm 10mm 10mm 10mm;
}

body {
  margin: 0px;
  width: 100%;
  height: 100%;
  background: #FFF;
}

.print_doc {
  font-family: 'Times New Roman', Times, serif;
  font-size: 7pt;
}

.main-table td {
  border-collapse:collapse;
  border: 0.4mm solid black;
  border-bottom: none;
  border-right: none;
}
.main-table tr:last-child td {
    border-bottom: 1px solid #000;
}

.main-table tr td:last-child {
    border-right: 1px solid #000;
}
</style>