<template>
  <q-card style="min-width: 350px">
    <q-card-section>
      <div class="text-h6">Новая проводка</div>
    </q-card-section>
    <q-card-section class="q-pt-none">
      <q-input label="Название проводки" dense v-model="operation_name" autofocus />
    </q-card-section>
    <q-card-section class="q-pt-none">
      <q-input dense v-model="operation_date" :rules="['date']">
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
              <q-date v-model="operation_date">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </q-card-section>
    <q-card-section class="q-pt-none">
      <q-input label="Описание" dense v-model="operation_description" />
    </q-card-section>
    <q-card-section class="q-pt-none">
      <q-select v-model="bill_from" :options="initial" label="Счет списания" />
    </q-card-section>
    <q-card-section class="q-pt-none">
      <q-select v-model="bill_to" :options="initial" label="Счет зачисления" />
    </q-card-section>
    <q-card-section class="q-pt-none">
      <q-input label="Сумма" dense v-model="operation_summ" />
    </q-card-section>

    <q-card-actions align="right" class="text-primary">
      <q-btn flat label="Отмена" v-close-popup />
      <q-btn flat label="Сохранить" v-close-popup @click="addNewOperation" />
    </q-card-actions>
  </q-card>
</template>

<script>

import {ref} from "vue";

function prepareOptions(arr) {
  return arr.map(function (item){
    return item.name
  });
}

export default {
  name: "create_new_operation",
  setup() {
    return {
      operation_description: ref(null),
      operation_name: ref(null),
      operation_date: ref('2021/11/02'),
      operation_summ: ref(0),
      bill_from: ref(null),
      bill_to: ref(null),
    }
  },
  props: {
    initial_bills: { required: true, type: Array }
  },
  data() {
    return  {
      initial: prepareOptions(this.initial_bills)
    }
  },
  methods: {
    addNewOperation() {
      console.log(this.parent_bill, this.bill_description, this.bill_name)
      const newOperationInfo = {
        operation_description: this.operation_description,
        operation_name: this.operation_name,
        operation_date: this.operation_date,
        operation_summ: this.operation_summ,
        bill_from: this.bill_from,
        bill_to: this.bill_to,
      }
      this.$store.commit('addNewOperationToStore',  newOperationInfo);
      this.$store.commit('updateBills');
      this.emitter.emit("addNewOperation", newOperationInfo);
    }
  }
}
</script>

<style scoped>

</style>